const styles = ({ palette }) => ({
  styleOverrides: {
    menuItem: {
      '&.Mui-selected': {
        background: palette.grey[700],
      },
      '&.Mui-selected:hover': {
        background: palette.grey[600],
      },
    },
  },
});

export default styles;
