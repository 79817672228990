import { appElementsInitialState } from '../state';

export function formatHeaderFromRequest(element) {
  const initialState = appElementsInitialState.header;
  const initialLogoState = appElementsInitialState.header_logo;

  return {
    ...initialState,
    ...element,

    logo: {
      ...initialLogoState,
      ...element.logo,
    },
  };
}

export function formatHeaderToRequest(element) {
  return {
    type: element.type,

    logo: {
      type: element.logo.type,
      image: element.logo.image,
    },
  };
}

export function formatImageFromRequest(element) {
  const initialState = appElementsInitialState.image;

  return {
    ...initialState,
    ...element,
  };
}

export function formatImageToRequest(element) {
  return {
    type: element.type,
    text: element.text,
    image: element.image,
  };
}

export function formatTextZoneFromRequest(element) {
  return element;
}

export function formatTextZoneToRequest(element) {
  return element;
}

export function formatButtonFromRequest(element) {
  return element;
}

export function formatButtonToRequest(element) {
  return element;
}

export function formatFormFromRequest(element) {
  return element;
}

export function formatFormToRequest(element) {
  return element;
}

export function formatYesNoButtonFromRequest(element) {
  return element;
}

export function formatYesNoButtonToRequest(element) {
  return element;
}

export function formatQuizFromRequest(element) {
  return element;
}

export function formatQuizToRequest(element) {
  return element;
}

export function formatListFromRequest(element) {
  return element;
}

export function formatListToRequest(element) {
  return element;
}

export function formatFooterFromRequest(element) {
  return element;
}

export function formatFooterToRequest(element) {
  return element;
}
