import { Box, styled, Container, ListItemButton, alpha, ListItem } from '@mui/material';

import { NavigationHeader } from '~/components/NavigationHeader';

export const ContainerStyled = styled(Container)(() => ({
  maxWidth: 'none !important',
  position: 'relative',
}));

export const HeaderStyled = styled(NavigationHeader)(({ theme }) => ({
  padding: '32px 0 8px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: 12,
  },
}));

export const SaveProjectButtonBox = styled(Box)(() => ({
  position: 'relative',
  textAlign: 'center',
}));

export const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: alpha(theme.palette.common.white, 0.08),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.08),
    },
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.08),
  },
}));

export const ToogleDrawerButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  top: '105px',
  left: 0,
  zIndex: theme.zIndex.drawer + 3,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.grey[800],
  padding: '12px 0px',
  border: 0,
  cursor: 'pointer',
}));

export const ModalStyle = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[800],
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  boxShadow: 24,
  padding: '16px 24px',
  borderRadius: 4,
}));

export const AppBuilderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '24px',

  height: 'calc(100vh - 64px - 40px - 80px)',
  overflowY: 'auto',
  overflowX: 'hidden',

  [theme.breakpoints.down('lg')]: {
    height: 'calc(100vh - 64px - 40px - 80px - 36px)',
  },

  '&::-webkit-scrollbar-track': {
    marginTop: '70px',
  },
}));
