import { Box, styled } from '@mui/material';

import { Container } from '../../styles';

export const TextContainer = styled(Container)(({ active, hasContent, theme }) => ({
  minHeight: '48px',
  outlineWidth: active ? '1px' : !hasContent && '1px',
  outlineColor: active ? theme.palette.primary.main : theme.palette.grey['400'],
}));

export const TextHoverOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',

  display: 'flex',
  flexDirection: 'column',
  textTransform: 'uppercase',

  padding: '4px',
  opacity: 0,
}));
