import { Paper } from '@mui/material';

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ProjectsActions } from '~/store/ducks/projects';

import CenterWrapper from '~/components/CenterWrapper';

import NewProjectForm from './components/NewProjectForm';
import { ContainerStyled } from './styles';

const loadingSelector = ({ projects }) => projects.loading;

function NewProjectPage() {
  const loading = useSelector(loadingSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (data) => {
      dispatch(
        ProjectsActions.addProjectRequest({ ...data, webhookMethod: 'POST' }, () => {
          navigate('/');
        })
      );
    },
    [dispatch, navigate]
  );

  return (
    <ContainerStyled maxWidth="md">
      <Paper
        sx={{
          margin: '0 auto',
          display: 'flex',
          height: '100%',
        }}
      >
        <CenterWrapper maxWidth="sm">
          <NewProjectForm onSubmit={handleSubmit} loading={loading} />
        </CenterWrapper>
      </Paper>
    </ContainerStyled>
  );
}

export default NewProjectPage;
