import { Box, styled, Typography } from '@mui/material';

export const TooltipBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 'calc(100% + 30px)',

  width: '220px',

  borderRadius: '4px',
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,

  background: theme.palette.grey[700],
}));

export const TooltipBoxSquare = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% - 6px)',
  left: '-6px',

  width: '12px',
  height: '12px',

  transform: 'rotate(45deg)',
  background: theme.palette.grey[700],
}));

export const TooltipTypography = styled(Typography)(() => ({
  fontSize: '0.75rem',
  fontWeight: '500',
}));
