export default {
  // General messages
  default_error: 'Something went wrong',
  image_upload_error: 'Image upload failed',
  image_delete_error: 'Image delete failed',

  goBack: 'Go Back',

  // Pages/Menu
  'nav.overview': 'Overview',
  'nav.user_tooltip': 'User settings',
  'nav.logout': 'Logout',

  // Form validation messages
  'form.validation.invalid': 'The value is invalid',
  'form.validation.required': 'This field is required',
  'form.validation.email': 'Please enter a valid email address',
  'form.validation.minLength': 'Please enter at least {minLength} characters',
  'form.validation.maxLength': 'Please enter no more than {maxLength} characters',
  'form.validation.minValue': 'Please enter a value greater than or equal to {minValue}',
  'form.validation.maxValue': 'Please enter a value less than or equal to {maxValue}',
  'form.validation.number': 'Please enter a number',
  'form.validation.url': 'Please enter a valid URL',
  'form.validation.integer': 'Please enter an integer',
  'form.validation.positive': 'Please enter a positive value',
  'form.validation.password_confirm': 'Passwords do not match',

  // Modal Texts
  'delete.modal.confirmation.title': 'Delete item',
  'delete.modal.confirmation.message': 'Are you sure you want to delete this item?',
  'delete.modal.confirmation.cancel': 'Cancel',
  'delete.modal.confirmation.delete': 'Delete',

  // Response errors
  'session.email_password': 'Incorrect email/password combination',
  'project.subdomain_already_exists': 'Subdomain already in use',

  // Dropzone
  'dropzone.text': 'Drag and drop image or click to upload',

  // TextEditor
  'editor.heading_1': 'Heading 1',
  'editor.heading_2': 'Heading 2',
  'editor.heading_3': 'Heading 3',
  'editor.heading_4': 'Heading 4',

  'editor.list_bullets': 'Bullets',
  'editor.list_numbering': 'Numbering',
  'editor.highlight': 'Highlight',
  'editor.clear_nodes': 'Clear styles',

  'editor.strike': 'Strike',
  'editor.paragraph': 'Paragraph',
  'editor.blockquote': 'Blockquote',
  'editor.divider': 'Divider',

  'editor.break_line': 'Break line',
  'editor.bold': 'Bold',
  'editor.italic': 'Italic',
  'editor.underline': 'Underline',

  // Project form fields
  'project.form.name': 'Project Name',
  'project.form.subdomain': 'Subdomain',
  'project.form.webhook': 'Webhook URL',
  'project.form.goals': 'Project Goals',
  'project.form.budget': 'Budget',
  'project.form.domain': 'Domain',
  'project.form.cname': 'CNAME',
  'project.form.client': 'Client',
  'project.form.description': 'Description',
  'project.form.submit': 'Submit',
  'project.form.cancel': 'Cancel',
  'project.form.delete': 'Delete',
  'project.form.name_placeholder': 'Project Name',
  'project.form.subdomain_placeholder': 'Subdomain',
  'project.form.webhook_placeholder': 'Webhook URL',
  'project.form.goals_placeholder': 'Project Goals',
  'project.form.budget_placeholder': 'Project Budget',
  'project.form.domain_placeholder': 'Project Domain',
  'project.form.cname_placeholder': 'CNAME Domain',
  'project.form.client_placeholder': 'Client',
  'project.form.description_placeholder': 'Description',
  'project.form.domain_info':
    'After updating your project domain, your CNAME will be available here after the next publishing. You should add this CNAME to your DNS provider.',

  // Project
  'project.name': 'Name',
  'project.client': 'Client',
  'project.goals': 'Goals',
  'project.status': 'Status',
  'project.created_at': 'Created At',
  'project.link': 'Link',
  'project.published': 'Published',
  'project.draft': 'Draft',
  'project.lastsave': 'Last save',

  'project.load_failed': 'Failed to load project',
  'project.create_failed': 'Failed to create project',

  'project.saved_success': 'Project saved successfully!',
  'project.save_failed': 'Failed to save project',

  'project.project_deleted': 'Project deleted successfully!',
  'project.delete_failed': 'Failed to delete project',

  'project.publish_success':
    'Project published successfully! Please note that it may take up to 10 minutes for the changes to take effect.',
  'project.publish_failure': 'Failed to publish project',

  'project.lead.load_failed': 'Failed to load leads of project',
  'project.lead.delete_failed': 'Failed to delete lead of project',

  'project.dropzone.text': 'Drag and drop image or click to upload (16:9 - max 640x360)',

  'project.unsaved_changes': 'You have unsaved changes',
  'project.unpublished_changes': 'You have unpublished changes',
  'project.open_website': 'Open website',
  'project.not_published': 'Project not published',

  // SignInPage
  'signin.title': 'Sign In',
  'signin.field_email': 'E-mail',
  'signin.field_password': 'Password',
  'signin.button_signin': 'Sign in',
  'signin.create_account': 'Create an account',
  'signin.forgot_password': 'Forgot password?',

  'signin.login_failed': 'Failed to login',

  // Projects
  'projects.card.menu.delete': 'Delete',
  'projects.card.menu.edit': 'Edit',

  'projects.load_failed': 'Failed to load projects',

  // RegisterPage
  'register.title': 'Create an account',
  'register.field_name': 'Name',
  'register.field_email': 'E-mail',
  'register.field_password': 'Password',
  'register.field_password_confirm': 'Confirm password',
  'register.button_register': 'Register',
  'register.cancel': 'Cancel',

  'register.account_created': 'Account created successfully!',
  'register.account_creation_failed': 'Failed to create account!',

  // RecoverPage
  'recover.title': 'Recover password',
  'recover.field_email': 'E-mail',
  'recover.button_recover': 'Recover',
  'recover.cancel': 'Cancel',

  // OverviewPage
  'overview.title': 'All Projects',
  'overview.new_project': 'New Project',

  // NewProjectPage
  'newproject.title': 'Create a new project',
  'newproject.breadcrumb_title': 'New Project',

  // ProjectsSettingsPage
  'projectsettings.savesettings': 'Save Settings',
  'projectsettings.general': 'General',
  'projectsettings.metainformation': 'Meta Information',
  'projectsettings.leadlist': 'Lead list',
  'projectsettings.custom_domain': 'Custom Domain',

  'projectsettings.modal.delete.title': 'Delete Project',
  'projectsettings.modal.delete.content': 'Are you sure you want to delete this project?',

  'projectsettings.name': 'Name',
  'projectsettings.email': 'Email',
  'projectsettings.phone': 'Phone',
  'projectsettings.country': 'Country',
  'projectsettings.city': 'City',
  'projectsettings.zip_code': 'Zip Code',
  'projectsettings.date': 'Date',
  'projectsettings.choice': 'Choice',

  'projectsettings.choice.not_answered': 'Not answered',
  'projectsettings.choice.yes': 'Yes',
  'projectsettings.choice.no': 'No',

  // ProjectMetaInformation
  'projectmetainformation.tag_manager_id': 'Tag Manager ID',
  'projectmetainformation.tag_manager_id_placeholder': 'Tag ID',
  'projectmetainformation.cookie_banner': 'Cookie Banner',

  // Project Builder
  'projectbuilder.all_projects': 'All Projects',
  'projectbuilder.settings': 'Settings',
  'projectbuilder.preview': 'Preview',
  'projectbuilder.publish': 'Publish',

  'projectbuilder.page.add.failure': 'Page title already exists',

  'projectbuilder.page.delete.modal.title': 'Delete Page',
  'projectbuilder.page.delete.modal.content':
    'Are you sure you want to delete this page?',

  'projectBuilder.page.form.title': 'Add new Page',
  'projectBuilder.page.form.label': 'Page title',
  'projectBuilder.page.form.cancel_button': 'Cancel',
  'projectBuilder.page.form.add_button': 'Add page',

  'projectbuilder.add_popover.title': 'Block type',

  'projectbuilder.add_element_failed': 'Failed to add element',
  'projectbuilder.max_elements_count': 'Maximum elements count reached',

  'projectbuilder.drawer.tab.pages': 'Pages',
  'projectbuilder.drawer.tab.ai_prompt': 'AI Prompt',
  'projectbuilder.drawer.title': 'Block Settings',
  'projectbuilder.drawer.save': 'Save',
  'projectbuilder.drawer.title.info':
    'Infomation about everything that exists in this world and more!',

  'projectbuilder.element.delete': 'Delete',

  'projectbuilder.image': 'Image',
  'projectbuilder.image.dropzone': 'Embedd Image',
  'projectbuilder.image.dropzone.text': 'Drag and drop image or click to upload (53x53)',
  'projectbuilder.image.text': 'Add Title to',

  'projectbuilder.text_zone': 'Text',
  'projectbuilder.text': 'Text',
  'projectbuilder.text.style': 'Text Style',
  'projectbuilder.text.placeholder': 'Click to add text',

  'projectbuilder.button': 'Button',
  'projectbuilder.button.text': 'Button Text',
  'projectbuilder.button.href': 'Link Address',
  'projectbuilder.button.href.placeholder': 'Select a page or enter a link',
  'projectbuilder.button.placeholder': 'Click to add text',
  'projectbuilder.button.background_color.text': 'Button Color',
  'projectbuilder.button.text_color.text': 'Text Color',

  'projectbuilder.yes_no_button.yes': 'YES',
  'projectbuilder.yes_no_button.no': 'NO',

  'projectbuilder.form': 'Form',
  'projectbuilder.form.name': 'Name',
  'projectbuilder.form.name.placeholder': 'Leade Name',
  'projectbuilder.form.email': 'Email',
  'projectbuilder.form.email.placeholder': 'lead@gmail.com',
  'projectbuilder.form.phone': 'Phone',
  'projectbuilder.form.phone.placeholder': '(99) 9999-9999',
  'projectbuilder.form.country': 'Country',
  'projectbuilder.form.country.placeholder': 'Poland',
  'projectbuilder.form.city': 'City',
  'projectbuilder.form.city.placeholder': 'Warsaw',
  'projectbuilder.form.zip_code': 'Zip Code',
  'projectbuilder.form.zip_code.placeholder': '00-013',
  'projectbuilder.form.date': 'Date',

  'projectbuilder.yesnobutton': 'Choice',

  'projectbuilder.element.item.delete': 'Delete item',

  'projectbuilder.quiz': 'Quiz',
  'projectbuilder.quiz.question': 'Quiz Question',
  'projectbuilder.quiz.question.editor.style': 'Question Text Style',
  'projectbuilder.quiz.choice.item.title': 'Quiz Choice Text',
  'projectbuilder.quiz.choice.placeholder': 'Choice text',

  'projectbuilder.list': 'List',
  'projectbuilder.list.item.text': 'List Item Text',
  'projectbuilder.list.item.placeholder': 'List item description',

  'projectbuilder.footer': 'Footer',
  'projectbuilder.footer.item.delete': 'Delete Item',
  'projectbuilder.footer.name.text': 'Item Name',
  'projectbuilder.footer.address.text': 'Item Address',

  'projectbuilder.form.tooltip.message': 'Hey👋 Check the Preview to see all the Items.',
};
