import { styled, Typography } from '@mui/material';

import { Container } from '../../styles';

export const FormContainer = styled(Container)(() => ({}));

export const FormTooltip = styled(Typography)(() => ({
  position: 'absolute',
}));

export const FakeInputImage = styled('img')(() => ({
  marginRight: '16px',
}));

export const FakeInput = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  background: theme.palette.grey[50],
  color: theme.palette.grey[400],

  borderRadius: '5px',
  padding: '12px 16px',
}));
