import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import HighlightExtension from '@tiptap/extension-highlight';
import UnderlineExtension from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import ImageDropzone from '~/components/ImageDropzone';
import { TextEditorContent, TextEditorMenuBar } from '~/components/TextEditor';

import { ContainerStack, DividerStyled, FabStyled, ItemHeader } from './styles';

function QuizSettings({ index, element }) {
  const intl = useIntl();
  const [quizChoicesHref, setQuizChoicesHref] = useState({});
  const dispatch = useDispatch();
  const { project, fullProject } = useSelector(projectBuilderSelector);

  const pageOptions = useMemo(
    () =>
      fullProject.pages.reduce((acc, page) => {
        if (page.id !== fullProject.activePage) {
          acc.push({ label: page.title, value: page.slug });
        }
        return acc;
      }, []),
    [fullProject.pages]
  );

  useEffect(() => {
    const initialAutoCompleteValues = element.elements.reduce((acc, item) => {
      const currentOption = pageOptions.find(
        (option) => `/${option.value}` === item.href || item.href === '/'
      );
      if (currentOption) {
        acc[item.id] = {
          label: currentOption.label,
          value: currentOption.value,
        };
      } else {
        acc[item.id] = {
          label: item.href,
          value: item.href,
        };
      }
      return acc;
    }, {});
    setQuizChoicesHref(initialAutoCompleteValues);
  }, []);

  const handleHrefChange = useCallback(
    (event, newValue, id, choiceIndex) => {
      if (newValue) {
        setQuizChoicesHref((prevState) => ({ ...prevState, [id]: { ...newValue } }));
        const href = newValue.value === '/' ? newValue.value : `/${newValue.value}`;
        dispatch(
          ProjectBuilderActions.updateBodyElementArrayItem(
            project.id,
            index,
            choiceIndex,
            {
              href,
            }
          )
        );
      } else if (event.target.value) {
        const title = event.target.value;
        setQuizChoicesHref((prevState) => ({
          ...prevState,
          [id]: { label: title, value: title },
        }));

        dispatch(
          ProjectBuilderActions.updateBodyElementArrayItem(
            project.id,
            index,
            choiceIndex,
            {
              href: title,
            }
          )
        );
      }
    },
    [index, project.id]
  );

  const handleQuestionChange = useCallback(
    ({ editor }) => {
      const dataToUpdate = { content: editor.getHTML(), question: editor.getText() };
      dispatch(ProjectBuilderActions.updateBodyElement(project.id, index, dataToUpdate));
    },
    [dispatch, index, project.id]
  );

  const handleChoiceTextChange = useCallback(
    (event, choiceIndex) => {
      const text = event.target.value;
      dispatch(
        ProjectBuilderActions.updateBodyElementArrayItem(project.id, index, choiceIndex, {
          text,
        })
      );
    },
    [index, project.id]
  );

  const handleImageChange = useCallback(
    ({ data, choiceIndex }) => {
      if (!data) {
        dispatch(
          ProjectBuilderActions.deleteBodyElementArrayItemImage(
            project.id,
            index,
            choiceIndex
          )
        );
        return;
      }
      dispatch(
        ProjectBuilderActions.updateBodyElementArrayItemImageRequest(
          project.id,
          index,
          choiceIndex,
          data
        )
      );
    },
    [index, project.id]
  );

  const handleAddChoice = useCallback(() => {
    dispatch(
      ProjectBuilderActions.addBodyElementArrayItem(project.id, index, 'quizChoice')
    );
  }, [index, project.id]);

  const handleRemoveChoice = useCallback(
    (choiceIndex) => {
      dispatch(
        ProjectBuilderActions.removeBodyElementArrayItem(project.id, index, choiceIndex)
      );
    },
    [index, project.id]
  );

  const elementsArraySize = element.elements.length;

  const editor = useEditor({
    content: element.content,
    onUpdate: handleQuestionChange,
    extensions: [StarterKit, UnderlineExtension, HighlightExtension],
  });

  if (!editor) {
    return null;
  }

  return (
    <ContainerStack flexDirection="column" spacing={3}>
      <Stack spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.quiz.question.editor.style" />
        </Typography>

        <TextEditorMenuBar editor={editor} />

        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.quiz.question" />
        </Typography>

        <TextEditorContent editor={editor} />
      </Stack>
      {element.elements.map((choiceElement, choiceIndex) => (
        <Stack spacing={3} key={choiceElement.id}>
          <Stack spacing={1}>
            <ItemHeader>
              <Typography variant="caption">
                <FormattedMessage id="projectbuilder.quiz.choice.item.title" />
              </Typography>

              <Tooltip
                title={<FormattedMessage id="projectbuilder.element.item.delete" />}
              >
                <Box component="span">
                  <IconButton
                    disabled={elementsArraySize === 1}
                    onClick={() => handleRemoveChoice(choiceIndex)}
                    sx={{ p: '0px' }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            </ItemHeader>

            <TextField
              type="text"
              fullWidth
              variant="outlined"
              value={choiceElement.text}
              onChange={(e) => handleChoiceTextChange(e, choiceIndex)}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="caption">
              <FormattedMessage id="projectbuilder.button.href" />
            </Typography>
            <Autocomplete
              freeSolo
              id="quiz-pages-autocomplete"
              options={pageOptions}
              value={quizChoicesHref[choiceElement.id] || { label: '', value: '' }}
              onChange={(e, newValue) =>
                handleHrefChange(e, newValue, choiceElement.id, choiceIndex)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={intl.formatMessage({
                    id: 'projectbuilder.button.href.placeholder',
                  })}
                  onChange={(e, newValue) =>
                    handleHrefChange(e, newValue, choiceElement.id, choiceIndex)
                  }
                />
              )}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="caption">
              <FormattedMessage id="projectbuilder.image.dropzone" />
            </Typography>
            <ImageDropzone
              sx={{ mt: 1, height: '133px' }}
              defaultImageSrc={choiceElement.tempImage?.src || choiceElement.image?.url}
              onImageChange={(data) => handleImageChange({ data, choiceIndex })}
            />
          </Stack>
          <DividerStyled />
        </Stack>
      ))}

      <FabStyled color="primary" onClick={handleAddChoice}>
        <AddIcon />
      </FabStyled>
    </ContainerStack>
  );
}

const quizChoicePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  src: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  tempImage: PropTypes.shape({
    src: PropTypes.string,
  }),
  text: PropTypes.string,
  href: PropTypes.string,
});

const quizPropTypes = PropTypes.shape({
  elements: PropTypes.arrayOf(quizChoicePropTypes),
  question: PropTypes.string,
  content: PropTypes.string,
});

QuizSettings.propTypes = {
  index: PropTypes.number.isRequired,
  element: quizPropTypes.isRequired,
};

export default QuizSettings;
