const styles = ({ palette }) => ({
  styleOverrides: {
    '.scroll__default': {
      '&::-webkit-scrollbar': {
        width: '8px',
      },

      '&::-webkit-scrollbar-track': {
        borderRadius: '8px',
        background: palette.grey[900],
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        background: palette.grey[600],
      },

      '&::-webkit-scrollbar-thumb:hover': {
        background: palette.grey[500],
      },
    },
  },
});

export default styles;
