import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { FlashMessageActions } from '~/store/ducks/flashMessage';

const flashMessageSelector = ({ flashMessage }) => flashMessage;

function SnackbarMessage() {
  const dispatch = useDispatch();

  const flashMessage = useSelector(flashMessageSelector);

  const handleClose = useCallback(() => {
    dispatch(FlashMessageActions.hideMessage());
  }, [dispatch]);

  if (!flashMessage.open) {
    return null;
  }

  return (
    <Snackbar
      open
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      sx={{ maxWidth: '450px' }}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={flashMessage.variant}
        onClose={handleClose}
      >
        <FormattedMessage id={flashMessage.id} />
      </Alert>
    </Snackbar>
  );
}

export default SnackbarMessage;
