import { Box, styled } from '@mui/material';

export const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',

  width: '100%',
  height: 'calc(100vh - 64px) !important',
}));

export const MockupContainer = styled(Box)(() => ({
  width: '100%',
  maxWidth: '410px',

  marginLeft: 'auto',
  marginRight: 'auto',

  paddingBottom: '24px',
}));

export const MockupBox = styled(Box)(() => ({
  position: 'relative',

  width: '95%',
  paddingTop: 'calc(200% - 24px)', // padding bottom of container

  '& iframe': {
    position: 'absolute',
    top: '9%',
    left: '6%',

    width: '89%',
    height: '89%',

    borderBottomLeftRadius: '32px',
    borderBottomRightRadius: '32px',
  },
}));

export const Mockup = styled('img')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  width: '100%',
  height: '100%',

  userSelect: 'none',
  pointerEvents: 'none',
}));

export const PreviewIframe = styled('iframe')(() => ({
  width: '100%',
  height: '100%',

  border: 'none',
}));
