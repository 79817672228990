import { Button, CircularProgress, styled } from '@mui/material';

export const ButtonStyled = styled(Button)(({ theme }) => ({
  '&.Mui-disabled': {
    background: theme.palette.primary.dark,
  },
}));

export const CircularProgressStyled = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
}));
