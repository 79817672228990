import { Box, Link, Stack, styled } from '@mui/material';

import { Container } from '../../styles';

export const FooterContainer = styled(Container)(({ theme, active, hasContent }) => ({
  minHeight: '48px',

  outlineWidth: active ? '1px' : !hasContent && '1px',
  outlineColor: active ? theme.palette.primary.main : theme.palette.grey['400'],
}));

export const FooterElementsStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  columnGap: '50px',
  overflow: 'hidden',

  '& > :not(:last-of-type)': {
    position: 'relative',

    '&::before': {
      content: `""`,
      position: 'absolute',
      top: 'calc(50% - 1px)',
      right: 'calc(-25px - 1px)',

      width: '2px',
      height: '2px',

      borderRadius: '50%',
      background: theme.palette.grey[600],

      pointerEvents: 'none',
    },
  },
}));

export const FooterHoverOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: '0px',
  bottom: '0px',
  left: '0px',
  right: '0px',

  padding: '4px',
  opacity: '0',
}));

export const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: '0.75rem',

  color: theme.palette.grey[600],
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
}));
