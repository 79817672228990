import { styled } from '@mui/material';

import { ElementsDefaultStyle } from '../styles';

export const ElementsViewerBox = styled(ElementsDefaultStyle)(({ theme }) => ({
  '.ProseMirror': {
    hr: {
      borderColor: theme.palette.grey[400],
    },

    blockquote: {
      borderColor: theme.palette.grey[400],
    },
    p: {
      margin: 0,
    },
    'h1, h2, h3, h4': {
      margin: 0,
    },
    // '> *:first-of-type': {
    //   marginTop: 0,
    // },

    // '> *:last-of-type': {
    //   marginBottom: 0,
    // },
  },
}));
