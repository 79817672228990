import { styled } from '@mui/material';

import { ElementsDefaultStyle } from '../styles';

export const EditorContentBox = styled(ElementsDefaultStyle)(({ theme }) => ({
  '.ProseMirror': {
    padding: '12px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '5px',
    outline: 0,

    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary.main}`,
      border: '1px solid transparent',
    },
    'h1,h2,h3,h4': {
      margin: 0,
    },
    p: {
      margin: 0,
    },
  },
}));
