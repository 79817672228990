import { Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Link } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';

import { Container, IconButtonDelete, TooltipOverlay } from '../../styles';
import { ButtonHoverOverlay, ButtonStyled } from './styles';

function ButtonBlock({ element, index }) {
  const dispatch = useDispatch();
  const { selectedAppElementIndex, project } = useSelector(projectBuilderSelector);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleDeleteElement = useCallback(() => {
    dispatch(ProjectBuilderActions.removeBodyElement(project.id, index));
  }, [index, project.id]);

  const handleClick = useCallback(() => {
    dispatch(ProjectBuilderActions.setSelectedAppElementIndex(index));
  }, [index]);

  const active = useMemo(
    () => index === selectedAppElementIndex,
    [index, selectedAppElementIndex]
  );

  const hasContent = useMemo(() => element.text !== '', [element.text]);

  return (
    <>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeleteElement}
      />

      <Container active={active} onClick={handleClick} open={deleteModalOpen}>
        <ButtonStyled
          LinkComponent={Link}
          variant="contained"
          href={element.href}
          target="_blank"
          rel="noopener"
          element={element}
        >
          {hasContent ? (
            element.text
          ) : (
            <FormattedMessage id="projectbuilder.button.placeholder" />
          )}
        </ButtonStyled>

        <ButtonHoverOverlay>
          <Box display="flex" justifyContent="flex-end">
            <TooltipOverlay
              title={<FormattedMessage id="projectbuilder.element.delete" />}
            >
              <IconButtonDelete onClick={handleOpenDeleteModal}>
                <DeleteIcon fontSize="small" />
              </IconButtonDelete>
            </TooltipOverlay>
          </Box>
        </ButtonHoverOverlay>
      </Container>
    </>
  );
}

const appButtonPropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  href: PropTypes.string,
  textColor: PropTypes.string,
  background: PropTypes.string,
});

ButtonBlock.propTypes = {
  element: appButtonPropTypes.isRequired,
  index: PropTypes.number.isRequired,
};

export default ButtonBlock;
