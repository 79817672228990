import { Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import defaultImage from '~/assets/images/default-project-app-element-image.svg';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';

import { QuizHoverOverlay } from '../../Quiz/Block/styles';
import { Container, IconButtonDelete, ListImage, TooltipOverlay } from './styles';

function ListBlock({ element, index }) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { selectedAppElementIndex, project } = useSelector(projectBuilderSelector);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const active = useMemo(
    () => index === selectedAppElementIndex,
    [index, selectedAppElementIndex]
  );

  const handleClick = useCallback(() => {
    dispatch(ProjectBuilderActions.setSelectedAppElementIndex(index));
  }, [index]);

  const handleDeleteElement = useCallback(() => {
    dispatch(ProjectBuilderActions.removeBodyElement(project.id, index));
  }, [index, project.id]);

  return (
    <>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeleteElement}
      />
      <Container active={active} onClick={handleClick}>
        <Stack spacing={3}>
          {element.elements.map((listItem) => (
            <Stack key={listItem.id} direction="row" alignItems="center" spacing={4}>
              <ListImage
                src={listItem.tempImage?.src || listItem.image?.url || defaultImage}
                alt="List item"
              />
              <Typography variant="body2" fontWeight={300}>
                {listItem.text || (
                  <FormattedMessage id="projectbuilder.list.item.placeholder" />
                )}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <QuizHoverOverlay>
          <Box display="flex" justifyContent="flex-end">
            <TooltipOverlay
              title={<FormattedMessage id="projectbuilder.element.delete" />}
            >
              <IconButtonDelete onClick={handleOpenDeleteModal}>
                <DeleteIcon fontSize="small" />
              </IconButtonDelete>
            </TooltipOverlay>
          </Box>
        </QuizHoverOverlay>
      </Container>
    </>
  );
}

const listItemPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  src: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  tempImage: PropTypes.shape({
    src: PropTypes.string,
  }),
});

const listPropTypes = PropTypes.shape({
  elements: PropTypes.arrayOf(listItemPropTypes),
});

ListBlock.propTypes = {
  index: PropTypes.number.isRequired,
  element: listPropTypes.isRequired,
};

export default ListBlock;
