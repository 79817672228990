import { Box, Divider, Fab, Stack } from '@mui/material';

import styled from '@emotion/styled';

export const ContainerStack = styled(Stack)(() => ({
  '& > div:last-of-type hr': {
    display: 'none',
  },
}));

export const ItemHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
}));

export const FabStyled = styled(Fab)(() => ({
  width: '42px',
  height: '42px',

  margin: '36px auto 0 !important ',
}));

export const DividerStyled = styled(Divider)(() => ({
  width: '234px',
  margin: '32px auto 0 !important ',
}));
