import { takeLatest, put, call } from 'redux-saga/effects';

import api from '~/helpers/api';
import { getErrorIdFromResponse } from '~/helpers/response';
import { login, setLoginUser } from '~/helpers/session';

import { FlashMessageActions } from '../ducks/flashMessage';
import { SessionTypes, SessionActions } from '../ducks/session';

function* createAccount({ credentials, callback }) {
  try {
    const accountData = {
      name: credentials.name,
      email: credentials.email,
      password: credentials.password,
    };

    yield call(api.post, '/register', accountData);

    yield put(
      FlashMessageActions.showMessage({
        variant: 'success',
        id: 'register.account_created',
      })
    );
    yield put(SessionActions.createAccountSuccess());

    callback();
  } catch (error) {
    const errorId = getErrorIdFromResponse(
      error.response,
      'register.account_creation_failed'
    );

    yield put(SessionActions.createAccountFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* loginRequest({ credentials, callback }) {
  try {
    const { data } = yield call(api.post, '/login', credentials);
    const { user, token } = data;

    setLoginUser(user);
    login(token);

    yield put(SessionActions.loginSuccess(user, token));
    callback();
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'signin.login_failed');

    yield put(SessionActions.loginError());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

export default function* sagas() {
  yield takeLatest(SessionTypes.CREATE_ACCOUNT_REQUEST, createAccount);
  yield takeLatest(SessionTypes.LOGIN_REQUEST, loginRequest);

  // yield takeLatest(SessionTypes.LOGIN_GOOGLE_REQUEST, loginGoogleRequest);
  // yield takeLatest(SessionTypes.LOGOUT, logout);
}
