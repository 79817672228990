import { styled } from '@mui/material';

export const ProjectMetaInformationFormRoot = styled('form')(() => ({
  width: '100%',
}));

export const CookieBannerImage = styled('img')(() => ({
  width: '100%',
}));

export const BgLightGray = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey.A700,
}));
