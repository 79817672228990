import { Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import EmailIcon from '~/assets/images/form/email.svg';
import PhoneIcon from '~/assets/images/form/phone.svg';
import ReadingIcon from '~/assets/images/form/reading.svg';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';

import { IconButtonDelete, TooltipOverlay } from '../../styles';
import { TextHoverOverlay } from '../../TextZone/Block/styles';
import FormBlockTooltip from './components/FormBlockTooltip';
import { FakeInput, FakeInputImage, FormContainer } from './styles';

function FormBlock({ index }) {
  const { selectedAppElementIndex, project } = useSelector(projectBuilderSelector);

  const dispatch = useDispatch();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleDeleteElement = useCallback(() => {
    dispatch(ProjectBuilderActions.removeBodyElement(project.id, index));
  }, [index, project.id]);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = useCallback(() => {
    dispatch(ProjectBuilderActions.setSelectedAppElementIndex(index));
  }, [dispatch]);

  const handleMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const active = useMemo(
    () => index === selectedAppElementIndex,
    [index, selectedAppElementIndex]
  );

  return (
    <>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeleteElement}
      />

      <FormContainer
        active={active}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack spacing={2.5}>
          <FakeInput>
            <FakeInputImage src={ReadingIcon} />
            <FormattedMessage id="projectbuilder.form.name" />
          </FakeInput>

          <FakeInput>
            <FakeInputImage src={EmailIcon} />
            <FormattedMessage id="projectbuilder.form.email" />
          </FakeInput>

          <FakeInput>
            <FakeInputImage src={PhoneIcon} />
            <FormattedMessage id="projectbuilder.form.phone" />
          </FakeInput>
        </Stack>

        <FormBlockTooltip open={showTooltip} />

        <TextHoverOverlay>
          <Box display="flex" justifyContent="flex-end">
            <TooltipOverlay
              title={<FormattedMessage id="projectbuilder.element.delete" />}
            >
              <IconButtonDelete onClick={handleOpenDeleteModal}>
                <DeleteIcon fontSize="small" />
              </IconButtonDelete>
            </TooltipOverlay>
          </Box>
        </TextHoverOverlay>
      </FormContainer>
    </>
  );
}

const appFormBlockPropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  content: PropTypes.string,
});

FormBlock.propTypes = {
  element: appFormBlockPropTypes.isRequired,
  index: PropTypes.number.isRequired,
};

export default FormBlock;
