/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import {
  FooterContainer,
  FooterElementsStack,
  FooterHoverOverlay,
  LinkStyled,
} from './styles';

function FooterBlock({ element, index }) {
  const dispatch = useDispatch();
  const { selectedAppElementIndex } = useSelector(projectBuilderSelector);
  const handleClick = useCallback(() => {
    dispatch(ProjectBuilderActions.setSelectedAppElementIndex(index));
  }, [index]);

  const active = useMemo(
    () => index === selectedAppElementIndex,
    [index, selectedAppElementIndex]
  );

  const hasContent = useMemo(() => {
    for (let i = 0; i < element.elements.length; i += 1) {
      const linkElement = element.elements[i];

      if (linkElement.text !== '') {
        return true;
      }
    }

    return false;
  }, [element.elements]);

  return (
    <FooterContainer active={active} hasContent={hasContent} onClick={handleClick}>
      <FooterElementsStack>
        {element.elements.map((footerLink, linkIndex) => (
          <LinkStyled key={linkIndex} href={footerLink.href}>
            {footerLink.text}
          </LinkStyled>
        ))}
      </FooterElementsStack>

      <FooterHoverOverlay />
    </FooterContainer>
  );
}

const appFooterLinkPropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  href: PropTypes.string,
});

const appFooterPropTypes = PropTypes.shape({
  elements: PropTypes.arrayOf(appFooterLinkPropTypes),
});

FooterBlock.propTypes = {
  element: appFooterPropTypes.isRequired,
  index: PropTypes.number.isRequired,
};

export default FooterBlock;
