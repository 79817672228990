import {
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import defaultLogo from '~/assets/images/default-project-logo.svg';

import { formatCreatedAtProjectDate } from '~/helpers/date';

import ProjectLinkIcon from '../common/ProjectLinkIcon';
import ProjectStatusChip from '../common/ProjectStatusChip';
import { Image, TableRowStyled } from './styles';

function ProjectsTableList({ projects }) {
  const navigate = useNavigate();

  const handleProjectClick = useCallback(
    (id) => navigate(`/project-builder/${id}`),
    [navigate]
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />

            <TableCell>
              <FormattedMessage id="project.name" />
            </TableCell>

            <TableCell>
              <FormattedMessage id="project.client" />
            </TableCell>

            <TableCell>
              <FormattedMessage id="project.created_at" />
            </TableCell>

            <TableCell>
              <FormattedMessage id="project.status" />
            </TableCell>

            <TableCell>
              <FormattedMessage id="project.link" />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {projects.map((project) => (
            <TableRowStyled
              key={project.id}
              onClick={() => handleProjectClick(project.id)}
            >
              <TableCell>
                <Image src={project.logo?.url || defaultLogo} />
              </TableCell>

              <TableCell>{project.name}</TableCell>
              <TableCell>{project.client}</TableCell>

              <TableCell>{formatCreatedAtProjectDate(project.createdAt)}</TableCell>

              <TableCell>
                <ProjectStatusChip status={project.status} />
              </TableCell>

              <TableCell>
                <ProjectLinkIcon
                  status={project.status}
                  publishedUrl={project.publishedUrl}
                />
              </TableCell>
            </TableRowStyled>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ProjectsTableList.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      published: PropTypes.bool,
    })
  ),
};

ProjectsTableList.defaultProps = {
  projects: [],
};

export default ProjectsTableList;
