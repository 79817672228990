import { Stack, Typography, TextField } from '@mui/material';

import React from 'react';
import { FormattedMessage } from 'react-intl';

function FormSettings() {
  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.form.name" />
        </Typography>

        <TextField
          disabled
          type="text"
          label={<FormattedMessage id="projectbuilder.form.name.placeholder" />}
        />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.form.email" />
        </Typography>
        <TextField
          disabled
          type="text"
          label={<FormattedMessage id="projectbuilder.form.email.placeholder" />}
        />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="caption">
          <FormattedMessage
            id="projectbuilder.form.phone"
            label={<FormattedMessage id="projectbuilder.form.phone.placeholder" />}
          />
        </Typography>
        <TextField
          disabled
          type="text"
          label={<FormattedMessage id="projectbuilder.form.phone.placeholder" />}
        />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.form.country" />
        </Typography>

        <TextField
          disabled
          type="text"
          label={<FormattedMessage id="projectbuilder.form.country.placeholder" />}
        />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.form.city" />
        </Typography>

        <TextField
          disabled
          type="text"
          label={<FormattedMessage id="projectbuilder.form.city.placeholder" />}
        />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.form.zip_code" />
        </Typography>

        <TextField
          disabled
          type="text"
          label={<FormattedMessage id="projectbuilder.form.zip_code.placeholder" />}
        />
      </Stack>
    </Stack>
  );
}

export default FormSettings;
