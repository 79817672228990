import MuiAutocomplete from './MuiAutocomplete';
import MuiButton from './MuiButton';
import MuiCssBaseline from './MuiCssBaseline';
import MuiDivider from './MuiDivider';
import MuiTablePagination from './MuiTablePagination';

export default (theme) => ({
  MuiButton: MuiButton(theme),
  MuiCssBaseline: MuiCssBaseline(theme),
  MuiAutocomplete: MuiAutocomplete(theme),
  MuiDivider: MuiDivider(theme),
  MuiTablePagination: MuiTablePagination(theme),
});
