import { createActions, createReducer } from 'reduxsauce';

import {
  removeElementFromArrayBasedOnIndex,
  updateElementOnArrayBasedOnIndex,
} from '~/helpers/array';
import {
  updateProjectHeaderLogo,
  updatePageBodyElements,
  updateProjectFooterElements,
} from '~/helpers/project';
import { updateBodyArrayElements } from '~/helpers/project/redux/utils';
import {
  appElementsInitialState,
  createDefaultContent,
  projectInitialState,
  appElementTypes,
} from '~/helpers/project/state';

/**
 * Types
 */
export const { Types: ProjectBuilderTypes, Creators: ProjectBuilderActions } =
  createActions(
    {
      getProjectInfoRequest: ['id'],
      getProjectInfoSuccess: ['project'],
      getProjectInfoFailure: [],

      addProjectPageRequest: ['title'],
      addProjectPageSuccess: ['title', 'slug'],

      selectProjectPage: ['pageId'],
      deleteProjectPage: ['pageId'],

      //
      setSelectedAppElementIndex: ['index'],

      addBodyElement: ['pageId', 'data'],
      updateBodyElement: ['pageId', 'index', 'data'],
      removeBodyElement: ['pageId', 'index'],

      updateFooterElement: ['index', 'data'],
      removeFooterElement: ['index'],
      //

      updateHeaderElementLogoRequest: ['index', 'data'],
      updateHeaderElementLogoSuccess: ['index', 'imageData'],
      updateHeaderElementLogoFailure: [],

      deleteHeaderElementLogo: ['index'],

      updateBodyElementImageRequest: ['pageId', 'index', 'data'],
      updateBodyElementImageSuccess: ['pageId', 'index', 'imageData'],
      updateBodyElementImageFailure: [],

      deleteBodyElementImage: ['pageId', 'index'],

      addBodyElementArrayItem: ['pageId', 'index', 'elementType'],
      updateBodyElementArrayItem: ['pageId', 'index', 'choiceIndex', 'data'],
      removeBodyElementArrayItem: ['pageId', 'index', 'choiceIndex'],

      updateBodyElementArrayItemImageRequest: ['pageId', 'index', 'choiceIndex', 'data'],
      updateBodyElementArrayItemImageSuccess: [
        'pageId',
        'index',
        'choiceIndex',
        'imageData',
      ],
      updateBodyElementArrayItemImageFailure: [],

      deleteBodyElementArrayItemImage: ['pageId', 'index', 'choiceIndex'],

      addFooterElementRequest: [],
      addFooterElementSuccess: ['element'],
      addFooterElementFailure: [],

      saveProjectBuildRequest: [],
      saveProjectBuildSuccess: ['data'],
      saveProjectBuildFailure: [],

      //

      publishProjectRequest: [],
      publishProjectSuccess: [],
      publishProjectFailure: [],
    },
    {
      prefix: 'projectBuilder/',
    }
  );

/**
 * Reducer handlers
 */
const INITIAL_STATE = {
  loading: false,
  loadingPublish: false,
  loadingSaveProject: false,
  loadingUploadImage: false,
  selectedAppElementIndex: null,
  changed: false,

  data: projectInitialState,
};

const getProjectInfoRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const getProjectInfoSuccess = (state = INITIAL_STATE, { project }) => ({
  ...state,
  loading: false,
  changed: false,
  data: {
    ...state.data,
    ...project,
  },
});

const getProjectInfoFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const addProjectPageRequest = (state = INITIAL_STATE) => ({
  ...state,
});

const addProjectPageSuccess = (state = INITIAL_STATE, { title, slug }) => {
  const newPageId = crypto.randomUUID();
  return {
    ...state,
    changed: true,
    data: {
      ...state.data,
      activePage: newPageId,
      pages: [
        ...state.data.pages,
        {
          id: newPageId,
          title,
          slug,
          content: {
            ...createDefaultContent(),
            header: state.data.pages[0].content.header,
            footer: state.data.pages[0].content.footer,
          },
        },
      ],
    },
  };
};

const selectProjectPage = (state = INITIAL_STATE, { pageId }) => ({
  ...state,
  selectedAppElementIndex: null,
  data: {
    ...state.data,
    activePage: pageId,
  },
});

const deleteProjectPage = (state = INITIAL_STATE, { pageId }) => {
  const updatedPagesArray = state.data.pages.filter((page) => page.id !== pageId);
  const lastPage = updatedPagesArray[updatedPagesArray.length - 1];

  return {
    ...state,
    changed: true,
    data: {
      ...state.data,
      activePage: lastPage.id,
      pages: updatedPagesArray,
    },
  };
};

const setSelectedAppElementIndex = (state = INITIAL_STATE, { index }) => ({
  ...state,
  selectedAppElementIndex: index,
});

//

const addBodyElement = (state = INITIAL_STATE, { pageId, data }) => {
  const element = appElementsInitialState[data.type];
  return {
    ...state,
    changed: true,
    data: {
      ...state.data,
      pages: state.data.pages.map((page) => {
        const newElements = [...page.content.body.elements, element];
        const updatedPage = updatePageBodyElements(page, newElements);
        if (page.id === pageId) {
          return {
            ...page,
            content: updatedPage,
          };
        }

        return page;
      }),
    },
  };
};

const updateBodyElement = (state = INITIAL_STATE, { pageId, index, data }) => ({
  ...state,
  changed: true,
  data: {
    ...state.data,
    pages: state.data.pages.map((page) => {
      if (page.id === pageId) {
        const { elements } = page.content.body;
        const updatedElements = updateElementOnArrayBasedOnIndex(elements, index, data);
        const updatedPage = updatePageBodyElements(page, updatedElements);
        return {
          ...page,
          content: updatedPage,
        };
      }
      return page;
    }),
  },
});

const removeBodyElement = (state = INITIAL_STATE, { pageId, index }) => ({
  ...state,
  changed: true,
  data: {
    ...state.data,
    pages: state.data.pages.map((page) => {
      if (page.id === pageId) {
        const { elements } = page.content.body;
        const updatedElements = removeElementFromArrayBasedOnIndex(elements, index);
        const updatedPage = updatePageBodyElements(page, updatedElements);
        return {
          ...page,
          content: updatedPage,
        };
      }
      return page;
    }),
  },
});
//
// currently updating footer on all pages
const updateFooterElement = (state = INITIAL_STATE, { index, data }) => ({
  ...state,
  changed: true,
  data: {
    ...state.data,
    pages: state.data.pages.map((page) => {
      const { elements } = page.content.footer;
      const updatedElements = updateElementOnArrayBasedOnIndex(elements, index, data);
      const updatedPage = updateProjectFooterElements(page, updatedElements);

      return {
        ...page,
        content: updatedPage,
      };
    }),
  },
});

// currently removing footer on all pages
const removeFooterElement = (state = INITIAL_STATE, { index }) => ({
  ...state,
  changed: true,
  data: {
    ...state.data,
    pages: state.data.pages.map((page) => {
      const { elements } = page.content.footer;
      const updatedElements = removeElementFromArrayBasedOnIndex(elements, index);
      const updatedPage = updateProjectFooterElements(page, updatedElements);
      return {
        ...page,
        content: updatedPage,
      };
    }),
  },
});

const updateHeaderElementLogoRequest = (state = INITIAL_STATE, { data }) => {
  const dataToUpdate = {
    tempImage: data.tempImage,
  };
  return {
    ...state,
    loadingUploadImage: true,
    data: {
      ...state.data,
      pages: state.data.pages.map((page) => {
        const updatedProject = updateProjectHeaderLogo(page, dataToUpdate);
        return {
          ...page,
          content: updatedProject,
        };
      }),
    },
  };
};

const updateHeaderElementLogoSuccess = (state = INITIAL_STATE, { imageData }) => {
  const dataToUpdate = {
    image: imageData,
    tempImage: { src: null, file: null },
  };

  return {
    ...state,
    changed: true,
    loadingUploadImage: false,
    data: {
      ...state.data,
      pages: state.data.pages.map((page) => {
        const updatedProject = updateProjectHeaderLogo(page, dataToUpdate);
        return {
          ...page,
          content: updatedProject,
        };
      }),
    },
  };
};

const updateHeaderElementLogoFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingUploadImage: false,
});

//

const deleteHeaderElementLogo = (state = INITIAL_STATE) => ({
  ...state,
  changed: true,
  data: {
    ...state.data,
    pages: state.data.pages.map((page) => {
      const dataToUpdate = {
        tempImage: { src: null, file: null },
        image: null,
      };
      const updatedProject = updateProjectHeaderLogo(page, dataToUpdate);
      return {
        ...page,
        content: updatedProject,
      };
    }),
  },
});

const updateBodyElementImageRequest = (
  state = INITIAL_STATE,
  { pageId, index, data }
) => {
  const dataToUpdate = {
    tempImage: data.tempImage,
  };

  return {
    ...state,
    loadingUploadImage: true,
    data: {
      ...state.data,
      pages: state.data.pages.map((page) => {
        if (page.id === pageId) {
          const { elements } = page.content.body;

          const updatedElements = updateElementOnArrayBasedOnIndex(
            elements,
            index,
            dataToUpdate
          );

          const updatedProject = updatePageBodyElements(page, updatedElements);
          return {
            ...page,
            content: updatedProject,
          };
        }
        return page;
      }),
    },
  };
};

const updateBodyElementImageSuccess = (
  state = INITIAL_STATE,
  { pageId, index, imageData }
) => {
  const dataToUpdate = {
    image: imageData,
    tempImage: { src: null, file: null },
  };
  return {
    ...state,
    changed: true,
    loadingUploadImage: false,
    data: {
      ...state.data,
      pages: state.data.pages.map((page) => {
        if (page.id === pageId) {
          const { elements } = page.content.body;

          const updatedElements = updateElementOnArrayBasedOnIndex(
            elements,
            index,
            dataToUpdate
          );

          const updatedProject = updatePageBodyElements(page, updatedElements);
          return {
            ...page,
            content: updatedProject,
          };
        }
        return page;
      }),
    },
  };
};

const updateBodyElementImageFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingUploadImage: false,
});

//

const deleteBodyElementImage = (state = INITIAL_STATE, { pageId, index }) => ({
  ...state,
  changed: true,
  data: {
    ...state.data,
    pages: state.data.pages.map((page) => {
      if (page.id === pageId) {
        const dataToUpdate = {
          tempImage: { src: null, file: null },
          image: null,
        };
        const { elements } = page.content.body;
        const updatedElements = updateElementOnArrayBasedOnIndex(
          elements,
          index,
          dataToUpdate
        );

        const updatedProject = updatePageBodyElements(page, updatedElements);
        return {
          ...page,
          content: updatedProject,
        };
      }
      return page;
    }),
  },
});

const addBodyElementArrayItem = (
  state = INITIAL_STATE,
  { pageId, index, elementType }
) => {
  const uuid = crypto.randomUUID();
  const elementData = {
    ...appElementsInitialState[appElementTypes[elementType]],
    id: uuid,
  };

  return {
    ...state,
    changed: true,
    data: {
      ...state.data,
      pages: state.data.pages.map((page) => {
        if (page.id === pageId) {
          const newElements = [
            ...page.content.body.elements[index].elements,
            elementData,
          ];
          const updatedPage = updateBodyArrayElements(page, newElements, index);
          return {
            ...page,
            content: updatedPage,
          };
        }
        return page;
      }),
    },
  };
};

const updateBodyElementArrayItem = (
  state = INITIAL_STATE,
  { pageId, index, choiceIndex, data }
) => ({
  ...state,
  changed: true,
  data: {
    ...state.data,
    pages: state.data.pages.map((page) => {
      if (page.id === pageId) {
        const newElements = updateElementOnArrayBasedOnIndex(
          page.content.body.elements[index].elements,
          choiceIndex,
          data
        );
        const updatedPage = updateBodyArrayElements(page, newElements, index);
        return {
          ...page,
          content: updatedPage,
        };
      }
      return page;
    }),
  },
});

const removeBodyElementArrayItem = (
  state = INITIAL_STATE,
  { pageId, index, choiceIndex }
) => ({
  ...state,
  changed: true,
  data: {
    ...state.data,
    pages: state.data.pages.map((page) => {
      if (page.id === pageId) {
        const newElements = removeElementFromArrayBasedOnIndex(
          page.content.body.elements[index].elements,
          choiceIndex
        );
        const updatedPage = updateBodyArrayElements(page, newElements, index);
        return {
          ...page,
          content: updatedPage,
        };
      }
      return page;
    }),
  },
});

const updateBodyElementArrayItemImageRequest = (
  state = INITIAL_STATE,
  { pageId, index, choiceIndex, data }
) => {
  const dataToUpdate = {
    tempImage: data,
  };

  return {
    ...state,
    changed: true,
    loadingUploadImage: true,
    data: {
      ...state.data,
      pages: state.data.pages.map((page) => {
        if (page.id === pageId) {
          const newElements = updateElementOnArrayBasedOnIndex(
            page.content.body.elements[index].elements,
            choiceIndex,
            dataToUpdate
          );
          const updatedPage = updateBodyArrayElements(page, newElements, index);
          return {
            ...page,
            content: updatedPage,
          };
        }
        return page;
      }),
    },
  };
};

const updateBodyElementArrayItemImageSuccess = (
  state = INITIAL_STATE,
  { pageId, index, choiceIndex, imageData }
) => {
  const dataToUpdate = {
    image: imageData,
    tempImage: { src: null, file: null },
  };
  return {
    ...state,
    changed: true,
    loadingUploadImage: false,
    data: {
      ...state.data,
      pages: state.data.pages.map((page) => {
        if (page.id === pageId) {
          const newElements = updateElementOnArrayBasedOnIndex(
            page.content.body.elements[index].elements,
            choiceIndex,
            dataToUpdate
          );
          const updatedPage = updateBodyArrayElements(page, newElements, index);
          return {
            ...page,
            content: updatedPage,
          };
        }
        return page;
      }),
    },
  };
};

const updateBodyElementArrayItemImageFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingUploadImage: false,
});

const deleteBodyElementArrayItemImage = (
  state = INITIAL_STATE,
  { pageId, index, choiceIndex }
) => {
  const dataToUpdate = {
    tempImage: { src: null, file: null },
    image: null,
  };

  return {
    ...state,
    changed: true,
    loadingUploadImage: false,
    data: {
      ...state.data,
      pages: state.data.pages.map((page) => {
        if (page.id === pageId) {
          const newElements = updateElementOnArrayBasedOnIndex(
            page.content.body.elements[index].elements,
            choiceIndex,
            dataToUpdate
          );
          const updatedPage = updateBodyArrayElements(page, newElements, index);
          return {
            ...page,
            content: updatedPage,
          };
        }
        return page;
      }),
    },
  };
};

//
const addFooterElementRequest = (state = INITIAL_STATE) => ({
  ...state,
});

// currently adding footer on all pages
const addFooterElementSuccess = (state = INITIAL_STATE, { element }) => ({
  ...state,
  changed: true,
  data: {
    ...state.data,
    pages: state.data.pages.map((page) => {
      const newElements = [...page.content.footer.elements, element];
      const updatedPage = updateProjectFooterElements(page, newElements);
      return {
        ...page,
        content: updatedPage,
      };
    }),
  },
});

const addFooterElementFailure = (state = INITIAL_STATE) => ({
  ...state,
});

//

const saveProjectBuildRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingSaveProject: true,
});

const saveProjectBuildSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  changed: false,
  loadingSaveProject: false,
  data: {
    ...state.data,
    ...data,
  },
});

const saveProjectBuildFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingSaveProject: false,
});

const publishProjectRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingPublishProject: true,
  data: {
    ...state.data,
    updated: true,
  },
});

const publishProjectSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loadingPublishProject: false,
});

const publishProjectFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingPublishProject: false,
  data: {
    ...state.data,
    updated: false,
  },
});

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [ProjectBuilderTypes.GET_PROJECT_INFO_REQUEST]: getProjectInfoRequest,
  [ProjectBuilderTypes.GET_PROJECT_INFO_SUCCESS]: getProjectInfoSuccess,
  [ProjectBuilderTypes.GET_PROJECT_INFO_FAILURE]: getProjectInfoFailure,

  [ProjectBuilderTypes.ADD_PROJECT_PAGE_REQUEST]: addProjectPageRequest,
  [ProjectBuilderTypes.ADD_PROJECT_PAGE_SUCCESS]: addProjectPageSuccess,

  [ProjectBuilderTypes.SELECT_PROJECT_PAGE]: selectProjectPage,
  [ProjectBuilderTypes.DELETE_PROJECT_PAGE]: deleteProjectPage,

  [ProjectBuilderTypes.SET_SELECTED_APP_ELEMENT_INDEX]: setSelectedAppElementIndex,

  [ProjectBuilderTypes.ADD_BODY_ELEMENT]: addBodyElement,
  [ProjectBuilderTypes.UPDATE_BODY_ELEMENT]: updateBodyElement,
  [ProjectBuilderTypes.REMOVE_BODY_ELEMENT]: removeBodyElement,

  [ProjectBuilderTypes.ADD_BODY_ELEMENT_ARRAY_ITEM]: addBodyElementArrayItem,
  [ProjectBuilderTypes.UPDATE_BODY_ELEMENT_ARRAY_ITEM]: updateBodyElementArrayItem,
  [ProjectBuilderTypes.REMOVE_BODY_ELEMENT_ARRAY_ITEM]: removeBodyElementArrayItem,

  [ProjectBuilderTypes.UPDATE_BODY_ELEMENT_ARRAY_ITEM_IMAGE_REQUEST]:
    updateBodyElementArrayItemImageRequest,
  [ProjectBuilderTypes.UPDATE_BODY_ELEMENT_ARRAY_ITEM_IMAGE_SUCCESS]:
    updateBodyElementArrayItemImageSuccess,
  [ProjectBuilderTypes.UPDATE_BODY_ELEMENT_ARRAY_ITEM_IMAGE_FAILURE]:
    updateBodyElementArrayItemImageFailure,

  [ProjectBuilderTypes.DELETE_BODY_ELEMENT_ARRAY_ITEM_IMAGE]:
    deleteBodyElementArrayItemImage,

  [ProjectBuilderTypes.UPDATE_FOOTER_ELEMENT]: updateFooterElement,
  [ProjectBuilderTypes.REMOVE_FOOTER_ELEMENT]: removeFooterElement,

  [ProjectBuilderTypes.ADD_FOOTER_ELEMENT_REQUEST]: addFooterElementRequest,
  [ProjectBuilderTypes.ADD_FOOTER_ELEMENT_SUCCESS]: addFooterElementSuccess,
  [ProjectBuilderTypes.ADD_FOOTER_ELEMENT_FAILURE]: addFooterElementFailure,

  [ProjectBuilderTypes.UPDATE_BODY_ELEMENT_IMAGE_REQUEST]: updateBodyElementImageRequest,
  [ProjectBuilderTypes.UPDATE_BODY_ELEMENT_IMAGE_SUCCESS]: updateBodyElementImageSuccess,
  [ProjectBuilderTypes.UPDATE_BODY_ELEMENT_IMAGE_FAILURE]: updateBodyElementImageFailure,

  [ProjectBuilderTypes.DELETE_BODY_ELEMENT_IMAGE]: deleteBodyElementImage,

  [ProjectBuilderTypes.UPDATE_HEADER_ELEMENT_LOGO_REQUEST]:
    updateHeaderElementLogoRequest,
  [ProjectBuilderTypes.UPDATE_HEADER_ELEMENT_LOGO_SUCCESS]:
    updateHeaderElementLogoSuccess,
  [ProjectBuilderTypes.UPDATE_HEADER_ELEMENT_LOGO_FAILURE]:
    updateHeaderElementLogoFailure,

  [ProjectBuilderTypes.DELETE_HEADER_ELEMENT_LOGO]: deleteHeaderElementLogo,

  [ProjectBuilderTypes.SAVE_PROJECT_BUILD_REQUEST]: saveProjectBuildRequest,
  [ProjectBuilderTypes.SAVE_PROJECT_BUILD_SUCCESS]: saveProjectBuildSuccess,
  [ProjectBuilderTypes.SAVE_PROJECT_BUILD_FAILURE]: saveProjectBuildFailure,

  [ProjectBuilderTypes.PUBLISH_PROJECT_REQUEST]: publishProjectRequest,
  [ProjectBuilderTypes.PUBLISH_PROJECT_SUCCESS]: publishProjectSuccess,
  [ProjectBuilderTypes.PUBLISH_PROJECT_FAILURE]: publishProjectFailure,
});
