import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import DashboardContainer from '~/containers/DashboardContainer';

const authSelector = ({ session }) => session.token;

function PrivateRoute({ children }) {
  const auth = useSelector(authSelector);

  return auth ? (
    <DashboardContainer>{children}</DashboardContainer>
  ) : (
    <Navigate to="/signin" />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

PrivateRoute.defaultProps = {
  children: null,
};

export default PrivateRoute;
