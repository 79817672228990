import { Stack, styled, Table, TableContainer } from '@mui/material';

export const LeadsContainerStack = styled(Stack)(({ theme }) => ({
  rowGap: theme.spacing(4),
  minHeight: 'calc(100vh - 88px - 32px - 116.5px - 40px)',

  paddingBottom: theme.spacing(3),

  paddingLeft: '32px',
  paddingRight: '32px',

  borderRadius: '20px',

  background: theme.palette.grey[900],
}));

export const LeadTableStyled = styled(Table)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  '& .MuiTableHead-root': {
    background: theme.palette.grey[800],
  },
}));

export const TableContainerStyled = styled(TableContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  whiteSpace: 'nowrap',
}));
