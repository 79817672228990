import { Box, Button, styled } from '@mui/material';

export const ButtonGroup = styled(Box)(({ theme }) => ({
  margin: '8px 0 !important',
  borderRadius: '4px',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);',

  '.button-actived': {
    backgroundColor: theme.palette.primary.main,
  },

  'button:first-of-type': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  'button:last-of-type': {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
}));

export const ButtonStyle = styled(Button)(({ theme }) => ({
  width: '25%',
  color: theme.palette.common.black,
  borderRadius: 0,
  background: theme.palette.primary.light,
  borderRight: `1px solid ${theme.palette.primary.main}`,
  padding: '9px 0',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
