import * as yup from 'yup';

yup.setLocale({
  mixed: {
    notType: { id: 'form.validation.invalid' },
    required: { id: 'form.validation.required' },
  },
  string: {
    email: { id: 'form.validation.email' },
    max: ({ max }) => ({
      id: 'form.validation.maxLength',
      values: { maxLength: max },
    }),
    min: ({ min }) => ({
      id: 'form.validation.minLength',
      values: { minLength: min },
    }),
    url: {
      id: 'form.validation.url',
    },
  },
  number: {
    notType: { id: 'form.validation.number' },
    integer: { id: 'form.validation.integer' },
    positive: { id: 'form.validation.positive' },
    moreThan: ({ more }) => ({
      id: 'form.validation.maxValue',
      values: { maxValue: more },
    }),
    lessThan: { id: 'form.validation.invalid' },
    max: ({ max }) => ({
      id: 'form.validation.maxValue',
      values: { maxValue: max },
    }),
    min: ({ min }) => ({
      id: 'form.validation.minValue',
      values: { minValue: min },
    }),
  },
});

const patterns = {
  domain: /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
};
yup.addMethod(
  yup.string,
  'domain',
  function pattern(name, message = { id: 'form.validation.invalid' }) {
    const domainRules = [patterns.domain];

    return this.test({
      message,
      test: (value) =>
        value === null ||
        value === '' ||
        value === undefined ||
        domainRules.some((regex) => regex.test(value)),
    });
  }
);

export const signInValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

export const registerValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(6),
  confirm: yup.string().oneOf([yup.ref('password'), null], {
    id: 'form.validation.password_confirm',
  }),
});

export const recoverValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export const newProjectValidationSchema = yup.object().shape({
  name: yup.string().required(),
  slug: yup.string().required(),
  goals: yup.string(),
  budget: yup
    .number()
    .min(0)
    .max(10000000)
    .transform((value, originalValue) => (originalValue === '' ? 0 : value)),
  client: yup.string(),
  webhook: yup.string().url().nullable(),
  description: yup.string().max(200),
});

export const newPageValidationSchema = yup.object().shape({
  title: yup.string().required(),
});

export const projectSettingsValidationSchema = yup.object().shape({
  name: yup.string().required(),
  slug: yup.string().required(),
  goals: yup.string().nullable(),
  budget: yup
    .number()
    .min(0)
    .max(10000000)
    .transform((value, originalValue) => (originalValue === '' ? 0 : value)),
  client: yup.string().nullable(),
  webhook: yup.string().url().nullable(),
  description: yup.string().max(200).nullable(),
});

export const projectDomainValidationSchema = yup.object().shape({
  domain: yup.string().domain().required(),
});

export const metaInformationValidationSchema = yup.object().shape({
  metadata: yup.object().shape({
    tagManagerId: yup.string().required(),
    cookieBanner: yup.bool().required(),
  }),
});

export default yup;
