import { Box, styled } from '@mui/material';

export const DesktopLogo = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  svg: {
    width: 95,
    height: 'auto',
  },
  // display: 'none',
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  // },
}));

// export const MobileLogo = styled(Typography)(({ theme }) => ({
//   display: 'flex',
//   flexGrow: 1,
//   justifyContent: 'center',
//   [theme.breakpoints.up('md')]: {
//     display: 'none',
//   },
// }));

// export const MobileMenu = styled('div')(({ theme }) => ({
//   flexGrow: 0,
//   display: 'flex',
//   [theme.breakpoints.up('md')]: {
//     display: 'none',
//   },
// }));

export const DesktopMenu = styled('div')(() => ({
  flexGrow: 1,
  // display: 'none',
  // marginTop: theme.spacing(2),
  // marginBottom: theme.spacing(2),
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  // },
}));

export const UserMenu = styled('div')(() => ({
  flexGrow: 0,
}));

export const PageContent = styled(Box)(() => ({
  display: 'flex',
  flex: 1,

  marginTop: '64px', // app bar height
  overflow: 'auto',

  '> :first-of-type': {
    height: 'fit-content',
  },
}));
