import { Button, Typography, Modal } from '@mui/material';

import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalStyle, ActionButtonGroup } from './styles';

const defaultMessagesIds = {
  title: 'delete.modal.confirmation.title',
  content: 'delete.modal.confirmation.message',
  cancel: 'delete.modal.confirmation.cancel',
  confirm: 'delete.modal.confirmation.delete',
};

function DeleteModalConfirmation({ open, messages, onClose, onDeleteConfirmation }) {
  const handleDeleteConfirmation = useCallback(() => {
    onDeleteConfirmation();
    onClose();
  }, [onClose, onDeleteConfirmation]);

  const messagesIds = useMemo(
    () => ({
      ...defaultMessagesIds,
      ...messages,
    }),
    [messages]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalStyle>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <FormattedMessage id={messagesIds.title} />
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <FormattedMessage id={messagesIds.content} />
        </Typography>

        <ActionButtonGroup>
          <Button onClick={onClose} color="info">
            <FormattedMessage id={messagesIds.cancel} />
          </Button>

          <Button onClick={handleDeleteConfirmation} color="info">
            <FormattedMessage id={messagesIds.confirm} />
          </Button>
        </ActionButtonGroup>
      </ModalStyle>
    </Modal>
  );
}

DeleteModalConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDeleteConfirmation: PropTypes.func.isRequired,
  messages: PropTypes.shape({}),
};

DeleteModalConfirmation.defaultProps = {
  messages: {},
};

export default DeleteModalConfirmation;
