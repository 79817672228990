import { Box, styled } from '@mui/material';

export const NavigationHeader = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: '22px 24px',

  zIndex: theme.zIndex.drawer,
  background: theme.palette.background.default,
}));
