import { Box, Typography, Stack, TextField } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import ImageDropzone from '~/components/ImageDropzone';

function ImageSettings({ element, index }) {
  const dispatch = useDispatch();
  const { project } = useSelector(projectBuilderSelector);

  const handleImageChange = useCallback(
    (props) => {
      if (!props) {
        dispatch(ProjectBuilderActions.deleteBodyElementImage(project.id, index));
        return;
      }
      const data = { tempImage: props };
      dispatch(
        ProjectBuilderActions.updateBodyElementImageRequest(project.id, index, data)
      );
    },
    [dispatch, index, project.id]
  );

  const handleTextChange = useCallback(
    (event) => {
      const text = event.target.value;
      dispatch(ProjectBuilderActions.updateBodyElement(project.id, index, { text }));
    },
    [index, project.id]
  );

  const imageSrc = useMemo(() => element.tempImage.src || element.image?.url, [element]);

  return (
    <Stack direction="column" spacing={4.5}>
      <Box>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.image.dropzone" />
        </Typography>

        <ImageDropzone
          sx={{ mt: 1, height: '133px' }}
          defaultImageSrc={imageSrc}
          onImageChange={handleImageChange}
        />
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography variant="caption" mb={1}>
          <FormattedMessage id="projectbuilder.image.text" />
        </Typography>

        <TextField
          type="text"
          fullWidth
          variant="outlined"
          value={element.text}
          onChange={handleTextChange}
        />
      </Box>
    </Stack>
  );
}

ImageSettings.propTypes = {
  element: PropTypes.shape({
    text: PropTypes.string,
    src: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    tempImage: PropTypes.shape({
      src: PropTypes.string,
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default ImageSettings;
