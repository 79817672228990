import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Typography,
  Stack,
  Button,
  Tooltip,
  IconButton,
  Link,
  Breadcrumbs,
  Box,
  List,
  Tab,
} from '@mui/material';

import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link as ReactRouterLink } from 'react-router-dom';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import AppBuilder from '~/components/AppBuilder';
import AppElementSettings from '~/components/AppBuilder/AppElementSettings';
import WarningButton from '~/components/Buttons/WarningButton';
import CircularLoading from '~/components/CircularLoading';
import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';
import PermanentDrawer from '~/components/PermanentDrawer';

// import LastSaveDate from './components/LastSaveDate';
import AddPagePopover from './components/AddPagePopover';
import DeleteTooltip from './components/DeleteTooltip';
import {
  AppBuilderWrapper,
  ContainerStyled,
  HeaderStyled,
  ListItemButtonStyled,
  ListItemStyled,
  SaveProjectButtonBox,
  ToogleDrawerButton,
} from './styles';

function ProjectBuilderPage() {
  const appBuilderWrapperRef = useRef(null);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletePageId, setDeletePageId] = useState(null);
  const [tab, setTab] = useState('1');

  const intl = useIntl();
  const params = useParams();
  const dispatch = useDispatch();

  const {
    changed: projectChanged,
    pages,
    fullProject,
    loading,
    loadingUploadImage,
    loadingSaveProject,
    loadingPublishProject,
  } = useSelector(projectBuilderSelector);

  const onlyOnePage = fullProject.pages.length === 1;

  const handleSaveProjectBuild = useCallback(() => {
    dispatch(ProjectBuilderActions.saveProjectBuildRequest());
  }, [dispatch]);

  const handlePublishProject = useCallback(() => {
    dispatch(ProjectBuilderActions.publishProjectRequest());
  }, [dispatch]);

  const handleAddNewPage = (title) => {
    dispatch(ProjectBuilderActions.addProjectPageRequest(title));
  };

  const handleSelectPage = (pageId) => {
    dispatch(ProjectBuilderActions.selectProjectPage(pageId));
  };

  const handleDeletePage = useCallback(() => {
    dispatch(ProjectBuilderActions.deleteProjectPage(deletePageId));
  }, [deletePageId]);

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = useCallback((pageId) => {
    setDeleteModalOpen(true);
    setDeletePageId(pageId);
  }, []);

  const handleUnselectAppElements = useCallback(
    (event) => {
      const containerClicked = event.target === appBuilderWrapperRef.current;

      if (containerClicked) {
        dispatch(ProjectBuilderActions.setSelectedAppElementIndex(null));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(ProjectBuilderActions.getProjectInfoRequest(params.id));
  }, [params]);

  const publishWarning = useMemo(() => {
    if (fullProject.updated === false) {
      return intl.formatMessage({ id: 'project.unpublished_changes' });
    }

    return null;
  }, [fullProject]);

  const saveWarning = useMemo(
    () => (projectChanged ? intl.formatMessage({ id: 'project.unsaved_changes' }) : null),
    [fullProject]
  );

  if (loading) {
    return <CircularLoading />;
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeletePage}
        messages={{
          title: 'projectbuilder.page.delete.modal.title',
          content: 'projectbuilder.page.delete.modal.content',
        }}
      />

      <PermanentDrawer disableGutters anchor="left" open={openDrawer}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} variant="fullWidth">
              <Tab
                label={<FormattedMessage id="projectbuilder.drawer.tab.pages" />}
                value="1"
                sx={{ textTransform: 'none' }}
              />
              <Tab
                label={<FormattedMessage id="projectbuilder.drawer.tab.ai_prompt" />}
                value="2"
                sx={{ textTransform: 'none' }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Stack gap={5}>
              <List>
                {pages.map((page, index) => (
                  <ListItemStyled key={page.id} disablePadding disableGutters>
                    <ListItemButtonStyled
                      color="primary"
                      onClick={() => handleSelectPage(page.id)}
                      selected={page.id === fullProject.activePage}
                    >
                      <Box component="span">{index + 1}</Box>
                      <Typography sx={{ ml: '24px' }}>{page.title}</Typography>
                    </ListItemButtonStyled>
                    <DeleteTooltip
                      selected={page.id === fullProject.activePage}
                      disabled={onlyOnePage}
                      handleOpenDeleteModal={() => handleOpenDeleteModal(page.id)}
                    />
                  </ListItemStyled>
                ))}
              </List>
              <Box alignSelf="center">
                <AddPagePopover handleAddNewPage={handleAddNewPage} />
              </Box>
            </Stack>
          </TabPanel>
          <TabPanel value="2">AI Prompt</TabPanel>
        </TabContext>
      </PermanentDrawer>

      <ContainerStyled>
        <ToogleDrawerButton
          type="button"
          onClick={() => setOpenDrawer((state) => !state)}
        >
          {openDrawer ? (
            <ChevronLeftIcon fontSize="medium" />
          ) : (
            <ChevronRightIcon fontSize="medium" />
          )}
        </ToogleDrawerButton>
        <HeaderStyled>
          <Breadcrumbs
            aria-label="navigation-breadcrumb"
            separator={<ChevronRightIcon />}
            sx={{ alignSelf: 'flex-start' }}
          >
            <Link underline="hover" color="inherit" href="/">
              <FormattedMessage id="projectbuilder.all_projects" />
            </Link>

            <Link
              underline="hover"
              color="text.primary"
              component={ReactRouterLink}
              to={`/project-builder/${fullProject.id}`}
              aria-current="page"
            >
              <Typography variant="body1">{fullProject.name}</Typography>
            </Link>
          </Breadcrumbs>

          <Stack direction="row" spacing={2}>
            <Tooltip title={<FormattedMessage id="projectbuilder.settings" />}>
              <IconButton
                LinkComponent={ReactRouterLink}
                to={`/projects/${params.id}/settings`}
                sx={{ mr: -1 }}
              >
                <SettingsIcon color="secondary" />
              </IconButton>
            </Tooltip>

            <Button
              variant="contained"
              color="secondary"
              LinkComponent={ReactRouterLink}
              to={`/projects/${params.id}/preview`}
              target="_blank"
            >
              <FormattedMessage id="projectbuilder.preview" />
            </Button>

            <SaveProjectButtonBox>
              <WarningButton
                boxProps={{ sx: { height: '100%' } }}
                loading={loadingSaveProject}
                disabled={loadingUploadImage}
                variant="contained"
                onClick={handleSaveProjectBuild}
                warning={saveWarning}
              >
                <SaveIcon fontSize="small" sx={{ mr: 1 }} />
                <FormattedMessage id="projectbuilder.drawer.save" />
              </WarningButton>

              {/* <LastSaveDate /> */}
            </SaveProjectButtonBox>

            <WarningButton
              loading={loadingPublishProject}
              disabled={loadingPublishProject}
              variant="contained"
              color="primary"
              onClick={handlePublishProject}
              warning={publishWarning}
            >
              <FormattedMessage id="projectbuilder.publish" />
            </WarningButton>
          </Stack>
        </HeaderStyled>

        <AppBuilderWrapper
          onClick={handleUnselectAppElements}
          ref={appBuilderWrapperRef}
          className="scroll__default"
        >
          <AppBuilder />
        </AppBuilderWrapper>
      </ContainerStyled>

      <PermanentDrawer>
        <Typography variant="subtitle1" textAlign="center" mb={4.5}>
          <FormattedMessage id="projectbuilder.drawer.title" />
        </Typography>

        <AppElementSettings />
      </PermanentDrawer>
    </>
  );
}

ProjectBuilderPage.propTypes = {};

export default ProjectBuilderPage;
