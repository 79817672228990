import { Box, Stack, styled } from '@mui/material';

export const AppContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  width: '100%',
  maxWidth: '375px',

  minHeight: '100%',
  height: 'max-content',

  paddingBottom: '32px',

  background: theme.palette.common.white,
  borderRadius: '10px',

  fontFamily: 'Poppins, sans-serif',
}));

export const AppHeader = styled(Box)(() => ({
  paddingTop: '8px',
  borderRadius: '10px 10px 0 0',
}));

export const AppElementsStack = styled(Stack)(() => ({
  alignItems: 'center',
  flexDirection: 'column',

  paddingLeft: '12px',
  paddingRight: '12px',
}));
