import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import StartContainer from '~/containers/StartContainer';

import StartFormWrapper from '~/components/StartFormWrapper';

import { SessionActions } from '../../store/ducks/session';
import RegisterForm from './components/RegisterForm';

const loadingSelector = ({ session }) => session.loading;

function RegisterPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);

  const handleSubmit = useCallback(
    (data) => {
      dispatch(
        SessionActions.createAccountRequest(data, () => {
          navigate('/');
        })
      );
    },
    [dispatch, navigate]
  );

  return (
    <StartContainer>
      <StartFormWrapper>
        <RegisterForm loading={loading} onSubmit={handleSubmit} />
      </StartFormWrapper>
    </StartContainer>
  );
}

export default RegisterPage;
