import { Grid } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectActions } from '~/store/ducks/project';

import { projectSelector } from '~/helpers/project';
import { projectSettingsValidationSchema } from '~/helpers/yup';

import { ProjectInfoFormFields } from '~/components/FormComponents';
import ImageDropzone from '~/components/ImageDropzone';

import ActionsForm from '../ActionsForm';
import { ProjectNameTypography, ProjectSettingsFormRoot } from '../styles';

function ProjectSettingsForm({ onSubmit }) {
  const dispatch = useDispatch();

  const { project } = useSelector(projectSelector);

  const methods = useForm({
    resolver: yupResolver(projectSettingsValidationSchema),
  });

  const { handleSubmit, formState, control, setValue } = methods;

  const handleLogoChange = useCallback(
    (imageData) => {
      if (imageData) {
        dispatch(ProjectActions.uploadLogoRequest(project.id, imageData.file));
        return;
      }

      dispatch(ProjectActions.deleteLogoRequest(project.id));
    },
    [project.id]
  );

  useEffect(() => {
    methods.reset({
      name: project.name,
      slug: project.slug,
      goals: project.goals,
      budget: project.budget,
      client: project.client,
      webhook: project.webhook,
      description: project.description,
    });
  }, [methods.reset, project]);

  return (
    <FormProvider
      formState={formState}
      control={control}
      setValue={setValue}
      {...methods}
    >
      <ProjectSettingsFormRoot onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} sx={{ py: 4 }}>
          <Grid item xs={12}>
            <ProjectNameTypography variant="h4" component="h1">
              {project.name}
            </ProjectNameTypography>
          </Grid>

          <ProjectInfoFormFields />

          <Grid item xs={12}>
            <ImageDropzone
              defaultImageSrc={project.logo?.url}
              onImageChange={handleLogoChange}
              defaultTextId="project.dropzone.text"
              sx={{ height: '200px' }}
            />
          </Grid>
        </Grid>

        <ActionsForm />
      </ProjectSettingsFormRoot>
    </FormProvider>
  );
}

ProjectSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProjectSettingsForm;
