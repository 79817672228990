import { createActions, createReducer } from 'reduxsauce';

/**
 * Types
 */
export const { Types: FlashMessageTypes, Creators: FlashMessageActions } = createActions({
  showMessage: ['settings'],
  hideMessage: [],
});

/**
 * Reducer handlers
 */
const INITIAL_STATE = {
  id: null,
  message: null,
  open: false,
  variant: 'error',
  params: null,
};

const showMessage = (state = INITIAL_STATE, action) => ({
  ...state,
  id: action.settings.id || null,
  message: action.settings.message || null,
  variant: action.settings.variant || 'error',
  params: action.settings.params || null,
  open: true,
});

const hideMessage = (state = INITIAL_STATE) => ({
  ...state,
  id: null,
  message: null,
  params: null,
  variant: 'error',
  open: false,
});

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [FlashMessageTypes.SHOW_MESSAGE]: showMessage,
  [FlashMessageTypes.HIDE_MESSAGE]: hideMessage,
});
