import {
  Add as AddIcon,
  FormatListBulleted as ListIcon,
  CalendarViewMonth as CardIcon,
} from '@mui/icons-material';
import { Button, IconButton, Box, Grid, Typography, Fab } from '@mui/material';

import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ProjectsActions } from '~/store/ducks/projects';

import CircularLoading from '~/components/CircularLoading';

import ProjectCard from './components/ProjectCard';
import ProjectsTableList from './components/ProjectsTableList';
import { ContainerStyled } from './styles';

const projectsSelector = ({ projects }) => ({
  projects: projects.data,
  loading: projects.loading,
});

function ProjectsPage() {
  const dispatch = useDispatch();
  const { projects, loading } = useSelector(projectsSelector);

  const [listView, setListView] = useState(false);

  const handleChangeListView = useCallback(() => {
    setListView(!listView);
  }, [listView]);

  useEffect(() => {
    dispatch(ProjectsActions.getProjectsRequest());
  }, []);

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <ContainerStyled>
      <Box mb={6} display="flex" alignItems="flex-start" justifyContent="space-between">
        <Typography variant="h4" component="h1">
          <FormattedMessage id="overview.title" />
        </Typography>

        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={handleChangeListView} sx={{ mr: 2 }}>
            {listView ? <CardIcon /> : <ListIcon />}
          </IconButton>

          <Button
            LinkComponent={Link}
            to="/new-project"
            variant="contained"
            color="primary"
          >
            <FormattedMessage id="overview.new_project" />
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {!listView &&
          projects.map((project) => (
            <Grid item key={project.id} lg={4} sm={6} xs={12}>
              <ProjectCard
                id={project.id}
                status={project.status}
                publishedUrl={project.publishedUrl}
                link={`/project-builder/${project.id}`}
                createdAt={project.createdAt.substr(0, 19).replace('T', ' ')}
                logo={project.logo?.url}
                name={project.name}
                client={project.client}
                description={project.description}
                updated={project.updated}
              />
            </Grid>
          ))}

        {listView && (
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <ProjectsTableList projects={projects} />
            </Grid>
          </Grid>
        )}

        <Grid container item xs={12} justifyContent="center">
          <Box m={5}>
            <Fab LinkComponent={Link} to="/new-project" color="primary">
              <AddIcon />
            </Fab>
          </Box>
        </Grid>
      </Grid>
    </ContainerStyled>
  );
}

export default ProjectsPage;
