import { Autocomplete, Stack, TextField, Typography } from '@mui/material';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import { ColorInput } from './styles';

function ButtonSettings({ element, index }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [buttonHref, setButtonHref] = useState('');
  const { project, fullProject } = useSelector(projectBuilderSelector);

  const pageOptions = useMemo(
    () =>
      fullProject.pages.reduce((acc, page) => {
        if (page.id !== fullProject.activePage) {
          acc.push({ label: page.title, value: page.slug });
        }
        return acc;
      }, []),
    [fullProject.pages]
  );

  useEffect(() => {
    const currentOption = pageOptions.find(
      (option) => `/${option.value}` === element.href || element.href === `/`
    );
    setButtonHref(currentOption || { label: element.href, value: element.href });
  }, []);

  const handleTextChange = useCallback(
    (event) => {
      const text = event.target.value;
      dispatch(ProjectBuilderActions.updateBodyElement(project.id, index, { text }));
    },
    [index, project.id]
  );

  const handleHrefChange = useCallback(
    (event, newValue) => {
      if (newValue) {
        setButtonHref(newValue);
        const href = newValue.value === '/' ? newValue.value : `/${newValue.value}`;
        dispatch(
          ProjectBuilderActions.updateBodyElement(project.id, index, {
            href,
          })
        );
      } else if (event.target.value) {
        const title = event.target.value;
        const options = { label: title, value: title };
        setButtonHref(options);
        dispatch(
          ProjectBuilderActions.updateBodyElement(project.id, index, {
            href: options.value,
          })
        );
      }
    },
    [index, project.id]
  );

  const handleBackgroundColorChange = useCallback(
    _.debounce((background) => {
      dispatch(
        ProjectBuilderActions.updateBodyElement(project.id, index, { background })
      );
    }, 10),
    [index, project.id]
  );

  const handleTextColorChange = useCallback(
    _.debounce((textColor) => {
      dispatch(ProjectBuilderActions.updateBodyElement(project.id, index, { textColor }));
    }, 10),
    [index, project.id]
  );

  return (
    <Stack spacing={4.5}>
      <Stack spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.button.text" />
        </Typography>

        <TextField
          type="text"
          fullWidth
          variant="outlined"
          value={element.text}
          onChange={handleTextChange}
        />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.button.href" />
        </Typography>

        <Autocomplete
          freeSolo
          id="button-pages-autocomplete"
          options={pageOptions}
          value={buttonHref}
          onChange={handleHrefChange}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={intl.formatMessage({
                id: 'projectbuilder.button.href.placeholder',
              })}
              onChange={handleHrefChange}
            />
          )}
        />
      </Stack>

      <Stack spacing={1} direction="row">
        <Stack direction="column" sx={{ width: '50%' }}>
          <Typography variant="caption">
            <FormattedMessage id="projectbuilder.button.background_color.text" />
          </Typography>

          <ColorInput
            value={element.background}
            onChange={(event) => handleBackgroundColorChange(event.target.value)}
            type="color"
          />
        </Stack>

        <Stack direction="column" sx={{ width: '50%' }}>
          <Typography variant="caption">
            <FormattedMessage id="projectbuilder.button.text_color.text" />
          </Typography>
          <ColorInput
            value={element.textColor}
            onChange={(event) => handleTextColorChange(event.target.value)}
            type="color"
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

const appButtonPropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  href: PropTypes.string,
  textColor: PropTypes.string,
  background: PropTypes.string,
});

ButtonSettings.propTypes = {
  element: appButtonPropTypes.isRequired,
  index: PropTypes.number.isRequired,
};

export default ButtonSettings;
