import { Grid } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as FullLogo } from '~/assets/images/full-logo.svg';

import { StartWrapper, StartPicture, LogoWrapper } from './styles';

function StartContainer({ children }) {
  return (
    <StartWrapper>
      <Grid container>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          lg={5}
          md={6}
          xs={12}
        >
          <LogoWrapper mb={5} width={120} height={114}>
            <FullLogo />
          </LogoWrapper>
          {children}
        </Grid>
        <StartPicture item lg={7} md={6} sx={{ display: { xs: 'none', md: 'block' } }} />
      </Grid>
    </StartWrapper>
  );
}

StartContainer.propTypes = {
  children: PropTypes.node,
};

StartContainer.defaultProps = {
  children: null,
};

export default StartContainer;
