import { Switch } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

function RhfSwitch({ name, control, defaultValue, ...rest }) {
  return (
    <Controller
      render={({ field: { onChange, value, ref, ...props } }) => (
        <Switch
          inputRef={ref}
          checked={value || false}
          onChange={onChange}
          {...props}
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}

RhfSwitch.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.shape({
      id: PropTypes.string,
      values: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    }),
  }),
};

RhfSwitch.defaultProps = {
  defaultValue: null,
  error: null,
};

export default RhfSwitch;
