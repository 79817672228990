import { Box, Button, styled } from '@mui/material';

import { darken } from 'polished';

export const ButtonStyled = styled(Button)(({ element }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100%',
  minHeight: '63px',

  borderRadius: '9px',
  textAlign: 'center',
  overflow: 'hidden',

  fontSize: '1rem',
  lineHeight: '143%',
  fontWeight: 700,
  color: element.textColor,
  background: element.background,

  fontFamily: 'Poppins, sans-serif',

  '&:hover': {
    background: darken(0.15, element.background),
  },
}));

export const ButtonHoverOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  display: 'flex',
  flexDirection: 'column',
  textTransform: 'uppercase',
  padding: '4px',
  opacity: '0',
}));
