export function getPaginationQueryOptions(options) {
  return {
    page: options.page,
    sort: options.sort,
    direction: options.direction,
    limit: options.limit,
  };
}

function getNewPageOnLimitIncrease(page, limit, newLimit) {
  const newPageDecimal = (page * limit) / newLimit;
  const newPage = Math.ceil(newPageDecimal);

  return newPage;
}

function getNewPageOnLimitDecrease(page, limit, newLimit) {
  const ratio = Math.ceil(newLimit / limit);

  if (ratio === 1) {
    return 1;
  }

  return ratio * page;
}

export function getNewPageBasedOnLimit({ page, limit, newLimit }) {
  if (newLimit > limit) {
    const newPage = getNewPageOnLimitIncrease(page, limit, newLimit);
    return newPage;
  }

  const newPage = getNewPageOnLimitDecrease(page, limit, newLimit);
  return newPage;
}
