import { Delete as DeleteIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ProjectActions } from '~/store/ducks/project';

import { projectSelector } from '~/helpers/project';

import LoadingButton from '~/components/Buttons/LoadingButton';
import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';

import { FormFooter, ActionsFormBox, LeftActions, RightActions } from './styles';

function ActionsForm({ hiddenSave, hiddenDelete, cancelMessageId }) {
  const actionsRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const { project, loadingLogoUpload, loadingLogoDelete, loadingSave } =
    useSelector(projectSelector);

  const handleDeleteProject = useCallback(() => {
    dispatch(ProjectActions.deleteProjectInfoRequest(project.id, () => navigate('/')));
  }, [dispatch, project, navigate]);

  const handleCancelProject = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleDeleteProjectKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      event.target.click();
    }
  }, []);

  useEffect(() => {
    if (!actionsRef.current) {
      return;
    }

    const parent = actionsRef.current.parentNode;
    actionsRef.current.style.maxWidth = `${parent.clientWidth}px`;
  }, []);

  return (
    <ActionsFormBox ref={actionsRef}>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeleteProject}
        messages={{
          title: 'projectsettings.modal.delete.title',
          content: 'projectsettings.modal.delete.content',
        }}
      />
      <FormFooter>
        <LeftActions>
          <Button onClick={handleCancelProject} variant="outlined" sx={{ mr: 1.5 }}>
            <FormattedMessage id={cancelMessageId || 'project.form.cancel'} />
          </Button>

          {!hiddenDelete && (
            <Button
              startIcon={<DeleteIcon size="small" />}
              variant="outlined"
              color="inherit"
              onClick={handleOpenDeleteModal}
              onKeyDown={handleDeleteProjectKeyDown}
            >
              <FormattedMessage id="project.form.delete" />
            </Button>
          )}
        </LeftActions>

        <RightActions>
          {!hiddenSave && (
            <LoadingButton
              fullWidth
              loading={loadingSave}
              disabled={loadingLogoUpload || loadingLogoDelete}
              type="submit"
              variant="contained"
              color="primary"
            >
              <FormattedMessage id="projectsettings.savesettings" />
            </LoadingButton>
          )}
        </RightActions>
      </FormFooter>
    </ActionsFormBox>
  );
}

ActionsForm.propTypes = {
  hiddenSave: PropTypes.bool,
  hiddenDelete: PropTypes.bool,
  cancelMessageId: PropTypes.string,
};

ActionsForm.defaultProps = {
  hiddenSave: false,
  hiddenDelete: false,
  cancelMessageId: null,
};

export default ActionsForm;
