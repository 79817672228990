import { Add as AddIcon } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Fab,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import theme from '~/theme';

import { appElementTypes } from '~/helpers/project/state';

const blocks = [
  { labelId: 'projectbuilder.text_zone', type: appElementTypes.textZone },
  { labelId: 'projectbuilder.button', type: appElementTypes.button },
  { labelId: 'projectbuilder.image', type: appElementTypes.image },
  { labelId: 'projectbuilder.form', type: appElementTypes.form },
  { labelId: 'projectbuilder.yesnobutton', type: appElementTypes.yesNoButton },
  { labelId: 'projectbuilder.quiz', type: appElementTypes.quiz },
  { labelId: 'projectbuilder.list', type: appElementTypes.list },
];

function AppPopoverElements({ onAdd }) {
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  const handleOpen = useCallback((event) => {
    setAutocompleteValue(null);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAdd = useCallback(() => {
    onAdd(autocompleteValue);
    setAnchorEl(null);
  }, [onAdd, autocompleteValue]);

  const handleAutocompleteChange = useCallback((event, newValue) => {
    setAutocompleteValue(newValue);
  }, []);

  const popoverProps = useMemo(
    () => ({
      open: Boolean(anchorEl),
      id: anchorEl ? 'popover-block' : undefined,
    }),
    [anchorEl]
  );

  const autocompleteOptions = useMemo(
    () =>
      blocks.map((block) => ({
        type: block.type,
        label: intl.formatMessage({ id: block.labelId }),
      })),
    [intl]
  );

  return (
    <>
      <Fab color="primary" aria-describedby={popoverProps.id} onClick={handleOpen}>
        <AddIcon />
      </Fab>

      <Popover
        id={popoverProps.id}
        open={popoverProps.open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <Box p="20px 24px" sx={{ background: theme.palette.grey[800] }}>
          <Typography variant="h6" mb={2}>
            <FormattedMessage id="projectbuilder.add_popover.title" />
          </Typography>

          <Autocomplete
            sx={{ width: '247px' }}
            options={autocompleteOptions}
            value={autocompleteValue}
            onChange={handleAutocompleteChange}
            isOptionEqualToValue={(option, value) => option.type === value.type}
            renderInput={(params) => <TextField {...params} label="Select block" />}
          />

          <Stack direction="row" justifyContent="flex-end" mt={2.5}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAdd}>Add</Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}

AppPopoverElements.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

export default AppPopoverElements;
