/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { ElementsViewerBox } from './styles';

function TextViewer({ textContent }) {
  return (
    <ElementsViewerBox>
      <div
        className="ProseMirror"
        style={{ color: 'black' }}
        dangerouslySetInnerHTML={{ __html: textContent }}
      />
    </ElementsViewerBox>
  );
}

TextViewer.propTypes = {
  textContent: PropTypes.string,
};

TextViewer.defaultProps = {
  textContent: null,
};

export default memo(TextViewer);
