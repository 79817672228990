import { ContentCopy } from '@mui/icons-material';
import { Alert, Grid, IconButton, InputAdornment } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { projectDomainValidationSchema } from '~/helpers/yup';

import RhfTextField from '~/components/RhfComponents/RhfTextField';

import ActionsForm from '../../../ActionsForm';
import { CustomDomainFormRoot } from './styles';

function CustomDomainForm({ onSubmit, initialValues }) {
  const intl = useIntl();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(projectDomainValidationSchema),
  });

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(initialValues.cname);
  }, []);

  return (
    <CustomDomainFormRoot onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} sx={{ py: 4 }}>
        <Grid item xs={12}>
          <Alert severity="info">
            {intl.formatMessage({
              id: 'project.form.domain_info',
            })}
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <RhfTextField
            error={errors?.domain}
            fullWidth
            variant="outlined"
            name="domain"
            defaultValue=""
            placeholder={intl.formatMessage({
              id: 'project.form.domain_placeholder',
            })}
            type="text"
            control={control}
            label={intl.formatMessage({
              id: 'project.form.domain',
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <RhfTextField
            error={errors?.cname}
            fullWidth
            disabled
            variant="outlined"
            name="cname"
            defaultValue=""
            placeholder={intl.formatMessage({
              id: 'project.form.cname_placeholder',
            })}
            type="text"
            control={control}
            label={intl.formatMessage({
              id: 'project.form.cname',
            })}
            InputProps={{
              endAdornment: initialValues.cname ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleCopy} edge="end">
                    <ContentCopy />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
      </Grid>
      <ActionsForm />
    </CustomDomainFormRoot>
  );
}

CustomDomainForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape({
    domain: PropTypes.string,
    cname: PropTypes.string,
  }),
};

CustomDomainForm.defaultProps = {
  onSubmit: () => {},
  initialValues: {
    domain: '',
  },
};

export default CustomDomainForm;
