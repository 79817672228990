import { takeLatest, put, call, select } from 'redux-saga/effects';

import api from '~/helpers/api';
import { replaceEmptyStringForNull } from '~/helpers/object';
import { getPaginationQueryOptions } from '~/helpers/pagination';
import { formatProjectFromRequest, projectSelector } from '~/helpers/project';
import { getErrorIdFromResponse } from '~/helpers/response';

import { FlashMessageActions } from '../ducks/flashMessage';
import { ProjectTypes, ProjectActions } from '../ducks/project';

function* getProjectInfo({ id }) {
  try {
    const { data } = yield call(api.get, `/projects/${id}`);
    const formattedProject = formatProjectFromRequest(data);

    yield put(ProjectActions.getProjectInfoSuccess(formattedProject));
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.load_failed');

    yield put(ProjectActions.getProjectInfoFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* saveProjectInfo({ id, data, callback }) {
  try {
    const dataToRequest = replaceEmptyStringForNull(data);

    const response = yield call(api.put, `/projects/${id}`, dataToRequest);
    const formattedProject = formatProjectFromRequest(response.data);

    yield put(
      FlashMessageActions.showMessage({
        id: 'project.saved_success',
        variant: 'success',
      })
    );
    yield put(ProjectActions.saveProjectInfoSuccess(formattedProject));

    callback();
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.save_failed');

    yield put(ProjectActions.saveProjectInfoFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* deleteProject({ id, callback }) {
  try {
    yield call(api.delete, `/projects/${id}`);
    yield put(ProjectActions.deleteProjectInfoSuccess());

    yield put(
      FlashMessageActions.showMessage({
        id: 'project.project_deleted',
        variant: 'success',
      })
    );

    callback();
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.delete_failed');

    yield put(ProjectActions.deleteProjectInfoFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* uploadLogo({ id, logoFile }) {
  try {
    const formData = new FormData();
    formData.append('logo', logoFile);

    const response = yield call(api.put, `/projects/${id}/logo`, formData);
    yield put(ProjectActions.uploadLogoSuccess(response.data.logo));
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'image_upload_error');

    yield put(ProjectActions.uploadLogoFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* deleteLogo({ id }) {
  try {
    yield call(api.delete, `/projects/${id}/logo`);
    yield put(ProjectActions.deleteLogoSuccess());
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'image_delete_error');

    yield put(ProjectActions.deleteLogoFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

// Leads

function* getLeads() {
  const { project, leads } = yield select(projectSelector);

  try {
    const projectId = project.id;
    const params = getPaginationQueryOptions(leads.options);

    const response = yield call(api.get, '/leads', {
      params: {
        ...params,
        projectId,
      },
    });

    const { data, total } = response.data;

    yield put(
      ProjectActions.getLeadsSuccess(data, {
        total,
      })
    );
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.lead.load_failed');

    yield put(ProjectActions.getLeadsFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* deleteLead({ id }) {
  try {
    yield call(api.delete, `/leads/${id}`);
    yield put(ProjectActions.deleteLeadSuccess());
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.lead.delete_failed');
    yield put(ProjectActions.deleteLeadsFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* changeLeadsOptions() {
  yield put(ProjectActions.getLeadsRequest());
}

export default function* sagas() {
  yield takeLatest(ProjectTypes.GET_PROJECT_INFO_REQUEST, getProjectInfo);
  yield takeLatest(ProjectTypes.SAVE_PROJECT_INFO_REQUEST, saveProjectInfo);
  yield takeLatest(ProjectTypes.DELETE_PROJECT_INFO_REQUEST, deleteProject);
  yield takeLatest(ProjectTypes.UPLOAD_LOGO_REQUEST, uploadLogo);
  yield takeLatest(ProjectTypes.DELETE_LOGO_REQUEST, deleteLogo);

  // Leads

  yield takeLatest(ProjectTypes.DELETE_LEAD_REQUEST, deleteLead);
  yield takeLatest(ProjectTypes.GET_LEADS_REQUEST, getLeads);
  yield takeLatest(ProjectTypes.CHANGE_LEADS_OPTIONS, changeLeadsOptions);
}
