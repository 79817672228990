import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';
import { renderElementBasedOnType } from '~/helpers/project/render';
import { appElementIndexes } from '~/helpers/project/state';

import AppPopoverElements from './AppPopoverElements';
import { AppContainer, AppHeader, AppElementsStack } from './styles';

function AppBuilder() {
  const dispatch = useDispatch();

  const { project } = useSelector(projectBuilderSelector);

  const handleAddElement = useCallback(
    (block) => {
      dispatch(ProjectBuilderActions.addBodyElement(project.id, block));
    },
    [project.id]
  );

  return (
    <AppContainer>
      <AppHeader>
        <AppElementsStack sx={{ mt: 'auto' }}>
          {renderElementBasedOnType(
            project.content.header,
            appElementIndexes.headerIndex
          )}
        </AppElementsStack>
      </AppHeader>

      <AppElementsStack spacing={1} sx={{ mt: '12px' }}>
        {project.content.body.elements.map(renderElementBasedOnType)}
      </AppElementsStack>

      <AppElementsStack sx={{ mt: '48px', mb: '40px' }}>
        <AppPopoverElements onAdd={handleAddElement} />
      </AppElementsStack>

      <AppElementsStack sx={{ mt: 'auto' }}>
        {renderElementBasedOnType(project.content.footer, appElementIndexes.footerIndex)}
      </AppElementsStack>
    </AppContainer>
  );
}

export default AppBuilder;
