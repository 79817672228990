import { styled } from '@mui/material';

import { Container } from '../../styles';

export const HeaderContainer = styled(Container)(({ theme, active }) => ({
  outlineWidth: active ? '1px' : '0px',
  outlineColor: active ? theme.palette.primary.main : theme.palette.grey['400'],
}));

export const Logo = styled('img')(({ theme }) => ({
  width: '53px',
  height: '53px',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '13px',

  objectFit: 'cover',
}));
