import { TextField } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

function RhfTextField({
  name,
  label,
  control,
  defaultValue,
  error,
  rows,
  variant,
  ...rest
}) {
  const intl = useIntl();
  return (
    <Controller
      render={({ field: { onChange, value, ref, ...props } }) => (
        <TextField
          label={label}
          value={value || ''}
          error={!!error}
          multiline={!!rows}
          rows={rows}
          helperText={error && intl.formatMessage(error.message, error.message.values)}
          onChange={onChange}
          inputRef={ref}
          variant={variant}
          {...props}
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}

RhfTextField.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.shape({
      id: PropTypes.string,
      values: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    }),
  }),
  rows: PropTypes.number,
};

RhfTextField.defaultProps = {
  defaultValue: null,
  error: null,
  label: null,
  variant: 'outlined',
  rows: 0,
};

export default RhfTextField;
