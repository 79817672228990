export function formatLastSaveProjectDate(updatedAt) {
  try {
    const date = new Date(updatedAt);
    const dateFormatted = date.toLocaleString(
      {},
      {
        day: '2-digit',
        month: '2-digit',
        hourCycle: 'h24',
        hour: '2-digit',
        minute: '2-digit',
      }
    );

    return dateFormatted.replace(',', ' -');
  } catch (error) {
    return null;
  }
}

export function formatCreatedAtProjectDate(createdAt) {
  try {
    const date = new Date(createdAt);
    const dateFormatted = date.toLocaleString(
      {},
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }
    );

    return dateFormatted;
  } catch (error) {
    return null;
  }
}
