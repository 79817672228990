import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectActions } from '~/store/ducks/project';

import { formatCreatedAtProjectDate } from '~/helpers/date';
import { formatLeadChoice } from '~/helpers/leads';
import { projectSelector } from '~/helpers/project';

import CircularLoading from '~/components/CircularLoading';
import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';
import TablePagination from '~/components/TableComponents/TablePagination';

import ActionsForm from '../ActionsForm';
import { ProjectNameTypography } from '../styles';
import { LeadsContainerStack, LeadTableStyled, TableContainerStyled } from './styles';

function LeadsTable() {
  const dispatch = useDispatch();
  const { project, leads } = useSelector(projectSelector);

  const [currentLeadId, setCurrentLeadId] = useState();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleOpenDeleteModal = (leadId) => {
    setCurrentLeadId(leadId);
    setDeleteModalOpen(true);
  };

  const handleDeleteLeads = useCallback(() => {
    dispatch(ProjectActions.deleteLeadRequest(currentLeadId));
  }, [currentLeadId]);

  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleChangePage = useCallback(
    (newPage) => {
      dispatch(ProjectActions.changeLeadsOptions({ page: newPage }));
    },
    [dispatch]
  );

  const handleChaneLimit = useCallback(
    (options) => {
      const { page, limit } = options;

      dispatch(
        ProjectActions.changeLeadsOptions({
          page,
          limit,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(ProjectActions.getLeadsRequest());
  }, [dispatch]);

  if (leads.loading) {
    return (
      <LeadsContainerStack>
        <CircularLoading />
        <ActionsForm hiddenSave hiddenDelete cancelMessageId="goBack" />
      </LeadsContainerStack>
    );
  }

  return (
    <>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeleteLeads}
      />

      <LeadsContainerStack>
        <ProjectNameTypography variant="h4" component="h1">
          {project.name}
        </ProjectNameTypography>

        <TableContainerStyled>
          <LeadTableStyled>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="projectsettings.name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="projectsettings.email" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="projectsettings.phone" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="projectsettings.country" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="projectsettings.city" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="projectsettings.zip_code" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="projectsettings.date" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="projectsettings.choice" />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {leads.data.map((lead) => (
                <TableRow key={lead.id}>
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>{lead.email}</TableCell>
                  <TableCell>{lead.phone}</TableCell>
                  <TableCell>{lead.country}</TableCell>
                  <TableCell>{lead.city}</TableCell>
                  <TableCell>{lead.zipcode}</TableCell>

                  <TableCell>{formatCreatedAtProjectDate(lead.createdAt)}</TableCell>

                  <TableCell>
                    <Typography variant="body2" {...formatLeadChoice(lead.choice).style}>
                      <FormattedMessage id={formatLeadChoice(lead.choice).messageId} />
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <IconButton onClick={() => handleOpenDeleteModal(lead.id)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </LeadTableStyled>

          <TablePagination
            options={leads.options}
            onPageChange={handleChangePage}
            onLimitChange={handleChaneLimit}
            sx={{ marginTop: 'auto' }}
          />
        </TableContainerStyled>

        <ActionsForm hiddenSave hiddenDelete cancelMessageId="goBack" />
      </LeadsContainerStack>
    </>
  );
}

export default LeadsTable;
