import { Link as LinkIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { projectStatus } from '~/helpers/project/state';

import { LinkIconDiv } from './styles';

function ProjectLinkIcon({ status, publishedUrl }) {
  const intl = useIntl();

  const handleOpenLanding = useCallback(
    (event) => {
      event.stopPropagation();
      window.open(publishedUrl, '_blank');
    },
    [publishedUrl]
  );

  const tooltipTitle = useMemo(
    () =>
      status === projectStatus.draft
        ? intl.formatMessage({ id: 'project.not_published' })
        : intl.formatMessage({ id: 'project.open_website' }),
    [intl, status]
  );

  return (
    <Tooltip arrow placement="top" title={tooltipTitle}>
      <LinkIconDiv>
        <IconButton onClick={handleOpenLanding} disabled={status === projectStatus.draft}>
          <LinkIcon />
        </IconButton>
      </LinkIconDiv>
    </Tooltip>
  );
}

ProjectLinkIcon.propTypes = {
  status: PropTypes.string.isRequired,
  publishedUrl: PropTypes.string.isRequired,
};

export default ProjectLinkIcon;
