const styles = ({ palette }) => ({
  styleOverrides: {
    popper: {
      'ul::-webkit-scrollbar': {
        width: '12px',
      },
      'ul::-webkit-scrollbar-track': {
        marginTop: '4px',
        background: palette.grey[900],
      },
      'ul::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: palette.secondary.dark,
      },
      'ul::-webkit-scrollbar-thumb:hover': {
        background: palette.grey[500],
      },
    },
  },
});

export default styles;
