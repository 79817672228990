import { all } from 'redux-saga/effects';

import projectSagas from './project';
import projectBuilderSagas from './projectBuilder';
import projectsSagas from './projects';
import sessionSagas from './session';

export default function* rootSaga() {
  yield all([sessionSagas(), projectBuilderSagas(), projectsSagas(), projectSagas()]);
}
