import {
  Card,
  CardActions,
  Typography,
  styled,
  CardActionArea,
  CardContent,
} from '@mui/material';

export const CardStyled = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const CardActionAreaStyled = styled(CardActionArea)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const CardContentStyled = styled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 1,
}));

export const CardActionsStyled = styled(CardActions)(() => ({
  marginTop: 'auto',
}));

export const CreatedAtTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: '14px',
}));

export const ClientTypography = styled(Typography)(() => ({
  marginBottom: '28px',
  fontSize: '12px',
}));

export const DescriptionTypography = styled(Typography)(() => ({
  marginTop: 'auto',
  fontSize: '12px',

  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const CardActionsLeft = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
}));

export const CardActionsRight = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'end',
}));

export const VisibilityCount = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
}));
