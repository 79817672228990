import { Box, Stack, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import ImageDropzone from '~/components/ImageDropzone';

function HeaderSettings({ element, index }) {
  const dispatch = useDispatch();

  const handleImageChange = useCallback(
    (props) => {
      if (!props) {
        dispatch(ProjectBuilderActions.deleteHeaderElementLogo(index));
        return;
      }

      const data = { tempImage: props };
      dispatch(ProjectBuilderActions.updateHeaderElementLogoRequest(index, data));
    },
    [dispatch, index]
  );

  const imageSrc = useMemo(
    () => element.logo.tempImage?.src || element.logo.image?.url,
    [element]
  );
  return (
    <Stack direction="column" spacing={4.5}>
      <Box>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.image.dropzone" />
        </Typography>

        <ImageDropzone
          sx={{ mt: 1, height: '133px' }}
          defaultImageSrc={imageSrc}
          onImageChange={handleImageChange}
          defaultTextId="projectbuilder.image.dropzone.text"
        />
      </Box>
    </Stack>
  );
}

const appHeaderLogoPropTypes = PropTypes.shape({
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  tempImage: PropTypes.shape({
    src: PropTypes.string,
  }),
});

const appHeaderPropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  logo: appHeaderLogoPropTypes,
});

HeaderSettings.propTypes = {
  element: appHeaderPropTypes.isRequired,
  index: PropTypes.number.isRequired,
};

export default HeaderSettings;
