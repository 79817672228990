import { Tabs, styled } from '@mui/material';

export const PaperTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  width: '100%',
  position: 'absolute',
  top: '64px',
  left: '0',
  zIndex: '2',
}));
