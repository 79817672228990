import { Grid, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import CookieBanner from '~/assets/images/cookie-banner.svg';

import { projectSelector } from '~/helpers/project';
import { metaInformationValidationSchema } from '~/helpers/yup';

import RhfSwitch from '~/components/RhfComponents/RhfSwitch';
import RhfTextField from '~/components/RhfComponents/RhfTextField';

import ActionsForm from '../ActionsForm';
import { ProjectNameTypography, ProjectSettingsFormRoot } from '../styles';
import { BgLightGray, CookieBannerImage } from './styles';

function ProjectMetaInformationForm({ onSubmit }) {
  const intl = useIntl();
  const { project } = useSelector(projectSelector);

  const methods = useForm({
    resolver: yupResolver(metaInformationValidationSchema),
  });

  const { handleSubmit, formState, control, reset } = methods;

  useEffect(() => {
    if (!project) {
      return;
    }

    const dataToReset = {
      metadata: project.metadata,
    };

    reset(dataToReset);
  }, [reset, project]);

  return (
    <FormProvider {...methods}>
      <ProjectSettingsFormRoot onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ py: 4 }}>
          <Grid item xs={12}>
            <ProjectNameTypography variant="h4" component="h1">
              {project.name}
            </ProjectNameTypography>
          </Grid>

          <Grid item xs={12} sx={{ mb: 2 }}>
            <RhfTextField
              error={formState.errors?.metadata?.tagManagerId}
              fullWidth
              variant="outlined"
              name="metadata.tagManagerId"
              defaultValue=""
              placeholder={intl.formatMessage({
                id: 'projectmetainformation.tag_manager_id_placeholder',
              })}
              type="text"
              control={control}
              label={intl.formatMessage({
                id: 'projectmetainformation.tag_manager_id',
              })}
            />
          </Grid>

          <BgLightGray>
            <Grid item xs={12}>
              <CookieBannerImage alt="cookie banner" src={CookieBanner} />
            </Grid>

            <Grid item xs={12} align="left" sx={{ p: 2 }}>
              <Typography variant="h6" component="h2">
                <FormattedMessage id="projectmetainformation.cookie_banner" />
              </Typography>
              <Typography color="secondary">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <RhfSwitch
                name="metadata.cookieBanner"
                control={control}
                defaultValue={false}
              />
            </Grid>
          </BgLightGray>
        </Grid>
        <ActionsForm />
      </ProjectSettingsFormRoot>
    </FormProvider>
  );
}

ProjectMetaInformationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProjectMetaInformationForm;
