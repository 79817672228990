import { BrowserRouter, Routes, Route } from 'react-router-dom';

import NewProjectPage from '~/pages/NewProjectPage';
import ProjectBuilderPage from '~/pages/ProjectBuilderPage';
import ProjectPreview from '~/pages/ProjectPreview';
import ProjectSettings from '~/pages/ProjectSettings';
import ProjectsPage from '~/pages/ProjectsPage';
import RecoverPage from '~/pages/RecoverPage';
import RegisterPage from '~/pages/RegisterPage';
import SignInPage from '~/pages/SignInPage';

import PrivateRoute from './components/PrivateRoute';

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/recovery" element={<RecoverPage />} />

        <Route
          path="/projects/:id/settings"
          element={
            <PrivateRoute>
              <ProjectSettings />
            </PrivateRoute>
          }
        />

        <Route
          path="/projects/:id/preview"
          element={
            <PrivateRoute>
              <ProjectPreview />
            </PrivateRoute>
          }
        />

        <Route
          path="/project-builder/:id"
          element={
            <PrivateRoute>
              <ProjectBuilderPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/new-project"
          element={
            <PrivateRoute>
              <NewProjectPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <ProjectsPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
