/* eslint-disable react/no-array-index-key */
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { ContainerStack, DividerStyled, FabStyled, ItemHeader } from './styles';

function FooterSettings({ element, index }) {
  const dispatch = useDispatch();

  const handleAddLink = useCallback(() => {
    dispatch(ProjectBuilderActions.addFooterElementRequest());
  }, [dispatch]);

  const handleRemoveLink = useCallback(
    (linkIndex) => {
      dispatch(ProjectBuilderActions.removeFooterElement(linkIndex));
    },
    [dispatch]
  );

  const handleNameChange = useCallback(
    (event) => {
      const text = event.target.value;
      const linkIndex = Number(event.target.getAttribute('data-index'));

      dispatch(ProjectBuilderActions.updateFooterElement(linkIndex, { text }));
    },
    [dispatch]
  );

  const handleAddressChange = useCallback(
    (event) => {
      const href = event.target.value;
      const linkIndex = Number(event.target.getAttribute('data-index'));

      dispatch(ProjectBuilderActions.updateFooterElement(linkIndex, { href }));
    },
    [index]
  );

  return (
    <ContainerStack flexDirection="column" spacing={3}>
      {element.elements.map((footerLink, linkIndex) => (
        <Stack key={linkIndex} flexDirection="column" spacing={3}>
          <Stack spacing={1}>
            <ItemHeader>
              <Typography variant="caption">
                <FormattedMessage id="projectbuilder.footer.name.text" />
              </Typography>

              <Tooltip
                title={<FormattedMessage id="projectbuilder.footer.item.delete" />}
              >
                <IconButton onClick={() => handleRemoveLink(linkIndex)} sx={{ p: '0px' }}>
                  <DeleteIcon fontSize="small" sx={{ color: grey[400] }} />
                </IconButton>
              </Tooltip>
            </ItemHeader>

            <TextField
              type="text"
              fullWidth
              variant="outlined"
              value={footerLink.text}
              onChange={handleNameChange}
              inputProps={{ 'data-index': linkIndex }}
            />
          </Stack>

          <Stack spacing={1}>
            <Typography variant="caption">
              <FormattedMessage id="projectbuilder.footer.address.text" />
            </Typography>

            <TextField
              type="text"
              fullWidth
              variant="outlined"
              value={footerLink.href}
              onChange={handleAddressChange}
              inputProps={{ 'data-index': linkIndex }}
            />
          </Stack>

          <DividerStyled />
        </Stack>
      ))}

      <FabStyled color="primary" onClick={handleAddLink}>
        <AddIcon />
      </FabStyled>
    </ContainerStack>
  );
}

const appFooterLinkPropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  href: PropTypes.string,
});

const appFooterPropTypes = PropTypes.shape({
  elements: PropTypes.arrayOf(appFooterLinkPropTypes),
});

FooterSettings.propTypes = {
  element: appFooterPropTypes.isRequired,
  index: PropTypes.number.isRequired,
};

export default FooterSettings;
