import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import theme from '~/theme';

import { ProjectsActions } from '~/store/ducks/projects';

import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';

const origin = {
  vertical: 'top',
  horizontal: 'right',
};

function DotsMenu({ id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorElement, setAnchorElement] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleOpenMenu = useCallback((event) => {
    setAnchorElement(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorElement(null);
  }, []);

  const handleDeleteProject = useCallback(() => {
    dispatch(ProjectsActions.deleteProjectRequest(id));
    handleCloseMenu();
  }, [dispatch, id, handleCloseMenu]);

  const handleProjectSettings = useCallback(() => {
    navigate(`/projects/${id}/settings`);
  }, [navigate, id]);

  return (
    <>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeleteProject}
        messages={{
          title: 'projectsettings.modal.delete.title',
          content: 'projectsettings.modal.delete.content',
        }}
      />

      <IconButton onClick={handleOpenMenu}>
        <MoreVert sx={{ color: theme.palette.grey[500] }} />
      </IconButton>

      <Menu
        keepMounted
        sx={{ mt: '45px' }}
        id="menu-options"
        anchorEl={anchorElement}
        anchorOrigin={origin}
        transformOrigin={origin}
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleProjectSettings}>
          <Typography textAlign="center">
            <FormattedMessage id="projects.card.menu.edit" />
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteModal}>
          <Typography textAlign="center">
            <FormattedMessage id="projects.card.menu.delete" />
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

DotsMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DotsMenu;
