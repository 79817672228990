const styles = ({ palette }) => ({
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.variant === 'darkgrey' && {
        backgroundColor: palette.background.default,
        color: '#fff',
      }),
    }),
  },
});

export default styles;
