import { Container, styled } from '@mui/material';

const CenterWrapper = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

export default CenterWrapper;
