import axios from 'axios';

import { getTokenData } from './session';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const requestConfig = { ...config };
  const token = getTokenData();
  const authorization = token ? `Bearer ${token}` : '';

  requestConfig.headers = {
    ...config.headers,
    authorization,
  };

  return requestConfig;
});

export default api;
