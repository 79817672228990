import { Box, IconButton, Tooltip } from '@mui/material';

import styled from '@emotion/styled';

export const Container = styled(Box)((props) => ({
  position: 'relative',

  width: '100%',
  padding: '24px',

  borderRadius: '8px',
  cursor: 'pointer',

  backgroundColor: 'transparent',
  color: props.theme.palette.common.black,

  outlineStyle: 'solid',
  outlineColor: props.active && props.theme.palette.primary.main,
  outlineWidth: props.active ? '1px' : '0',

  '&:hover': {
    outlineWidth: '1px',
    outlineColor: props.theme.palette.primary.main,
  },

  '&:hover > div': {
    opacity: 1,
  },
}));

export const ListImage = styled('img')(() => ({
  alignSelf: 'flex-start',

  maxWidth: '60px',
  width: '100%',
  height: '60px',

  backgroundColor: 'transparent',
  objectFit: 'cover',
}));

export const TooltipOverlay = styled(Tooltip)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export const IconButtonDelete = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ListHoverOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',

  display: 'flex',
  flexDirection: 'column',
  textTransform: 'uppercase',

  padding: '4px',
  opacity: 0,
}));
