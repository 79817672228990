import { Link } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RLink } from 'react-router-dom';

import { CancelTypographyStyled } from './styles';

function CancelLink({ href, ...rest }) {
  return (
    <CancelTypographyStyled variant="body1" {...rest}>
      <Link component={RLink} to={href}>
        <FormattedMessage id="project.form.cancel" />
      </Link>
    </CancelTypographyStyled>
  );
}

CancelLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default CancelLink;
