import {
  Delete as DeleteIcon,
  ThumbDown as ThumbDownIcon,
  EmojiEmotions as EmojiEmotionsIcon,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { isHtmlTextEmpty } from '~/helpers/html';
import { projectBuilderSelector } from '~/helpers/project';

import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';
import { TextEditorViewer } from '~/components/TextEditor';

import { ImageHoverOverlay } from '../../Image/Block/styles';
import { Container, IconButtonDelete, TooltipOverlay } from '../../styles';
import { BoxStyled, NoButton, YesButton, YesNoBox } from './styles';

function YesNoButtonBlock({ element, index }) {
  const dispatch = useDispatch();
  const { selectedAppElementIndex, project } = useSelector(projectBuilderSelector);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleDeleteElement = useCallback(() => {
    dispatch(ProjectBuilderActions.removeBodyElement(project.id, index));
  }, [index, project.id]);

  const handleClick = useCallback(() => {
    dispatch(ProjectBuilderActions.setSelectedAppElementIndex(index));
  });

  const active = useMemo(
    () => index === selectedAppElementIndex,
    [index, selectedAppElementIndex]
  );

  const hasContent = useMemo(() => !isHtmlTextEmpty(element.content), [element.content]);

  return (
    <>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeleteElement}
      />

      <Container active={active} onClick={handleClick}>
        <BoxStyled active={active} hasContent={hasContent} onClick={handleClick}>
          {hasContent ? (
            <TextEditorViewer textContent={element.content} />
          ) : (
            <Typography variant="body2" fontWeight={300} color="grey.500">
              <FormattedMessage id="projectbuilder.text.placeholder" />
            </Typography>
          )}
        </BoxStyled>

        <YesNoBox>
          <YesButton>
            <EmojiEmotionsIcon />
            <FormattedMessage id="projectbuilder.yes_no_button.yes" />
          </YesButton>

          <NoButton>
            <ThumbDownIcon />
            <FormattedMessage id="projectbuilder.yes_no_button.no" />
          </NoButton>
        </YesNoBox>

        <ImageHoverOverlay>
          <Box display="flex" justifyContent="flex-end">
            <TooltipOverlay
              title={<FormattedMessage id="projectbuilder.element.delete" />}
            >
              <IconButtonDelete onClick={handleOpenDeleteModal}>
                <DeleteIcon fontSize="small" />
              </IconButtonDelete>
            </TooltipOverlay>
          </Box>
        </ImageHoverOverlay>
      </Container>
    </>
  );
}

const yesNoButtonPropTypes = PropTypes.shape({
  question: PropTypes.string,
  content: PropTypes.string,
});

YesNoButtonBlock.propTypes = {
  index: PropTypes.number.isRequired,
  element: yesNoButtonPropTypes.isRequired,
};

export default YesNoButtonBlock;
