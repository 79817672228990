import { Box, styled } from '@mui/material';

export const YesNoBox = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',

  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '146px',
    height: '92px',

    columnGap: '12px',

    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'center',

    color: theme.palette.primary.contrastText,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '9px',
    border: 'none',
  },
}));

export const BoxStyled = styled(Box)(({ active, hasContent, theme }) => ({
  alignSelf: 'center',
  minHeight: '48px',
  outlineWidth: active ? '1px' : !hasContent && '1px',
  outlineColor: active ? theme.palette.primary.main : theme.palette.grey['400'],
}));

export const YesButton = styled('button')(() => ({
  background: '#4CD964',
}));

export const NoButton = styled('button')(() => ({
  background: '#FF3B30',
}));
