import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import StartContainer from '~/containers/StartContainer';

import StartFormWrapper from '~/components/StartFormWrapper';

import RegisterForm from './components/RecoverForm';

function RecoverPage() {
  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    navigate('/signin');
  }, [navigate]);

  return (
    <StartContainer>
      <StartFormWrapper>
        <RegisterForm onSubmit={handleSubmit} />
      </StartFormWrapper>
    </StartContainer>
  );
}

export default RecoverPage;
