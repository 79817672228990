import { Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { BoxStyled } from './styles';

function DeleteTooltip({ handleOpenDeleteModal, selected, disabled }) {
  return (
    <BoxStyled selected={selected} display="flex" justifyContent="flex-end">
      <Tooltip title={<FormattedMessage id="projectbuilder.element.delete" />}>
        <Box component="span">
          <IconButton disableRipple onClick={handleOpenDeleteModal} disabled={disabled}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </BoxStyled>
  );
}

DeleteTooltip.propTypes = {
  handleOpenDeleteModal: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DeleteTooltip;
