export const setLoginUser = (user) =>
  localStorage.setItem('ad-loca-funnel.user', JSON.stringify(user));

export const getTokenData = () =>
  JSON.parse(localStorage.getItem('ad-loca-funnel.token') || null);

export const getUser = () =>
  JSON.parse(localStorage.getItem('ad-loca-funnel.user') || '{}');

export const getTheme = () => {
  let defaultTheme = 'light';

  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    defaultTheme = 'dark';
  }

  return localStorage.getItem('ad-loca-funnel.theme') || defaultTheme;
};

export const logout = () => {
  localStorage.removeItem('ad-loca-funnel.token');
  localStorage.removeItem('ad-loca-funnel.user');
};

export const login = (tokenData) => {
  localStorage.setItem('ad-loca-funnel.token', JSON.stringify(tokenData));
};
