import { Tooltip } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';

import LoadingButton from '../LoadingButton';
import { WarningButtonIcon, WarningButtonBox } from './styles';

function WarningButton({ boxProps, warning, children, ...rest }) {
  return (
    <WarningButtonBox {...boxProps}>
      <LoadingButton {...rest}>{children}</LoadingButton>

      {warning && (
        <Tooltip title={warning} arrow placement="top">
          <WarningButtonIcon />
        </Tooltip>
      )}
    </WarningButtonBox>
  );
}

WarningButton.propTypes = {
  warning: PropTypes.string,
  boxProps: PropTypes.shape({}),
  children: PropTypes.node,
};

WarningButton.defaultProps = {
  boxProps: null,
  warning: null,
  children: null,
};

export default WarningButton;
