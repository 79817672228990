import { appElementIndexes } from '../state';

export const projectBuilderSelector = ({ projectBuilder }) => ({
  project: projectBuilder.data.pages.find(
    (page) => page.id === projectBuilder.data.activePage
  ),
  // need to adjust name after receiving proper data from backend
  fullProject: projectBuilder.data,
  pages: projectBuilder.data.pages,
  loading: projectBuilder.loading,
  changed: projectBuilder.changed,
  loadingUploadImage: projectBuilder.loadingUploadImage,
  loadingSaveProject: projectBuilder.loadingSaveProject,
  loadingPublishProject: projectBuilder.loadingPublishProject,
  selectedAppElementIndex: projectBuilder.selectedAppElementIndex,

  isAppSelected:
    projectBuilder.selectedAppElementIndex !== appElementIndexes.footerIndex &&
    projectBuilder.selectedAppElementIndex !== appElementIndexes.headerIndex,

  isHeaderSelected:
    projectBuilder.selectedAppElementIndex === appElementIndexes.headerIndex,

  isFooterSelected:
    projectBuilder.selectedAppElementIndex === appElementIndexes.footerIndex,
});

export const projectSelector = ({ project }) => ({
  project: project.data,
  loading: project.loading,
  loadingSave: project.loadingSave,
  loadingLogoUpload: project.loadingLogoUpload,
  loadingLogoDelete: project.loadingLogoDelete,

  leads: project.leads,
});
