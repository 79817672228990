// material-ui
import { Box, Grid, styled } from '@mui/material';

import startBg from '~/assets/images/start-bg-1.jpg';

export const StartWrapper = styled('div')(() => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StartPicture = styled(Grid)(() => ({
  height: '100vh',
  background: `url(${startBg}) no-repeat bottom right`,
  backgroundSize: 'cover',
}));

export const LogoWrapper = styled(Box)(() => ({
  '> svg': {
    width: '100%',
    height: 'auto',
  },
}));
