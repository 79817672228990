import { appElementsInitialState, appElementTypes } from '../state';
import {
  formatHeaderFromRequest,
  formatHeaderToRequest,
  formatButtonFromRequest,
  formatButtonToRequest,
  formatFooterFromRequest,
  formatFooterToRequest,
  formatImageFromRequest,
  formatImageToRequest,
  formatTextZoneFromRequest,
  formatTextZoneToRequest,
  formatYesNoButtonFromRequest,
  formatYesNoButtonToRequest,
  formatFormFromRequest,
  formatFormToRequest,
  formatQuizFromRequest,
  formatQuizToRequest,
  formatListFromRequest,
  formatListToRequest,
} from './elementsFilter';

const appElementsFormatFunctionsFromRequest = {
  header: formatHeaderFromRequest,
  image: formatImageFromRequest,
  text_zone: formatTextZoneFromRequest,
  button: formatButtonFromRequest,
  form: formatFormFromRequest,
  yesno_button: formatYesNoButtonFromRequest,
  quiz: formatQuizFromRequest,
  list: formatListFromRequest,
  footer: formatFooterFromRequest,
};

const appElementsFormatFunctionsToRequest = {
  header: formatHeaderToRequest,
  image: formatImageToRequest,
  text_zone: formatTextZoneToRequest,
  button: formatButtonToRequest,
  form: formatFormToRequest,
  yesno_button: formatYesNoButtonToRequest,
  quiz: formatQuizToRequest,
  list: formatListToRequest,
  footer: formatFooterToRequest,
};

const defaultContent = {
  header: {
    type: appElementTypes.header,
    logo: appElementsInitialState.header_logo,
  },
  body: {
    elements: [],
  },
  footer: {
    type: appElementTypes.footer,
    elements: [
      {
        type: appElementTypes.footerLink,
        text: 'Social',
        href: 'https://hox.rs',
      },
    ],
  },
};

function formatAppElementFromRequest(element) {
  const formatFunction = appElementsFormatFunctionsFromRequest[element.type];
  return formatFunction(element);
}

function formatAppElementToRequest(element) {
  const formatFunction = appElementsFormatFunctionsToRequest[element.type];
  return formatFunction(element);
}

export function formatProjectFromRequest(project) {
  if (project.pages.length < 1) {
    const initialPageId = crypto.randomUUID();
    const defaultProject = {
      ...project,
      activePage: initialPageId,
      pages: [
        {
          slug: '/',
          title: 'Home',
          id: initialPageId,
          content: {
            header: {
              ...defaultContent.header,
            },
            body: {
              ...defaultContent.body,
            },
            footer: {
              ...defaultContent.footer,
            },
          },
        },
      ],
    };
    return defaultProject;
  }

  return {
    ...project,
    activePage: project.pages[0]?.id,
    pages: project.pages.map((page) => {
      const content = {
        header: {
          ...defaultContent.header,
          ...page.content?.header,
        },
        body: {
          ...defaultContent.body,
          ...page.content?.body,
        },
        footer: {
          ...defaultContent.footer,
          ...page.content?.footer,
        },
      };

      const { elements: bodyElements } = content.body;
      const formattedAppElements = bodyElements.map(formatAppElementFromRequest);
      const formattedHeader = formatAppElementFromRequest(content.header);
      const formattedFooter = formatAppElementFromRequest(content.footer);

      return {
        ...page,
        content: {
          ...content,
          header: formattedHeader,
          body: {
            ...content.body,
            elements: formattedAppElements,
          },
          footer: formattedFooter,
        },
      };
    }),
  };
}

export function formatProjectToRequest(pages) {
  return {
    pages: pages.map((page) => {
      const { elements: bodyElements } = page.content.body;
      const formattedAppElements = bodyElements.map(formatAppElementToRequest);
      const formattedHeader = formatAppElementToRequest(page.content.header);
      const formattedFooter = formatAppElementToRequest(page.content.footer);

      return {
        ...page,
        content: {
          ...page.content,
          header: formattedHeader,
          body: {
            ...page.content.body,
            elements: formattedAppElements,
          },
          footer: formattedFooter,
        },
      };
    }),
  };
}
