import { TablePagination as MuiTablePagination, Box, Divider } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { getNewPageBasedOnLimit } from '~/helpers/pagination';

function TablePagination({ options, showDivider, onPageChange, onLimitChange, ...rest }) {
  const { page, limit, total } = options;

  const handleChangePage = useCallback(
    (_, newPage) => {
      onPageChange(newPage + 1); // on mui start with 0 but on api start with 1
    },
    [onPageChange]
  );

  const handleChangeLimit = useCallback(
    (event) => {
      const newLimit = parseInt(event.target.value, 10);

      const newPage = getNewPageBasedOnLimit({
        page,
        limit,
        newLimit,
      });

      onLimitChange({
        page: newPage,
        limit: newLimit,
      });
    },
    [page, limit, onLimitChange]
  );

  return (
    <Box {...rest}>
      {showDivider && <Divider sx={{ mb: 1 }} />}

      <MuiTablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={total}
        rowsPerPage={limit}
        page={page - 1} // on mui start with 0 but on api start with 1
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
          },
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeLimit}
      />
    </Box>
  );
}

TablePagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  showDivider: PropTypes.bool,

  options: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

TablePagination.defaultProps = {
  showDivider: true,
};

export default TablePagination;
