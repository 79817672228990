import { Box, styled } from '@mui/material';

export const ElementsDefaultStyle = styled(Box)(({ theme }) => ({
  '.ProseMirror': {
    fontFamily: `"Poppins", sans-serif`,

    'p:empty::after': {
      content: `"\\00A0"`,
    },

    'ul, ol': {
      padding: '0 1rem',
    },

    h1: {
      fontSize: '2.25rem',
      fontWeight: 700,
      marginBottom: '0.35em',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      marginBottom: '0.35em',
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 700,
      marginBottom: '0.35em',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
      marginBottom: '0.35em',
    },
    p: {
      fontSize: '1.125rem',
      fontWeight: 400,
      margin: 0,
    },
    blockquote: {
      marginLeft: '32px',
      paddingLeft: '0.825rem',
      borderLeft: `2px solid ${theme.palette.grey[600]}`,
    },
    hr: {
      border: 'none',
      borderTop: `2px solid ${theme.palette.grey[600]}`,
      margin: '1rem 0',
    },
    footer: {
      marginTop: '64px',
    },
  },
}));
