import { styled } from '@mui/material';

export const CustomDomainFormRoot = styled('form')(({ theme }) => ({
  width: '100%',
  maxWidth: '933px',

  position: 'relative',

  paddingInline: '120px',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',

  background: theme.palette.grey[900],
}));
