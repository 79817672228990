import { Box, styled } from '@mui/material';

export const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  minWidth: theme.breakpoints.values.md,
  marginLeft: 'auto',
  marginRight: 'auto',

  paddingTop: '88px', // tabs height + 40px
  paddingBottom: '116.5px', // footer actions + 40px
}));
