import { Tooltip } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import BoldIcon from 'remixicon-react/BoldIcon';
import DoubleQuotesLIcon from 'remixicon-react/DoubleQuotesLIcon';
import FormatClearIcon from 'remixicon-react/FormatClearIcon';
import H1Icon from 'remixicon-react/H1Icon';
import H2Icon from 'remixicon-react/H2Icon';
import H3Icon from 'remixicon-react/H3Icon';
import H4Icon from 'remixicon-react/H4Icon';
import ItalicIcon from 'remixicon-react/ItalicIcon';
import ListOrderedIcon from 'remixicon-react/ListOrderedIcon';
import ListUnorderedIcon from 'remixicon-react/ListUnorderedIcon';
import MarkPenLineIcon from 'remixicon-react/MarkPenLineIcon';
import ParagraphIcon from 'remixicon-react/ParagraphIcon';
import SeparatorIcon from 'remixicon-react/SeparatorIcon';
import StrikethroughIcon from 'remixicon-react/StrikethroughIcon';
import TextWrapIcon from 'remixicon-react/TextWrapIcon';
import UnderlineIcon from 'remixicon-react/UnderlineIcon';

import { ButtonStyle, ButtonGroup } from './styles';

function MenuBar({ editor }) {
  const intl = useIntl();

  const getButtonSelected = useCallback(
    (elementType, params) => {
      const isActive = editor.isActive(elementType, params);
      return isActive ? 'button-actived' : '';
    },
    [editor]
  );

  return (
    <>
      <ButtonGroup>
        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.heading_1' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            className={getButtonSelected('heading', { level: 1 })}
          >
            <H1Icon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.heading_2' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            className={getButtonSelected('heading', { level: 2 })}
          >
            <H2Icon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.heading_3' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            className={getButtonSelected('heading', { level: 3 })}
          >
            <H3Icon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.heading_4' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
            className={getButtonSelected('heading', { level: 4 })}
          >
            <H4Icon size={16} />
          </ButtonStyle>
        </Tooltip>
      </ButtonGroup>

      <ButtonGroup>
        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.paragraph' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={getButtonSelected('paragraph')}
          >
            <ParagraphIcon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip arrow placement="top" title={intl.formatMessage({ id: 'editor.bold' })}>
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={getButtonSelected('bold')}
          >
            <BoldIcon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.italic' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={getButtonSelected('italic')}
          >
            <ItalicIcon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.underline' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={getButtonSelected('underline')}
          >
            <UnderlineIcon size={16} />
          </ButtonStyle>
        </Tooltip>
      </ButtonGroup>

      <ButtonGroup>
        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.list_bullets' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={`${getButtonSelected('bulletList')} border-rounded-left`}
          >
            <ListUnorderedIcon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.list_numbering' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={getButtonSelected('orderedList')}
          >
            <ListOrderedIcon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.highlight' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            className={getButtonSelected('highlight')}
          >
            <MarkPenLineIcon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.strike' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={getButtonSelected('strike')}
          >
            <StrikethroughIcon size={16} />
          </ButtonStyle>
        </Tooltip>
      </ButtonGroup>

      <ButtonGroup>
        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.blockquote' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            className={getButtonSelected('blockquote')}
          >
            <DoubleQuotesLIcon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.divider' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            className="outlined"
          >
            <SeparatorIcon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.break_line' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().setHardBreak().run()}
            className="outlined"
          >
            <TextWrapIcon size={16} />
          </ButtonStyle>
        </Tooltip>

        <Tooltip
          arrow
          placement="top"
          title={intl.formatMessage({ id: 'editor.clear_nodes' })}
        >
          <ButtonStyle
            onClick={() => editor.chain().focus().clearNodes().run()}
            className="outlined"
          >
            <FormatClearIcon size={16} />
          </ButtonStyle>
        </Tooltip>
      </ButtonGroup>
    </>
  );
}

const editorTextPropTypes = PropTypes.shape({
  chain: PropTypes.func,
  isActive: PropTypes.func,
});

MenuBar.propTypes = {
  editor: editorTextPropTypes.isRequired,
};

export default MenuBar;
