import { takeLatest, put, call } from 'redux-saga/effects';

import api from '~/helpers/api';
import { createFormDataWithoutEmptyValues } from '~/helpers/request';
import { getErrorIdFromResponse } from '~/helpers/response';

import { FlashMessageActions } from '../ducks/flashMessage';
import { ProjectsTypes, ProjectsActions } from '../ducks/projects';

function* getProjects() {
  try {
    const { data: responseData } = yield call(api.get, '/projects');
    const { data } = responseData;

    yield put(ProjectsActions.getProjectsSuccess(data));
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'projects.load_failed');

    yield put(ProjectsActions.getProjectsFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* addProject({ data, callback }) {
  try {
    const formData = createFormDataWithoutEmptyValues(data);

    yield call(api.post, '/projects', formData);
    yield put(ProjectsActions.addProjectSuccess());

    callback();
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.create_failed');

    yield put(ProjectsActions.addProjectFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* deleteProject({ id }) {
  try {
    yield call(api.delete, `/projects/${id}`);

    yield put(ProjectsActions.deleteProjectSuccess());
    yield put(ProjectsActions.getProjectsRequest());

    yield put(
      FlashMessageActions.showMessage({
        id: 'project.project_deleted',
        variant: 'success',
      })
    );
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.delete_failed');

    yield put(ProjectsActions.deleteProjectFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

export default function* sagas() {
  yield takeLatest(ProjectsTypes.GET_PROJECTS_REQUEST, getProjects);
  yield takeLatest(ProjectsTypes.ADD_PROJECT_REQUEST, addProject);
  yield takeLatest(ProjectsTypes.DELETE_PROJECT_REQUEST, deleteProject);
}
