export function getSelectedBodyElement(project, selectedAppElementIndex) {
  const selectedElement = project.content.body.elements.find(
    (_, currentIndex) => currentIndex === selectedAppElementIndex
  );

  return selectedElement;
}

export function updateProjectHeaderLogo(page, logoData) {
  return {
    ...page.content,
    header: {
      ...page.content.header,
      logo: {
        ...page.content.header.logo,
        ...logoData,
      },
    },
  };
}

export function updatePageBodyElements(page, newElements) {
  return {
    ...page.content,
    body: {
      ...page.content.body,
      elements: newElements,
    },
  };
}

export function updateBodyArrayElements(page, newElements, index) {
  return {
    ...page.content,
    body: {
      ...page.content.body,
      elements: page.content.body.elements.map((element, elementIndex) => {
        if (index === elementIndex) {
          return {
            ...element,
            elements: newElements,
          };
        }

        return element;
      }),
    },
  };
}

export function updateProjectFooterElements(page, newElements) {
  return {
    ...page.content,
    footer: {
      ...page.content.footer,
      elements: newElements,
    },
  };
}
