import { Typography, Box, IconButton, styled } from '@mui/material';

export const FileUploadBox = styled(Box)(({ imageMode, theme }) => ({
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  padding: imageMode ? '2% 0' : '24px 0',
  border: `1px solid ${theme.palette.secondary.dark}`,
  borderRadius: '5px',
  cursor: 'pointer',

  '&:hover': {
    borderColor: theme.palette.secondary.contrastText,
  },
}));

export const InputStyled = styled('input')(() => ({}));

export const DropzoneImage = styled('img')((props) => ({
  display: props.show ? 'block' : 'none',
  width: '92%',
  height: '100%',

  borderRadius: '5px',
  objectFit: 'cover',
}));

export const DropzoneText = styled(Typography)(() => ({
  maxWidth: '41%',
  textAlign: 'center',
}));

export const DropzoneOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: '6%',
  right: '4%',
  bottom: '4%',
  left: '4%',

  display: 'flex',
  alignItems: 'start',
  justifyContent: 'flex-end',
}));

export const IconButtonDelete = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
