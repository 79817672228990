import { Box, IconButton, styled, Tooltip } from '@mui/material';

export const Container = styled(Box)((props) => ({
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '12px',

  width: '100%',
  padding: '12px',

  borderRadius: '5px',
  cursor: 'pointer',

  outlineStyle: 'solid',
  outlineColor: props.active && props.theme.palette.primary.main,
  outlineWidth: props.active ? '1px' : '0',

  '&:hover': {
    outlineWidth: '1px',
    outlineColor: props.theme.palette.primary.main,
  },

  '&:hover > div': {
    opacity: 1,
  },
}));

export const TooltipOverlay = styled(Tooltip)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export const IconButtonDelete = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
