import theme from '~/theme';

export function formatLeadChoice(choice) {
  if (choice === null) {
    return {
      messageId: 'projectsettings.choice.not_answered',
      style: {
        color: theme.palette.text.primary,
      },
    };
  }

  if (choice === true) {
    return {
      messageId: 'projectsettings.choice.yes',
      style: {
        color: theme.palette.success.main,
      },
    };
  }

  return {
    messageId: 'projectsettings.choice.no',
    style: {
      color: theme.palette.error.main,
    },
  };
}
