const responseErrors = {
  // session
  'Incorrect email/password combination': 'session.email_password',
  'An project with this slug already exists': 'project.subdomain_already_exists',
};

function getErrorMessage(message) {
  if (Array.isArray(message)) {
    return message[0];
  }

  return message;
}

export function getErrorIdFromResponse(response, defaultError) {
  const defaultErrorId = defaultError || 'default_error';

  try {
    const errorMessage = getErrorMessage(response.data.message);

    if (errorMessage in responseErrors) {
      return responseErrors[errorMessage];
    }

    return defaultErrorId;
  } catch (error) {
    return defaultErrorId;
  }
}
