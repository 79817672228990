import { Box, styled } from '@mui/material';

import theme from '~/theme';

export const ModalStyle = styled(Box)(() => ({
  background: theme.palette.grey[800],
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  height: 180,
  boxShadow: 24,
  padding: '16px 24px',
  borderRadius: 4,
}));

export const ActionButtonGroup = styled(Box)(() => ({
  position: 'absolute',
  right: '24px',
  bottom: '16px',
}));
