import { Tab } from '@mui/material';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { PaperTabs } from './styles';

function TabsBar({ selectedTab, setSelectedTab }) {
  const intl = useIntl();
  return (
    <PaperTabs
      centered
      value={selectedTab}
      onChange={(event, newValue) => {
        setSelectedTab(newValue);
      }}
    >
      <Tab
        label={intl.formatMessage({
          id: 'projectsettings.general',
        })}
      />
      <Tab
        label={intl.formatMessage({
          id: 'projectsettings.metainformation',
        })}
      />
      <Tab
        label={intl.formatMessage({
          id: 'projectsettings.leadlist',
        })}
      />
      <Tab
        label={intl.formatMessage({
          id: 'projectsettings.custom_domain',
        })}
      />
    </PaperTabs>
  );
}
TabsBar.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};
export default TabsBar;
