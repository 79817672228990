import { Typography } from '@mui/material';

import React from 'react';

import useWindowWidth from '~/hooks/useWindowWidth';

import { MobileOverlayBox } from './styles';

const minWidth = 750;

function MobileOverlay() {
  const windowWidth = useWindowWidth();

  if (windowWidth >= minWidth) {
    return null;
  }

  return (
    <MobileOverlayBox>
      <Typography variant="h5">Please use desktop to continue</Typography>
    </MobileOverlayBox>
  );
}

export default MobileOverlay;
