import { Box, Paper, styled } from '@mui/material';

export const ActionsFormBox = styled(Paper)(({ theme }) => ({
  position: 'fixed',

  left: '50%',
  bottom: 0,

  width: '100%',
  // maxWidth: '933px',

  transform: 'translateX(calc(-50% - 4px))', // 4px cause of box shadow

  boxShadow: '0px -2px 22px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px 8px 0px 0px',
  padding: '20px 32px',

  zIndex: theme.zIndex.drawer + 1,
}));

export const FormFooter = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const LeftActions = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const RightActions = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));
