import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { projectSelector } from '~/helpers/project';

import CustomDomainForm from './components/CustomDomainForm';
import { CustomDomainRoot } from './styles';

function CustomDomain({ onSubmit }) {
  const { project } = useSelector(projectSelector);
  return (
    <CustomDomainRoot sx={{ py: 3 }}>
      <CustomDomainForm initialValues={project} onSubmit={onSubmit} />
    </CustomDomainRoot>
  );
}

CustomDomain.propTypes = {
  onSubmit: PropTypes.func,
};

CustomDomain.defaultProps = {
  onSubmit: () => {},
};

export default CustomDomain;
