import { Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import defaultImage from '~/assets/images/default-project-app-element-image.svg';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { isHtmlTextEmpty } from '~/helpers/html';
import { projectBuilderSelector } from '~/helpers/project';

import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';
import { TextEditorViewer } from '~/components/TextEditor';

import {
  ButtonContainer,
  ChoiceButton,
  ChoiceImage,
  Container,
  IconButtonDelete,
  QuizHoverOverlay,
  TooltipOverlay,
} from './styles';

function QuizBlock({ element, index }) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { selectedAppElementIndex, project } = useSelector(projectBuilderSelector);

  const handleClick = useCallback(() => {
    dispatch(ProjectBuilderActions.setSelectedAppElementIndex(index));
  }, [index, project.id]);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleDeleteElement = useCallback(() => {
    dispatch(ProjectBuilderActions.removeBodyElement(project.id, index));
  }, [index, project.id]);

  const active = useMemo(
    () => index === selectedAppElementIndex,
    [index, selectedAppElementIndex]
  );

  const hasContent = useMemo(() => !isHtmlTextEmpty(element.content), [element.content]);

  return (
    <>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeleteElement}
      />
      <Container active={active} onClick={handleClick}>
        {hasContent ? (
          <TextEditorViewer textContent={element.content} />
        ) : (
          <Typography variant="body2" fontWeight={300} color="grey.500">
            <FormattedMessage id="projectbuilder.text.placeholder" />
          </Typography>
        )}
        <ButtonContainer>
          {element.elements.map((quizChoice) => (
            <ChoiceButton key={quizChoice.id} href={quizChoice.href}>
              <ChoiceImage
                src={quizChoice.tempImage?.src || quizChoice.image?.url || defaultImage}
                alt="Logo"
              />
              <Typography>
                {quizChoice.text || (
                  <FormattedMessage id="projectbuilder.quiz.choice.placeholder" />
                )}
              </Typography>
            </ChoiceButton>
          ))}
        </ButtonContainer>
        <QuizHoverOverlay>
          <Box display="flex" justifyContent="flex-end">
            <TooltipOverlay
              title={<FormattedMessage id="projectbuilder.element.delete" />}
            >
              <IconButtonDelete onClick={handleOpenDeleteModal}>
                <DeleteIcon fontSize="small" />
              </IconButtonDelete>
            </TooltipOverlay>
          </Box>
        </QuizHoverOverlay>
      </Container>
    </>
  );
}

const quizChoicePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  src: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  tempImage: PropTypes.shape({
    src: PropTypes.string,
  }),
  text: PropTypes.string,
  href: PropTypes.string,
});

const quizPropTypes = PropTypes.shape({
  elements: PropTypes.arrayOf(quizChoicePropTypes),
  question: PropTypes.string,
  content: PropTypes.string,
});

QuizBlock.propTypes = {
  index: PropTypes.number.isRequired,
  element: quizPropTypes.isRequired,
};

export default QuizBlock;
