import { Container, Drawer, Toolbar, styled } from '@mui/material';

import PropTypes from 'prop-types';

const PermanentDrawerStyled = styled(Drawer)((props) => ({
  width: props.open ? 340 : 0,
  flexShrink: 0,
  [`& .MuiDrawer-paper`]: {
    width: props.open ? 340 : 0,
    boxSizing: 'border-box',
  },
}));

const DrawerContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '24px 0',
  overflow: 'auto',

  '&::-webkit-scrollbar': {
    width: '8px',
  },

  '&::-webkit-scrollbar-thumb': {
    borderLeft: '4px solid #212121',
    backgroundClip: 'padding-box',
  },
}));

function PermanentDrawer({ children, anchor, open, disableGutters }) {
  return (
    <PermanentDrawerStyled
      open={open}
      PaperProps={{ elevation: 4, sx: { boxShadow: 'none', border: 'none' } }}
      variant="permanent"
      anchor={anchor}
    >
      <Toolbar />

      <DrawerContainer maxWidth="xl" disableGutters={disableGutters}>
        {children}
      </DrawerContainer>
    </PermanentDrawerStyled>
  );
}

PermanentDrawer.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  disableGutters: PropTypes.bool,
};

PermanentDrawer.defaultProps = {
  anchor: 'right',
  open: true,
  disableGutters: false,
};

export default PermanentDrawer;
