import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getSelectedBodyElement, projectBuilderSelector } from '~/helpers/project';
import { renderElementSettingsBasedOnType } from '~/helpers/project/render';

import { SettingsBox } from './styles';

function AppElementSettings() {
  const { project, selectedAppElementIndex, isFooterSelected, isHeaderSelected } =
    useSelector(projectBuilderSelector);

  const ElementSettings = useMemo(() => {
    let selectedElement = getSelectedBodyElement(project, selectedAppElementIndex);

    if (isHeaderSelected) {
      selectedElement = project.content.header;
    }

    if (isFooterSelected) {
      selectedElement = project.content.footer;
    }

    return renderElementSettingsBasedOnType(selectedElement, selectedAppElementIndex);
  }, [project, selectedAppElementIndex]);

  return <SettingsBox>{ElementSettings && ElementSettings}</SettingsBox>;
}

export default AppElementSettings;
