import '~/assets/styles/index.css';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { IntlProvider } from 'react-intl';

import theme from '~/theme';

import AppRouter from '~/router';

import messages from '~/intl';

import MobileOverlay from '~/components/MobileOverlay';
import SnackbarMessage from '~/components/SnackbarMessage';

function AppContainer() {
  const locale = 'en-US';

  return (
    <IntlProvider locale={locale} messages={messages[locale]} defaultLocale={locale}>
      <ThemeProvider theme={theme}>
        <SnackbarMessage />
        <MobileOverlay />

        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
    </IntlProvider>
  );
}

export default AppContainer;
