import { CardMedia, Tooltip, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import defaultLogo from '~/assets/images/default-project-logo.svg';

import { formatCreatedAtProjectDate } from '~/helpers/date';
import { projectStatus } from '~/helpers/project/state';

import { WarningIcon } from '~/components/Icons';

import ProjectLinkIcon from '../common/ProjectLinkIcon';
import ProjectStatusChip from '../common/ProjectStatusChip';
import DotsMenu from '../DotsMenu';
import {
  CardStyled,
  CreatedAtTypography,
  CardActionsStyled,
  CardActionsLeft,
  CardActionsRight,
  // VisibilityCount,
  DescriptionTypography,
  ClientTypography,
  CardActionAreaStyled,
  CardContentStyled,
} from './styles';

function ProjectCard({
  id,
  logo,
  name,
  client,
  description,
  link,
  createdAt,
  status,
  publishedUrl,
  updated,
}) {
  const intl = useIntl();

  const cardLogo = useMemo(() => logo || defaultLogo, [logo]);

  const hasUnpublishedChanges = useMemo(
    () => !updated && status === projectStatus.published,
    [status, updated]
  );

  return (
    <CardStyled>
      <CardActionAreaStyled LinkComponent={Link} to={link}>
        <CardMedia component="img" height="194" image={cardLogo} alt={name} />

        <CardContentStyled>
          <Typography gutterBottom variant="h6">
            {name}
          </Typography>

          <CreatedAtTypography mb={1}>
            {formatCreatedAtProjectDate(createdAt)}
          </CreatedAtTypography>

          {client && <ClientTypography>{client}</ClientTypography>}
          {description && <DescriptionTypography>{description}</DescriptionTypography>}
        </CardContentStyled>
      </CardActionAreaStyled>

      <CardActionsStyled>
        <CardActionsLeft>
          <ProjectLinkIcon status={status} publishedUrl={publishedUrl} />

          {/* <VisibilityIcon sx={{ color: theme.palette.grey[500], mr: 1 }} />
          <VisibilityCount>0</VisibilityCount> */}
        </CardActionsLeft>

        <CardActionsRight>
          {hasUnpublishedChanges && (
            <Tooltip
              arrow
              placement="top"
              title={intl.formatMessage({ id: 'project.unpublished_changes' })}
            >
              <WarningIcon sx={{ mr: 1 }} />
            </Tooltip>
          )}

          <ProjectStatusChip status={status} />
          <DotsMenu id={id} />
        </CardActionsRight>
      </CardActionsStyled>
    </CardStyled>
  );
}

ProjectCard.propTypes = {
  id: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string,
  client: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  createdAt: PropTypes.string,
  status: PropTypes.string,
  publishedUrl: PropTypes.string,
  updated: PropTypes.bool,
};

ProjectCard.defaultProps = {
  id: '',
  logo: '',
  name: '',
  client: '',
  description: '',
  link: '',
  createdAt: '',
  status: '',
  publishedUrl: '',
  updated: false,
};

export default memo(ProjectCard);
