import { Add as AddIcon } from '@mui/icons-material';
import { Fab, Popover } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import PageForm from '../PageForm';

function AddPagePopover({ handleAddNewPage }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const popoverProps = useMemo(
    () => ({
      open: Boolean(anchorEl),
      id: anchorEl ? 'add-page-popover' : undefined,
    }),
    [anchorEl]
  );

  const handleOnSubmit = useCallback((data) => {
    handleAddNewPage(data.title);
    handleClose();
  }, []);

  return (
    <>
      <Fab color="primary" aria-describedby={popoverProps.id} onClick={handleOpen}>
        <AddIcon />
      </Fab>

      <Popover
        id={popoverProps.id}
        open={popoverProps.open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <PageForm onSubmit={handleOnSubmit} cancel={handleClose} />
      </Popover>
    </>
  );
}

AddPagePopover.propTypes = {
  handleAddNewPage: PropTypes.func.isRequired,
};

export default AddPagePopover;
