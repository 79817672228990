import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ProjectActions } from '~/store/ducks/project';

import { projectSelector } from '~/helpers/project';

import CircularLoading from '~/components/CircularLoading';

import CustomDomain from './components/CustomDomain';
import LeadsTable from './components/LeadsTable';
import ProjectMetaInformationForm from './components/ProjectMetaInformationForm';
import ProjectSettingsForm from './components/ProjectSettingsForm';
import TabsBar from './components/TabsBar';
import { ContainerStyled } from './styles';

function ProjectSettingsPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { project, loading } = useSelector(projectSelector);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleSubmit = useCallback(
    (data) => {
      dispatch(
        ProjectActions.saveProjectInfoRequest(
          project.id,
          { ...data, webhookMethod: 'POST' },
          () => {}
        )
      );
    },
    [dispatch, navigate, project.id]
  );

  useEffect(() => {
    dispatch(ProjectActions.getProjectInfoRequest(params.id));
  }, [params]);

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <ContainerStyled>
      <TabsBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {selectedTab === 0 && <ProjectSettingsForm onSubmit={handleSubmit} />}
      {selectedTab === 1 && <ProjectMetaInformationForm onSubmit={handleSubmit} />}
      {selectedTab === 2 && <LeadsTable />}
      {selectedTab === 3 && <CustomDomain onSubmit={handleSubmit} />}
    </ContainerStyled>
  );
}

export default ProjectSettingsPage;
