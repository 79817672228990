import { removeEmptyValuesFromObject } from './object';

export function createFormDataWithoutEmptyValues(data) {
  const formData = new FormData();

  const dataWithoutEmptyValues = removeEmptyValuesFromObject(data);
  const dataArray = Object.entries(dataWithoutEmptyValues);

  for (let i = 0; i < dataArray.length; i += 1) {
    const [key, value] = dataArray[i];
    formData.append(key, value);
  }

  return formData;
}

export function concatenateUrl(baseUrl, newPath) {
  const url = new URL(newPath, baseUrl).toString();
  return url;
}
