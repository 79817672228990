import { styled, Typography } from '@mui/material';

export const CancelTypographyStyled = styled(Typography)(({ theme }) => ({
  '& > a': {
    color: theme.palette.grey[400],
    textDecorationColor: theme.palette.grey[600],

    '&:hover': {
      textDecorationColor: theme.palette.grey[400],
    },
  },
}));
