import { takeLatest, put, call, select } from 'redux-saga/effects';
import slugify from 'slugify';

import api from '~/helpers/api';
import {
  projectBuilderSelector,
  formatProjectFromRequest,
  formatProjectToRequest,
} from '~/helpers/project';
import {
  appElementRules,
  appElementsInitialState,
  appElementTypes,
} from '~/helpers/project/state';
import { createFormDataWithoutEmptyValues } from '~/helpers/request';
import { getErrorIdFromResponse } from '~/helpers/response';

import { FlashMessageActions } from '../ducks/flashMessage';
import { ProjectBuilderTypes, ProjectBuilderActions } from '../ducks/projectBuilder';

function* getProjectInfo({ id }) {
  try {
    const { data } = yield call(api.get, `/projects/${id}`);
    const formattedProject = formatProjectFromRequest(data);

    yield put(ProjectBuilderActions.getProjectInfoSuccess(formattedProject));
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.load_failed');

    yield put(ProjectBuilderActions.getProjectInfoFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

//
function* addProjectPage({ title }) {
  const slug = slugify(title, { lower: true, remove: /[*+~.()'"!:@]/g });
  const { pages } = yield select(projectBuilderSelector);

  const checkIfSlugExists = pages.find((page) => page.slug === slug);

  if (!checkIfSlugExists) {
    yield put(ProjectBuilderActions.addProjectPageSuccess(title, slug));
  } else {
    yield put(FlashMessageActions.showMessage({ id: 'projectbuilder.page.add.failure' }));
  }
}

//

function* removeBodyElement({ index }) {
  const { selectedAppElementIndex } = yield select(projectBuilderSelector);

  if (selectedAppElementIndex === index) {
    yield put(ProjectBuilderActions.setSelectedAppElementIndex(null));
  }
}

function* updateBodyElementImage({ pageId, index, data }) {
  const { fullProject } = yield select(projectBuilderSelector);
  try {
    const projectData = {
      projectId: fullProject.id,
      image: data.tempImage.file,
    };

    const formData = createFormDataWithoutEmptyValues(projectData);

    const response = yield call(api.post, '/images', formData);

    const imageData = {
      id: response.data.id,
      name: response.data.name,
      url: response.data.url,

      userId: response.data.userId,
      projectId: response.data.projectId,
      createdAt: response.data.createdAt,
    };

    yield put(
      ProjectBuilderActions.updateBodyElementImageSuccess(pageId, index, imageData)
    );
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'image_upload_error');

    yield put(ProjectBuilderActions.updateBodyElementImageFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* updateBodyElementArrayItemImage({ pageId, index, choiceIndex, data }) {
  const { fullProject } = yield select(projectBuilderSelector);
  try {
    const projectData = {
      projectId: fullProject.id,
      image: data.file,
    };

    const formData = createFormDataWithoutEmptyValues(projectData);

    const response = yield call(api.post, '/images', formData);

    const imageData = {
      id: response.data.id,
      name: response.data.name,
      url: response.data.url,

      userId: response.data.userId,
      projectId: response.data.projectId,
      createdAt: response.data.createdAt,
    };

    yield put(
      ProjectBuilderActions.updateBodyElementArrayItemImageSuccess(
        pageId,
        index,
        choiceIndex,
        imageData
      )
    );
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'image_upload_error');

    yield put(ProjectBuilderActions.updateQuizChoiceElementImageFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

//
function* updateHeaderElementLogo({ index, data }) {
  const { fullProject } = yield select(projectBuilderSelector);
  try {
    const projectData = {
      projectId: fullProject.id,
      image: data.tempImage.file,
    };

    const formData = createFormDataWithoutEmptyValues(projectData);
    const response = yield call(api.post, '/images', formData);

    const imageData = {
      id: response.data.id,
      name: response.data.name,
      url: response.data.url,

      userId: response.data.userId,
      projectId: response.data.projectId,
      createdAt: response.data.createdAt,
    };

    yield put(ProjectBuilderActions.updateHeaderElementLogoSuccess(index, imageData));
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'image_upload_error');

    yield put(ProjectBuilderActions.updateHeaderElementLogoFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* addFooterElement() {
  const { project } = yield select(projectBuilderSelector);

  try {
    const maxLimitReached =
      project.content.footer.elements.length >= appElementRules.footer.maxElementsCount;

    if (maxLimitReached) {
      yield put(
        FlashMessageActions.showMessage({
          variant: 'error',
          id: 'projectbuilder.max_elements_count',
        })
      );

      return;
    }

    const elementData = appElementsInitialState[appElementTypes.footerLink];
    yield put(ProjectBuilderActions.addFooterElementSuccess(elementData));
  } catch (error) {
    yield put(ProjectBuilderActions.addFooterElementFailure());
    yield put(
      FlashMessageActions.showMessage({
        id: 'projectbuilder.add_element_failed',
      })
    );
  }
}

function* saveProjectBuild() {
  const { fullProject } = yield select(projectBuilderSelector);
  const projectToRequest = formatProjectToRequest(fullProject.pages);

  const dataToUpdate = {
    pages: projectToRequest.pages,
  };

  try {
    const response = yield call(api.put, `/projects/${fullProject.id}`, dataToUpdate);
    const formattedProject = formatProjectFromRequest(response.data);

    yield put(
      FlashMessageActions.showMessage({
        variant: 'success',
        id: 'project.saved_success',
      })
    );
    yield put(ProjectBuilderActions.saveProjectBuildSuccess(formattedProject));
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.save_failed');

    yield put(ProjectBuilderActions.saveProjectBuildFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

function* publishProject() {
  const { fullProject } = yield select(projectBuilderSelector);

  try {
    yield call(api.post, `/projects/${fullProject.id}/publish`);

    yield put(
      FlashMessageActions.showMessage({
        variant: 'success',
        id: 'project.publish_success',
      })
    );
    yield put(ProjectBuilderActions.publishProjectSuccess());
  } catch (error) {
    const errorId = getErrorIdFromResponse(error.response, 'project.publish_failure');

    yield put(ProjectBuilderActions.publishProjectFailure());
    yield put(FlashMessageActions.showMessage({ id: errorId }));
  }
}

export default function* sagas() {
  yield takeLatest(ProjectBuilderTypes.GET_PROJECT_INFO_REQUEST, getProjectInfo);

  yield takeLatest(ProjectBuilderTypes.ADD_PROJECT_PAGE_REQUEST, addProjectPage);

  yield takeLatest(
    ProjectBuilderTypes.UPDATE_HEADER_ELEMENT_LOGO_REQUEST,
    updateHeaderElementLogo
  );

  yield takeLatest(
    ProjectBuilderTypes.UPDATE_BODY_ELEMENT_IMAGE_REQUEST,
    updateBodyElementImage
  );

  yield takeLatest(
    ProjectBuilderTypes.UPDATE_BODY_ELEMENT_ARRAY_ITEM_IMAGE_REQUEST,
    updateBodyElementArrayItemImage
  );

  yield takeLatest(ProjectBuilderTypes.REMOVE_BODY_ELEMENT, removeBodyElement);

  yield takeLatest(ProjectBuilderTypes.ADD_FOOTER_ELEMENT_REQUEST, addFooterElement);
  yield takeLatest(ProjectBuilderTypes.SAVE_PROJECT_BUILD_REQUEST, saveProjectBuild);
  yield takeLatest(ProjectBuilderTypes.PUBLISH_PROJECT_REQUEST, publishProject);
}
