export function updateElementOnArrayBasedOnIndex(array, index, newData) {
  const updatedArray = array.map((item, currentIndex) => {
    if (currentIndex === index) {
      return {
        ...item,
        ...newData,
      };
    }

    return item;
  });

  return updatedArray;
}

export function removeElementFromArrayBasedOnIndex(array, index) {
  const tempArray = [...array];
  tempArray.splice(index, 1);

  return tempArray;
}
