export function isHtmlTextEmpty(text) {
  try {
    let htmlText = text.trim();

    const firstElementLastCharIndex = htmlText.indexOf('>') - 1;
    const firstElement = htmlText.substr(1, firstElementLastCharIndex);

    htmlText = htmlText.replace(firstElement, ''); // remove when the tag opens
    htmlText = htmlText.replace(firstElement, ''); // remove when the tag closes

    return htmlText.length === 5; // '<></>'
  } catch (error) {
    return true;
  }
}
