import { TableRow, styled } from '@mui/material';

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',

  '&:hover': {
    background: theme.palette.grey[800],
  },
}));

export const Image = styled('img')(() => ({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  objectFit: 'cover',
}));
