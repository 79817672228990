import { Chip } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { projectStatus } from '~/helpers/project/state';

function ProjectStatusChip({ status }) {
  const intl = useIntl();

  const statusColor = useMemo(
    () => (status === projectStatus.published ? 'success' : 'info'),
    [status]
  );

  return (
    <Chip
      size="small"
      color={statusColor}
      label={intl.formatMessage({
        id: `project.${status}`,
      })}
    />
  );
}

ProjectStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ProjectStatusChip;
