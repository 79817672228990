import { createTheme } from '@mui/material';

import components from './components';
import palette from './palette';
import typography from './typography';

const themeInstance = createTheme({
  palette,
});

const theme = createTheme(
  {
    components: components(themeInstance),
    typography: typography(themeInstance),
  },
  themeInstance
);

export default theme;
