import { Box, styled } from '@mui/material';

import { WarningIcon } from '~/components/Icons';

export const WarningButtonBox = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
}));

export const WarningButtonIcon = styled(WarningIcon)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,

  transform: 'translate(50%, -50%) rotate(180deg)',

  borderRadius: '50%',
  background: theme.palette.background.default,

  zIndex: theme.zIndex.drawer + 1,
}));
