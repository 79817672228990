import PropTypes from 'prop-types';
import React from 'react';

import { ButtonStyled, CircularProgressStyled } from './styles';

function LoadingButton({ children, disabled, loading, ...rest }) {
  return (
    <ButtonStyled {...rest} disabled={disabled || loading}>
      {loading ? <CircularProgressStyled size="25px" /> : children}
    </ButtonStyled>
  );
}

LoadingButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

LoadingButton.defaultProps = {
  children: null,
  disabled: false,
  loading: false,
};

export default LoadingButton;
