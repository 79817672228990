import { styled, Typography } from '@mui/material';

export const ProjectSettingsFormRoot = styled('form')(({ theme }) => ({
  width: '100%',
  maxWidth: '900px',

  paddingInline: '120px',
  borderRadius: '20px',

  background: theme.palette.grey[900],
}));

export const ProjectNameTypography = styled(Typography)(() => ({
  margin: '52px 0 52px 0',

  textAlign: 'center',
  fontWeight: 'bold',
}));
