import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import defaultImage from '~/assets/images/default-project-app-element-image.svg';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import ImageDropzone from '~/components/ImageDropzone';

import { ContainerStack, DividerStyled, FabStyled, ItemHeader } from './styles';

function ListSettings({ element, index }) {
  const dispatch = useDispatch();
  const { project } = useSelector(projectBuilderSelector);

  const handleAddListItem = useCallback(() => {
    dispatch(
      ProjectBuilderActions.addBodyElementArrayItem(project.id, index, 'listItem')
    );
  }, [project.id, index]);

  const handleRemoveListItem = useCallback(
    (listIndex) => {
      dispatch(
        ProjectBuilderActions.removeBodyElementArrayItem(project.id, index, listIndex)
      );
    },
    [project.id, index]
  );

  const handleChoiceTextChange = useCallback(
    (event, choiceIndex) => {
      const text = event.target.value;
      dispatch(
        ProjectBuilderActions.updateBodyElementArrayItem(project.id, index, choiceIndex, {
          text,
        })
      );
    },
    [index, project.id]
  );

  const handleImageChange = useCallback(
    ({ data, listItemIndex }) => {
      if (!data) {
        dispatch(
          ProjectBuilderActions.deleteBodyElementArrayItemImage(
            project.id,
            index,
            listItemIndex
          )
        );
        return;
      }
      dispatch(
        ProjectBuilderActions.updateBodyElementArrayItemImageRequest(
          project.id,
          index,
          listItemIndex,
          data
        )
      );
    },
    [index, project.id]
  );

  const elementsArraySize = element.elements.length;

  return (
    <ContainerStack spacing={3}>
      {element.elements.map((listItem, listItemIndex) => (
        <Stack key={listItem.id} flexDirection="column" spacing={3}>
          <Stack spacing={1}>
            <ItemHeader>
              <Typography variant="caption">
                <FormattedMessage id="projectbuilder.list.item.text" />
              </Typography>

              <Tooltip
                title={<FormattedMessage id="projectbuilder.element.item.delete" />}
              >
                <Box component="span">
                  <IconButton
                    disabled={elementsArraySize === 1}
                    onClick={() => handleRemoveListItem(listItemIndex)}
                    sx={{ p: '0px' }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            </ItemHeader>

            <TextField
              type="text"
              fullWidth
              variant="outlined"
              value={listItem.text}
              onChange={(e) => handleChoiceTextChange(e, listItemIndex)}
            />
          </Stack>

          <Stack spacing={1}>
            <Typography variant="caption">
              <FormattedMessage id="projectbuilder.image.dropzone" />
            </Typography>
            <ImageDropzone
              sx={{ mt: 1, height: '133px' }}
              defaultImageSrc={
                listItem.tempImage?.src || listItem.image?.url || defaultImage
              }
              onImageChange={(data) => handleImageChange({ data, listItemIndex })}
            />
          </Stack>
          <DividerStyled />
        </Stack>
      ))}

      <FabStyled color="primary" onClick={handleAddListItem}>
        <AddIcon />
      </FabStyled>
    </ContainerStack>
  );
}

const listItemPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  text: PropTypes.string,
  src: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  tempImage: PropTypes.shape({
    src: PropTypes.string,
  }),
});

const listPropTypes = PropTypes.shape({
  elements: PropTypes.arrayOf(listItemPropTypes),
});

ListSettings.propTypes = {
  index: PropTypes.number.isRequired,
  element: listPropTypes.isRequired,
};
export default ListSettings;
