import { createActions, createReducer } from 'reduxsauce';

import { projectInitialState } from '~/helpers/project/state';

/**
 * Types
 */
export const { Types: ProjectTypes, Creators: ProjectActions } = createActions({
  getProjectInfoRequest: ['id'],
  getProjectInfoSuccess: ['project'],
  getProjectInfoFailure: [],

  saveProjectInfoRequest: ['id', 'data', 'callback'],
  saveProjectInfoSuccess: ['data'],
  saveProjectInfoFailure: [],

  deleteProjectInfoRequest: ['id', 'callback'],
  deleteProjectInfoSuccess: [],
  deleteProjectInfoFailure: [],

  uploadLogoRequest: ['id', 'logoFile'],
  uploadLogoSuccess: ['logo'],
  uploadLogoFailure: [],

  deleteLogoRequest: ['id'],
  deleteLogoSuccess: [],
  deleteLogoFailure: [],

  // Leads

  getLeadsRequest: [],
  getLeadsSuccess: ['leads', 'newOptions'],
  getLeadsFailure: [],

  deleteLeadRequest: ['id'],
  deleteLeadSuccess: [],
  deleteLeadFailure: [],

  changeLeadsOptions: ['newOptions'],
});

/**
 * Reducer handlers
 */
const INITIAL_STATE = {
  loading: false,
  loadingSave: false,
  loadingLogoUpload: false,
  loadingLogoDelete: false,

  data: projectInitialState,

  leads: {
    loading: false,
    data: [],

    options: {
      limit: 5,
      page: 1,
      total: 5,
      sort: 'createdAt',
      direction: 'asc',
    },
  },
};

// GET
const getProjectInfoRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const getProjectInfoSuccess = (state = INITIAL_STATE, { project }) => ({
  ...state,
  loading: false,
  data: {
    ...state.data,
    ...project,
  },
});

const getProjectInfoFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

// SAVE
const saveProjectInfoRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingSave: true,
});

const saveProjectInfoSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  loadingSave: false,
  data: {
    ...state.data,
    ...data,
  },
});

const saveProjectInfoFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingSave: false,
});

//

const deleteProjectInfoRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const deleteProjectInfoSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const deleteProjectInfoFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

//

const uploadLogoRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingLogoUpload: true,
});

const uploadLogoSuccess = (state = INITIAL_STATE, { logo }) => ({
  ...state,
  loadingLogoUpload: false,
  data: {
    ...state.data,
    logo,
  },
});

const uploadLogoFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingLogoUpload: false,
});

//

const deleteLogoRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingLogoDelete: true,
  data: {
    ...state.data,
    logo: null,
  },
});

const deleteLogoSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loadingLogoDelete: false,
});

const deleteLogoFailure = (state = INITIAL_STATE) => ({
  ...state,
  loadingLogoDelete: false,
});

// LEADS

const getLeadsRequest = (state = INITIAL_STATE) => ({
  ...state,
  leads: {
    ...state.leads,
    loading: true,
  },
});

const getLeadsSuccess = (state = INITIAL_STATE, { leads, newOptions }) => ({
  ...state,
  leads: {
    ...state.leads,
    loading: false,
    data: leads,
    options: {
      ...state.leads.options,
      ...newOptions,
    },
  },
});

const getLeadsFailure = (state = INITIAL_STATE) => ({
  ...state,
  leads: {
    ...state.leads,
    loading: false,
  },
});

const deleteLeadRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const deleteLeadSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const deleteLeadFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const changeLeadsOptions = (state = INITIAL_STATE, { newOptions }) => ({
  ...state,
  leads: {
    ...state.leads,
    options: {
      ...state.leads.options,
      ...newOptions,
    },
  },
});

export default createReducer(INITIAL_STATE, {
  [ProjectTypes.GET_PROJECT_INFO_REQUEST]: getProjectInfoRequest,
  [ProjectTypes.GET_PROJECT_INFO_SUCCESS]: getProjectInfoSuccess,
  [ProjectTypes.GET_PROJECT_INFO_FAILURE]: getProjectInfoFailure,

  [ProjectTypes.SAVE_PROJECT_INFO_REQUEST]: saveProjectInfoRequest,
  [ProjectTypes.SAVE_PROJECT_INFO_SUCCESS]: saveProjectInfoSuccess,
  [ProjectTypes.SAVE_PROJECT_INFO_FAILURE]: saveProjectInfoFailure,

  [ProjectTypes.DELETE_PROJECT_INFO_REQUEST]: deleteProjectInfoRequest,
  [ProjectTypes.DELETE_PROJECT_INFO_SUCCESS]: deleteProjectInfoSuccess,
  [ProjectTypes.DELETE_PROJECT_INFO_FAILURE]: deleteProjectInfoFailure,

  [ProjectTypes.UPLOAD_LOGO_REQUEST]: uploadLogoRequest,
  [ProjectTypes.UPLOAD_LOGO_SUCCESS]: uploadLogoSuccess,
  [ProjectTypes.UPLOAD_LOGO_FAILURE]: uploadLogoFailure,

  [ProjectTypes.DELETE_LOGO_REQUEST]: deleteLogoRequest,
  [ProjectTypes.DELETE_LOGO_SUCCESS]: deleteLogoSuccess,
  [ProjectTypes.DELETE_LOGO_FAILURE]: deleteLogoFailure,

  // LEADS

  [ProjectTypes.GET_LEADS_REQUEST]: getLeadsRequest,
  [ProjectTypes.GET_LEADS_SUCCESS]: getLeadsSuccess,
  [ProjectTypes.GET_LEADS_FAILURE]: getLeadsFailure,

  [ProjectTypes.DELETE_LEAD_REQUEST]: deleteLeadRequest,
  [ProjectTypes.DELETE_LEAD_SUCCESS]: deleteLeadSuccess,
  [ProjectTypes.DELETE_LEAD_FAILURE]: deleteLeadFailure,

  [ProjectTypes.CHANGE_LEADS_OPTIONS]: changeLeadsOptions,
});
