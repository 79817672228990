const palette = {
  mode: 'dark',
  primary: {
    main: '#F78d00',
    contrastText: '#FFF',
    light: '#F8A333',
  },
  secondary: {
    main: '#a4a4a4',
    contrastText: '#FFF',
    dark: '#6E6E6E',
    light: '#B6B6B6',
  },
  info: {
    main: '#2196F3',
    contrastText: '#FFF',
  },
  success: {
    main: '#66BB6A',
    contrastText: '#FFF',
  },
  warning: {
    main: '#FFA726',
    contrastText: '#FFF',
  },
  background: {
    default: '#303030',
    paper: '#303030',
  },
  grey: {
    50: '#F1F1F1',
    800: '#434343',
    900: '#3A3A3A',
  },
};

export default palette;
