import {
  HeaderBlock,
  HeaderSettings,
  ButtonBlock,
  ButtonSettings,
  ImageBlock,
  ImageSettings,
  TextZoneBlock,
  TextZoneSettings,
  FormBlock,
  FormSettings,
  YesNoButtonBlock,
  YesNoButtonSettings,
  FooterBlock,
  FooterSettings,
  QuizBlock,
  QuizSettings,
  ListBlock,
  ListSettings,
} from '~/components/AppBuilder/components';

// check ./state
const appElementsRenderBridge = {
  header: {
    ElementBlock: HeaderBlock,
    ElementSettings: HeaderSettings,
  },
  image: {
    ElementBlock: ImageBlock,
    ElementSettings: ImageSettings,
  },
  text_zone: {
    ElementBlock: TextZoneBlock,
    ElementSettings: TextZoneSettings,
  },
  button: {
    ElementBlock: ButtonBlock,
    ElementSettings: ButtonSettings,
  },
  form: {
    ElementBlock: FormBlock,
    ElementSettings: FormSettings,
  },
  yesno_button: {
    ElementBlock: YesNoButtonBlock,
    ElementSettings: YesNoButtonSettings,
  },
  quiz: {
    ElementBlock: QuizBlock,
    ElementSettings: QuizSettings,
  },
  list: {
    ElementBlock: ListBlock,
    ElementSettings: ListSettings,
  },
  footer: {
    ElementBlock: FooterBlock,
    ElementSettings: FooterSettings,
  },
};

export function renderElementBasedOnType(element, index) {
  try {
    const { ElementBlock } = appElementsRenderBridge[element.type];

    return <ElementBlock key={index} element={element} index={index} />;
  } catch (error) {
    return null;
  }
}

export function renderElementSettingsBasedOnType(element, index) {
  try {
    const { ElementSettings } = appElementsRenderBridge[element.type];

    return <ElementSettings key={index} element={element} index={index} />;
  } catch (error) {
    return null;
  }
}
