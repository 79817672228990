import {
  ChevronRight as ChevronRightIcon,
  Computer as ComputerIcon,
  Smartphone as SmartphoneIcon,
} from '@mui/icons-material';
import { Breadcrumbs, IconButton, Link, Typography } from '@mui/material';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import mockupImage from '~/assets/images/mockup.svg';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';
import { concatenateUrl } from '~/helpers/request';

import CircularLoading from '~/components/CircularLoading';
import { NavigationHeader } from '~/components/NavigationHeader';

import { BoxStyled, Mockup, MockupBox, MockupContainer, PreviewIframe } from './styles';

function ProjectPreview() {
  const dispatch = useDispatch();
  const params = useParams();

  const { project, loading } = useSelector(projectBuilderSelector);

  const [mobileMode, setMobileMode] = useState(true);

  const handleChangeMode = useCallback(() => {
    setMobileMode((oldState) => !oldState);
  }, []);

  const previewUrl = useMemo(
    () => concatenateUrl(process.env.REACT_APP_TEMPLATE_URL, `/preview/${params.id}`),
    [params.id]
  );

  useEffect(() => {
    dispatch(ProjectBuilderActions.getProjectInfoRequest(params.id));
  }, [dispatch, params.id]);

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <BoxStyled>
      <NavigationHeader>
        <Breadcrumbs aria-label="navigation-breadcrumb" separator={<ChevronRightIcon />}>
          <Link underline="hover" color="inherit" href="/">
            <FormattedMessage id="projectbuilder.all_projects" />
          </Link>

          <Link
            underline="hover"
            color="text.primary"
            href={`/project-builder/${project.id}`}
            aria-current="page"
          >
            <Typography variant="body1">{project.name}</Typography>
          </Link>
        </Breadcrumbs>

        <IconButton onClick={handleChangeMode} sx={{ mr: 2 }}>
          {mobileMode ? <ComputerIcon /> : <SmartphoneIcon />}
        </IconButton>
      </NavigationHeader>

      <MockupContainer hidden={!mobileMode}>
        <MockupBox>
          <PreviewIframe title="Preview" src={previewUrl} />
          <Mockup src={mockupImage} alt="mockup" />
        </MockupBox>
      </MockupContainer>

      <PreviewIframe title="Preview" src={previewUrl} hidden={mobileMode} />
    </BoxStyled>
  );
}

export default ProjectPreview;
