import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import {
  DropzoneImage,
  DropzoneText,
  DropzoneOverlay,
  FileUploadBox,
  InputStyled,
  IconButtonDelete,
} from './styles';

function ImageDropzone({ defaultTextId, defaultImageSrc, onImageChange, ...rest }) {
  const dropzoneImageRef = useRef(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = useCallback((imageData) => {
    const src = imageData?.src || null;

    setSelectedImage(src);
    dropzoneImageRef.current.src = src;

    if (onImageChange) onImageChange(imageData);
    // adjust dependency
  }, []);

  const handleDeleteTempImage = useCallback(
    (event) => {
      event.stopPropagation(); // stop to prevent dropzone onClick
      handleImageChange(null);
    },
    [handleImageChange]
  );

  useEffect(() => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);

    reader.onload = (event) =>
      handleImageChange({
        src: event.target.result,
        file: acceptedFiles[0],
      });
  }, [acceptedFiles, handleImageChange]);

  useEffect(() => {
    setSelectedImage(defaultImageSrc || null);
    dropzoneImageRef.current.src = defaultImageSrc || null;
  }, [defaultImageSrc]);

  const imageSelected = useMemo(() => {
    const hasAcceptedFiles = selectedImage !== null ? 1 : 0;
    const hasDefaultImage = defaultImageSrc ? 1 : 0;

    return hasDefaultImage || hasAcceptedFiles;
  }, [selectedImage, defaultImageSrc]);

  const textId = useMemo(() => defaultTextId || 'dropzone.text', [defaultTextId]);

  return (
    <FileUploadBox {...getRootProps()} imageMode={imageSelected} {...rest}>
      <InputStyled {...getInputProps()} accept="image/*" multiple={false} />
      <DropzoneImage ref={dropzoneImageRef} alt="Dropzone" show={imageSelected} />

      {!!imageSelected && (
        <DropzoneOverlay>
          <IconButtonDelete onClick={handleDeleteTempImage}>
            <DeleteIcon />
          </IconButtonDelete>
        </DropzoneOverlay>
      )}

      {!imageSelected && (
        <>
          <CloudUploadIcon fontSize="large" color="secondary" sx={{ mb: 1 }} />

          <DropzoneText variant="caption">
            <FormattedMessage id={textId} />
          </DropzoneText>
        </>
      )}
    </FileUploadBox>
  );
}

ImageDropzone.propTypes = {
  defaultTextId: PropTypes.string,
  defaultImageSrc: PropTypes.string,
  onImageChange: PropTypes.func,
};

ImageDropzone.defaultProps = {
  defaultTextId: null,
  defaultImageSrc: null,
  onImageChange: null,
};

export default ImageDropzone;
