import { Stack, Typography } from '@mui/material';

import HighlightExtension from '@tiptap/extension-highlight';
import UnderlineExtension from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import { TextEditorContent, TextEditorMenuBar } from '~/components/TextEditor';

function YesNoButtonSettings({ element, index }) {
  const dispatch = useDispatch();
  const { project } = useSelector(projectBuilderSelector);

  const handleUpdate = useCallback(
    ({ editor }) => {
      const dataToUpdate = { content: editor.getHTML(), question: editor.getText() };
      dispatch(ProjectBuilderActions.updateBodyElement(project.id, index, dataToUpdate));
    },
    [dispatch]
  );

  const editor = useEditor({
    content: element.content,
    onUpdate: handleUpdate,
    extensions: [StarterKit, UnderlineExtension, HighlightExtension],
  });

  if (!editor) {
    return null;
  }

  return (
    <Stack flexDirection="column" spacing={4.5}>
      <Stack flexDirection="column" spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.text.style" />
        </Typography>

        <TextEditorMenuBar editor={editor} />
      </Stack>

      <Stack flexDirection="column" spacing={1}>
        <Typography variant="caption">
          <FormattedMessage id="projectbuilder.text" />
        </Typography>

        <TextEditorContent editor={editor} />
      </Stack>
    </Stack>
  );
}

const appTextPropTypes = PropTypes.shape({
  content: PropTypes.string,
});

YesNoButtonSettings.propTypes = {
  element: appTextPropTypes.isRequired,
  index: PropTypes.number.isRequired,
};

export default YesNoButtonSettings;
