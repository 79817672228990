/* eslint-disable no-console */
import { Grid } from '@mui/material';

import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import RhfMoneyInput from '~/components/RhfComponents/RhfMoneyInput';
import RhfTextField from '~/components/RhfComponents/RhfTextField';

import { SubdomainPreview } from './styles';

function ProjectInfoFormFields() {
  const intl = useIntl();
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const projectSlug = useWatch({
    control,
    name: 'slug',
  });

  const subdomainPreview = useMemo(() => {
    const start = 'https://';
    const domain = process.env.REACT_APP_PROJECTS_DOMAIN;

    const subdomain = projectSlug || 'subdomain';

    return `${start}${subdomain}.${domain}`;
  }, [projectSlug]);

  return (
    <>
      <Grid item xs={12}>
        <RhfTextField
          error={errors?.name}
          fullWidth
          variant="outlined"
          name="name"
          defaultValue=""
          placeholder={intl.formatMessage({
            id: 'project.form.name_placeholder',
          })}
          type="text"
          control={control}
          label={intl.formatMessage({
            id: 'project.form.name',
          })}
        />
      </Grid>

      <Grid item xs={12}>
        <RhfTextField
          error={errors?.slug}
          fullWidth
          variant="outlined"
          name="slug"
          defaultValue=""
          placeholder={intl.formatMessage({
            id: 'project.form.subdomain_placeholder',
          })}
          type="text"
          control={control}
          label={intl.formatMessage({
            id: 'project.form.subdomain',
          })}
        />
      </Grid>

      <Grid item xs={12}>
        <SubdomainPreview>{subdomainPreview}</SubdomainPreview>
      </Grid>

      <Grid item xs={12}>
        <RhfTextField
          error={errors?.webhook}
          fullWidth
          variant="outlined"
          name="webhook"
          defaultValue=""
          placeholder={intl.formatMessage({
            id: 'project.form.webhook_placeholder',
          })}
          type="text"
          control={control}
          label={intl.formatMessage({
            id: 'project.form.webhook',
          })}
        />
      </Grid>

      <Grid item xs={6}>
        <RhfTextField
          error={errors?.client}
          fullWidth
          variant="outlined"
          name="client"
          defaultValue=""
          placeholder={intl.formatMessage({
            id: 'project.form.client_placeholder',
          })}
          type="text"
          control={control}
          label={intl.formatMessage({
            id: 'project.form.client',
          })}
        />
      </Grid>

      <Grid item xs={6}>
        <RhfMoneyInput
          prefix="€ "
          error={errors?.budget}
          fullWidth
          variant="outlined"
          name="budget"
          defaultValue=""
          placeholder={intl.formatMessage({
            id: 'project.form.budget_placeholder',
          })}
          control={control}
          label={intl.formatMessage({
            id: 'project.form.budget',
          })}
        />
      </Grid>

      <Grid item xs={12}>
        <RhfTextField
          error={errors?.goals}
          fullWidth
          variant="outlined"
          name="goals"
          defaultValue=""
          type="text"
          control={control}
          placeholder={intl.formatMessage({
            id: 'project.form.goals_placeholder',
          })}
          label={intl.formatMessage({
            id: 'project.form.goals',
          })}
        />
      </Grid>

      <Grid item xs={12}>
        <RhfTextField
          error={errors?.description}
          fullWidth
          variant="outlined"
          name="description"
          defaultValue=""
          placeholder={intl.formatMessage({
            id: 'project.form.description_placeholder',
          })}
          type="text"
          multiline
          control={control}
          label={intl.formatMessage({
            id: 'project.form.description',
          })}
        />
      </Grid>
    </>
  );
}

export default ProjectInfoFormFields;
