import { createActions, createReducer } from 'reduxsauce';

import {
  getTokenData,
  getUser,
  getTheme,
  logout as logoutHelper,
} from '~/helpers/session';

export const { Types: SessionTypes, Creators: SessionActions } = createActions({
  createAccountRequest: ['credentials', 'callback'],
  createAccountSuccess: [],
  createAccountFailure: [],

  loginRequest: ['credentials', 'callback'],
  loginSuccess: ['user', 'token'],
  loginError: [],

  logout: ['callback'],

  setUserTheme: ['theme'],
});

const token = getTokenData();
const user = getUser();
const theme = getTheme();

const INITIAL_STATE = {
  theme,
  user,
  loading: false,
  token,
};

const createAccountRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const createAccountSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const createAccountFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

//

const loginRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const loginSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  user: { ...state.user, ...action.user },
  token: action.token,
  loading: false,
});

const loginError = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const logout = (state = INITIAL_STATE) => {
  logoutHelper();

  return {
    ...state,
    token: null,
    user: {},
  };
};

const setUserTheme = (state = INITIAL_STATE, action) => ({
  ...state,
  theme: action.theme,
});

// Reducers

export default createReducer(INITIAL_STATE, {
  [SessionTypes.CREATE_ACCOUNT_REQUEST]: createAccountRequest,
  [SessionTypes.CREATE_ACCOUNT_SUCCESS]: createAccountSuccess,
  [SessionTypes.CREATE_ACCOUNT_FAILURE]: createAccountFailure,

  [SessionTypes.LOGIN_REQUEST]: loginRequest,
  [SessionTypes.LOGIN_SUCCESS]: loginSuccess,
  [SessionTypes.LOGIN_ERROR]: loginError,

  [SessionTypes.LOGOUT]: logout,

  [SessionTypes.SET_USER_THEME]: setUserTheme,
});
