import { Box, styled } from '@mui/material';

export const MobileOverlayBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '100vw',
  height: '100vh',

  paddingInline: theme.spacing(2),

  textAlign: 'center',

  background: theme.palette.background.default,
  zIndex: theme.zIndex.tooltip + 1,
}));
