import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { newProjectValidationSchema } from '~/helpers/yup';

import { CancelFormLink, ProjectInfoFormFields } from '~/components/FormComponents';
import ImageDropzone from '~/components/ImageDropzone';

import { NewProjectFormRoot, ProjectNameTypography } from './styles';

function NewProjectForm({ onSubmit, loading }) {
  const methods = useForm({
    resolver: yupResolver(newProjectValidationSchema),
  });

  const { handleSubmit, setValue } = methods;

  const handleLogoChange = useCallback((imageData) => {
    const logo = imageData ? imageData.file : null;
    setValue('logo', logo);
  }, []);

  return (
    <FormProvider setValue={setValue} {...methods}>
      <NewProjectFormRoot onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} sx={{ py: 4 }}>
          <Grid item xs={12}>
            <ProjectNameTypography variant="h4" component="h1">
              <FormattedMessage id="newproject.title" />
            </ProjectNameTypography>
          </Grid>

          <ProjectInfoFormFields />

          <Grid item xs={12}>
            <ImageDropzone
              onImageChange={handleLogoChange}
              defaultTextId="project.dropzone.text"
              sx={{ height: '200px' }}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              <FormattedMessage id="project.form.submit" />
            </LoadingButton>

            <CancelFormLink href="/" sx={{ mt: '24px' }} />
          </Grid>
        </Grid>
      </NewProjectFormRoot>
    </FormProvider>
  );
}

NewProjectForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

NewProjectForm.defaultProps = {
  loading: false,
};

export default NewProjectForm;
