import { TextField } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { convertCurrencyToNumberString } from '~/helpers/number';

function RhfTextField({
  name,
  label,
  control,
  defaultValue,
  error,
  variant,
  prefix,
  ...rest
}) {
  const intl = useIntl();

  const handleChange = useCallback((event, onChange) => {
    const formattedValue = convertCurrencyToNumberString(event.target.value, prefix);

    const newEvent = {
      ...event,
      target: {
        ...event.target,
        value: formattedValue,
      },
    };

    onChange(newEvent);
  }, []);

  return (
    <Controller
      render={({ field: { value, onChange, ref, ...props } }) => (
        <NumberFormat
          prefix={prefix}
          customInput={TextField}
          displayType="input"
          decimalSeparator="."
          thousandSeparator=","
          thousandsGroupStyle="thousand"
          allowNegative={false}
          decimalScale={2}
          //
          label={label}
          value={value || ''}
          error={!!error}
          multiline={false}
          helperText={error && intl.formatMessage(error.message, error.message.values)}
          onChange={(event) => handleChange(event, onChange)}
          inputRef={ref}
          variant={variant}
          {...props}
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
}

RhfTextField.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  variant: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.shape({
      id: PropTypes.string,
      values: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    }),
  }),
  prefix: PropTypes.string,
};

RhfTextField.defaultProps = {
  defaultValue: null,
  error: null,
  label: null,
  variant: 'outlined',
  prefix: null,
};

export default RhfTextField;

// import { Box, TextField } from '@mui/material';

// import React from 'react';
// import NumberFormat from 'react-number-format';

// function RhfMoneyInput() {
//   return (
//     <Box>
//       <NumberFormat
//         customInput={TextField}
//         defaultValue="0.00"
//         prefix="R$ "
//         displayType="input"
//         decimalSeparator="."
//         thousandSeparator=","
//         thousandsGroupStyle="thousand"
//         allowNegative={false}
//       />
//     </Box>
//   );
// }

// export default RhfMoneyInput;
