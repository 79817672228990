import { CircularProgress } from '@mui/material';

import React from 'react';

import CenterWrapper from '../CenterWrapper';

function CircularLoading() {
  return (
    <CenterWrapper sx={{ height: 'auto !important', margin: 'auto' }}>
      <CircularProgress />
    </CenterWrapper>
  );
}

export default CircularLoading;
