import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TooltipBox, TooltipBoxSquare, TooltipTypography } from './styles';

function FormBlockTooltip({ open }) {
  if (!open) {
    return null;
  }

  return (
    <TooltipBox>
      <TooltipBoxSquare />

      <TooltipTypography variant="body2">
        <FormattedMessage id="projectbuilder.form.tooltip.message" />
      </TooltipTypography>
    </TooltipBox>
  );
}

FormBlockTooltip.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default FormBlockTooltip;
