import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import defaultHeaderLogo from '~/assets/images/default-header-logo.svg';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { projectBuilderSelector } from '~/helpers/project';

import { HeaderContainer, Logo } from './styles';

function HeaderBlock({ element, index }) {
  const dispatch = useDispatch();

  const { selectedAppElementIndex } = useSelector(projectBuilderSelector);

  const handleClick = useCallback(() => {
    dispatch(ProjectBuilderActions.setSelectedAppElementIndex(index));
  }, [index]);

  const active = useMemo(
    () => index === selectedAppElementIndex,
    [index, selectedAppElementIndex]
  );

  const imageSrc = useMemo(
    () => element.logo.tempImage?.src || element.logo.image?.url || defaultHeaderLogo,
    [element]
  );

  return (
    <HeaderContainer active={active} onClick={handleClick}>
      <Logo src={imageSrc} alt="Logo" />
    </HeaderContainer>
  );
}

const appHeaderLogoPropTypes = PropTypes.shape({
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  tempImage: PropTypes.shape({
    src: PropTypes.string,
  }),
});

const appHeaderPropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  logo: appHeaderLogoPropTypes,
});

HeaderBlock.propTypes = {
  element: appHeaderPropTypes.isRequired,
  index: PropTypes.number.isRequired,
};

export default HeaderBlock;
