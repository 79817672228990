import { Button, Grid, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { newPageValidationSchema } from '~/helpers/yup';

import RhfTextField from '~/components/RhfComponents/RhfTextField';

import { PageFormRoot } from './styles';

function PageForm({ onSubmit, cancel }) {
  const intl = useIntl();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { title: '' },
    resolver: yupResolver(newPageValidationSchema),
  });

  return (
    <PageFormRoot onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6">
            <FormattedMessage id="projectBuilder.page.form.title" />
          </Typography>
        </Grid>
        <Grid item>
          <RhfTextField
            fullWidth
            name="title"
            label={intl.formatMessage({ id: 'projectBuilder.page.form.label' })}
            control={control}
            error={errors.title}
            defaultValue=""
            type="text"
          />
        </Grid>
        <Grid item alignSelf="flex-end">
          <Button type="button" onClick={cancel}>
            <FormattedMessage id="projectBuilder.page.form.cancel_button" />
          </Button>
          <Button type="submit">
            <FormattedMessage id="projectBuilder.page.form.add_button" />
          </Button>
        </Grid>
      </Grid>
    </PageFormRoot>
  );
}

PageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default PageForm;
