import { styled } from '@mui/material';

export const SubdomainPreview = styled('p')(({ theme }) => ({
  marginBlock: 0,
  padding: '6px 16px',

  textAlign: 'start',

  borderRadius: '4px',
  background: theme.palette.grey[700],
}));
