import { EditorContent as TiptapEditor } from '@tiptap/react';
import PropTypes from 'prop-types';
import React from 'react';

import { EditorContentBox } from './styles';

function EditorContent({ editor }) {
  return (
    <EditorContentBox>
      <TiptapEditor editor={editor} />
    </EditorContentBox>
  );
}

EditorContent.propTypes = {
  editor: PropTypes.shape({}),
};

EditorContent.defaultProps = {
  editor: null,
};

export default EditorContent;
