// Check ./render
export const appElementTypes = {
  header: 'header',
  headerLogo: 'header_logo',
  image: 'image',
  textZone: 'text_zone',
  button: 'button',
  form: 'form',
  yesNoButton: 'yesno_button',
  quiz: 'quiz',
  quizChoice: 'quiz_choice',
  list: 'list',
  listItem: 'list_item',
  footer: 'footer',
  footerLink: 'footer_link',
};

export const projectStatus = {
  draft: 'draft',
  published: 'published',
};

export const appElementIndexes = {
  headerIndex: -1,
  footerIndex: -2,
};

export const appElementRules = {
  footer: {
    maxElementsCount: 3,
  },
};

//

const quizChoiceId = crypto.randomUUID();
const listItemId = crypto.randomUUID();

export const appElementsInitialState = {
  header: {
    type: appElementTypes.header,
  },
  header_logo: {
    type: appElementTypes.headerLogo,
    image: null,

    tempImage: {
      src: null,
      file: null,
    },
  },
  image: {
    type: appElementTypes.image,
    text: '', // must be not null
    image: null,

    tempImage: {
      src: null,
      file: null,
    },
  },
  text_zone: {
    type: appElementTypes.textZone,
    content: null,
  },
  button: {
    type: appElementTypes.button,
    text: '', // must be not null
    href: '', // must be not null
    textColor: '#ffffff', // must be six characters
    background: '#000000', // must be six characters
  },
  form: {
    type: appElementTypes.form,
  },
  yesno_button: {
    type: appElementTypes.yesNoButton,
    question: '',
    content: null,
  },
  quiz: {
    type: appElementTypes.quiz,
    question: '',
    content: null,
    elements: [
      {
        id: quizChoiceId,
        type: appElementTypes.quizChoice,
        text: '',
        href: '',
        image: null,

        tempImage: {
          src: null,
          file: null,
        },
      },
    ],
  },
  quiz_choice: {
    id: null,
    type: appElementTypes.quizChoice,
    text: '',
    href: '',
    image: null,

    tempImage: {
      src: null,
      file: null,
    },
  },

  list: {
    type: appElementTypes.list,
    elements: [
      {
        id: listItemId,
        type: appElementTypes.listItem,
        text: '',
        image: null,

        tempImage: {
          src: null,
          file: null,
        },
      },
    ],
  },

  list_item: {
    id: listItemId,
    type: appElementTypes.listItem,
    text: '',
    image: null,

    tempImage: {
      src: null,
      file: null,
    },
  },

  footer: {
    type: appElementTypes.footer,
    elements: [],
  },
  footer_link: {
    type: appElementTypes.footerLink,
    text: '', // must be not null
    href: '', // must be not null
  },
};

export const createDefaultContent = () => ({
  header: {
    logo: appElementsInitialState.header_logo,
  },
  body: {
    elements: [],
  },
  footer: {
    elements: [],
  },
});

const initialPageId = crypto.randomUUID();

export const projectInitialState = {
  id: null,
  name: null,
  slug: null,
  goals: null,
  budget: 0,
  domain: null,
  status: projectStatus.draft,
  ownerId: null,
  logo: null,
  client: null,
  description: null,
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
  metadata: null,
  updated: false,
  publishUrl: '',
  activePage: initialPageId,
  pages: [
    {
      id: initialPageId,
      slug: '',
      title: '',
      content: createDefaultContent(),
    },
  ],
};
