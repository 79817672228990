import { ArrowForward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography, Link } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RLink } from 'react-router-dom';

import { signInValidationSchema } from '~/helpers/yup';

import RhfTextField from '~/components/RhfComponents/RhfTextField';

import { SignInFormRoot } from './styles';

function SignInForm({ onSubmit, loading }) {
  const intl = useIntl();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: signInValidationSchema.cast(),
    resolver: yupResolver(signInValidationSchema),
  });

  return (
    <SignInFormRoot onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <FormattedMessage id="signin.title" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RhfTextField
            error={errors?.email}
            fullWidth
            disabled={loading}
            variant="outlined"
            name="email"
            defaultValue=""
            type="text"
            control={control}
            label={intl.formatMessage({
              id: 'signin.field_email',
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <RhfTextField
            error={errors?.password}
            fullWidth
            variant="outlined"
            name="password"
            disabled={loading}
            defaultValue=""
            type="password"
            control={control}
            label={intl.formatMessage({
              id: 'signin.field_password',
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={loading}
            variant="contained"
            fullWidth
            endIcon={<ArrowForward />}
            color="primary"
            type="submit"
          >
            <FormattedMessage id="signin.button_signin" />
          </LoadingButton>
        </Grid>
        <Grid item container justifyContent="space-between" xs={12}>
          <Typography variant="body2">
            <Link color="text.secondary" component={RLink} to="/register">
              <FormattedMessage id="signin.create_account" />
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link color="text.secondary" component={RLink} to="/recovery">
              <FormattedMessage id="signin.forgot_password" />
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </SignInFormRoot>
  );
}

SignInForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

SignInForm.defaultProps = {
  loading: false,
};

export default SignInForm;
