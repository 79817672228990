function isNotNull(value) {
  return value != null && value !== '';
}

function getValueOrNullIfEmptyString(value) {
  if (value === '') {
    return null;
  }

  return value;
}

export function removeEmptyValuesFromObject(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => isNotNull(value)));
}

export function replaceEmptyStringForNull(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, getValueOrNullIfEmptyString(value)])
  );
}
