import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Button,
  Avatar,
} from '@mui/material';

import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// import { Menu as MenuIcon } from '@mui/icons-material';
import { ReactComponent as LogoSvg } from '~/assets/images/logo.svg';

import { logout } from '~/helpers/session';

import {
  DesktopLogo,
  DesktopMenu,
  PageContent,
  // MobileLogo,
  // MobileMenu,
  UserMenu,
} from './styles';

const pages = [];

function DashboardContainer({ children }) {
  const { user } = useSelector((state) => state.session);

  const navigate = useNavigate();
  // const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  // const handleOpenNavMenu = useCallback((event) => {
  //   setAnchorElNav(event.currentTarget);
  // }, []);

  // const handleCloseNavMenu = useCallback(() => {
  //   setAnchorElNav(null);
  // }, []);

  const handleLogout = useCallback(() => {
    logout();
    navigate('/signin');
    setAnchorElUser(null);
  }, [navigate]);

  const handleOpenUserMenu = useCallback((event) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  return (
    <>
      <AppBar
        sx={{ boxShadow: 'none', zIndex: (theme) => theme.zIndex.drawer + 2 }}
        position="fixed"
      >
        <Container maxWidth="none">
          <Toolbar disableGutters>
            <DesktopLogo height={21}>
              <Link to="/">
                <LogoSvg />
              </Link>
            </DesktopLogo>

            {/* <MobileMenu>
              <IconButton
                color="inherit"
                aria-label="open nav menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {pages.map((page) => (
                  <MenuItem
                    LinkComponent={Link}
                    to={page.path}
                    key={page.label}
                  >
                    <Typography textAlign="center">
                      <FormattedMessage id={page.label} />
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </MobileMenu>

            <MobileLogo variant="h6" noWrap component="div">
              LOGO
            </MobileLogo> */}

            <DesktopMenu>
              {pages.map((page) => (
                <Button
                  sx={{ color: 'white' }}
                  LinkComponent={Link}
                  to={page.path}
                  key={page.label}
                >
                  <FormattedMessage id={page.label} />
                </Button>
              ))}
            </DesktopMenu>

            <Typography variant="body2" mr={1}>
              {user.name}
            </Typography>

            <UserMenu>
              <Tooltip title={<FormattedMessage id="nav.user_tooltip" />}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar />
                </IconButton>
              </Tooltip>

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">
                    <FormattedMessage id="nav.logout" />
                  </Typography>
                </MenuItem>
              </Menu>
            </UserMenu>
          </Toolbar>
        </Container>
      </AppBar>

      <PageContent className="scroll__default">{children}</PageContent>
    </>
  );
}

DashboardContainer.propTypes = {
  children: PropTypes.node,
};

DashboardContainer.defaultProps = {
  children: null,
};

export default DashboardContainer;
