import { Box, styled } from '@mui/material';

export const Image = styled('img')(() => ({
  width: '100%',
  height: '142px',

  objectFit: 'cover',
  borderRadius: '5px',
  textTransform: 'uppercase',
}));

export const ImageHoverOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: '12px',
  bottom: '12px',
  left: '12px',
  right: '12px',

  display: 'flex',
  flexDirection: 'column',
  textTransform: 'uppercase',

  padding: '4px',
  opacity: '0',
}));
