import { createActions, createReducer } from 'reduxsauce';

/**
 * Types
 */
export const { Types: ProjectsTypes, Creators: ProjectsActions } = createActions({
  getProjectsRequest: [],
  getProjectsSuccess: ['data'],
  getProjectsFailure: [],

  addProjectRequest: ['data', 'callback'],
  addProjectSuccess: [],
  addProjectFailure: ['error'],

  deleteProjectRequest: ['id'],
  deleteProjectSuccess: [],
  deleteProjectFailure: ['error'],
});

/**
 * Reducer handlers
 */
const INITIAL_STATE = {
  loading: false,
  data: [],
};

const getProjectsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const getProjectsSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
});

const getProjectsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

//

const addProjectRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const addProjectSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const addProjectFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

//

const deleteProjectRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const deleteProjectSuccess = (state = INITIAL_STATE) => ({
  ...state,
  // will call getProjectsRequest, if change the loading, it will restart the animation
  // loading: false,
});

const deleteProjectFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [ProjectsTypes.GET_PROJECTS_REQUEST]: getProjectsRequest,
  [ProjectsTypes.GET_PROJECTS_SUCCESS]: getProjectsSuccess,
  [ProjectsTypes.GET_PROJECTS_FAILURE]: getProjectsFailure,

  [ProjectsTypes.ADD_PROJECT_REQUEST]: addProjectRequest,
  [ProjectsTypes.ADD_PROJECT_SUCCESS]: addProjectSuccess,
  [ProjectsTypes.ADD_PROJECT_FAILURE]: addProjectFailure,

  [ProjectsTypes.DELETE_PROJECT_REQUEST]: deleteProjectRequest,
  [ProjectsTypes.DELETE_PROJECT_SUCCESS]: deleteProjectSuccess,
  [ProjectsTypes.DELETE_PROJECT_FAILURE]: deleteProjectFailure,
});
