import { Delete as DeleteIcon } from '@mui/icons-material';
import { Typography, Box } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectBuilderActions } from '~/store/ducks/projectBuilder';

import { isHtmlTextEmpty } from '~/helpers/html';
import { projectBuilderSelector } from '~/helpers/project';

import DeleteModalConfirmation from '~/components/DeleteModalConfirmation';
import { TextEditorViewer } from '~/components/TextEditor';

import { TooltipOverlay, IconButtonDelete } from '../../styles';
import { TextContainer, TextHoverOverlay } from './styles';

function TextZoneBlock({ element, index }) {
  const dispatch = useDispatch();
  const { selectedAppElementIndex, project } = useSelector(projectBuilderSelector);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  const handleDeleteElement = useCallback(() => {
    dispatch(ProjectBuilderActions.removeBodyElement(project.id, index));
  }, [index, project.id]);

  const handleClick = useCallback(() => {
    dispatch(ProjectBuilderActions.setSelectedAppElementIndex(index));
  }, [index]);

  const active = useMemo(
    () => index === selectedAppElementIndex,
    [index, selectedAppElementIndex]
  );

  const hasContent = useMemo(() => !isHtmlTextEmpty(element.content), [element.content]);
  return (
    <>
      <DeleteModalConfirmation
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteConfirmation={handleDeleteElement}
      />

      <TextContainer active={active} hasContent={hasContent} onClick={handleClick}>
        {hasContent ? (
          <TextEditorViewer textContent={element.content} />
        ) : (
          <Typography variant="body2" fontWeight={300} color="grey.500">
            <FormattedMessage id="projectbuilder.text.placeholder" />
          </Typography>
        )}

        <TextHoverOverlay>
          <Box display="flex" justifyContent="flex-end">
            <TooltipOverlay
              title={<FormattedMessage id="projectbuilder.element.delete" />}
            >
              <IconButtonDelete onClick={handleOpenDeleteModal}>
                <DeleteIcon fontSize="small" />
              </IconButtonDelete>
            </TooltipOverlay>
          </Box>
        </TextHoverOverlay>
      </TextContainer>
    </>
  );
}

const appTextZonePropTypes = PropTypes.shape({
  type: PropTypes.string.isRequired,
  content: PropTypes.string,
});

TextZoneBlock.propTypes = {
  element: appTextZonePropTypes.isRequired,
  index: PropTypes.number.isRequired,
};

export default TextZoneBlock;
