import { Typography, styled } from '@mui/material';

export const NewProjectFormRoot = styled('form')(() => ({
  width: '100%',
}));

export const ProjectNameTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  margin: '60px 0 50px 0',
}));
