import { combineReducers } from 'redux';

import flashMessage from './flashMessage';
import project from './project';
import projectBuilder from './projectBuilder';
import projects from './projects';
import session from './session';

export default combineReducers({
  session,
  projectBuilder,
  projects,
  project,
  flashMessage,
});
