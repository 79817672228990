import { Box, IconButton, Tooltip } from '@mui/material';

import styled from '@emotion/styled';

export const ChoiceImage = styled('img')(() => ({
  width: '53px',
  height: '53px',
  backgroundColor: 'transparent',
  borderRadius: '13px',

  objectFit: 'cover',
}));

export const Container = styled(Box)((props) => ({
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '12px',

  width: '100%',
  padding: '12px',

  borderRadius: '5px',
  cursor: 'pointer',

  outlineStyle: 'solid',
  outlineColor: props.active && props.theme.palette.primary.main,
  outlineWidth: props.active ? '1px' : '0',

  '&:hover': {
    outlineWidth: '1px',
    outlineColor: props.theme.palette.primary.main,
  },

  '&:hover > div': {
    opacity: 1,
  },
}));

export const ButtonContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  maxWidth: '324px',
  gap: '24px',
}));

export const ChoiceButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  gap: '12px',
  minHeight: '150px',
  padding: '24px 8px 16px',
  borderRadius: '9px',

  wordBreak: 'break-word',

  backgroundColor: theme.palette.background.default,
}));

export const TooltipOverlay = styled(Tooltip)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export const IconButtonDelete = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const QuizHoverOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',

  display: 'flex',
  flexDirection: 'column',
  textTransform: 'uppercase',

  padding: '4px',
  opacity: 0,
}));
